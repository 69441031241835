import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { COLOR } from "../../../utils/color";

export const FooterContainer: SxProps<Theme> = {
  borderTop: `1px solid ${COLOR.neutral200}`,
  minHeight: 80,
  width: "100%",
  px: 2,
  py: 2,
  display: "flex",
  flexDirection: "row",
  gap: 2,
}

export const ProductContainer: SxProps<Theme> = {
  display: 'flex',
  flexDirection:'row',
  alignItems:'center',
  textAlign:'left',
}
export const ProductImage: SxProps<Theme> = {
  width: '89px',
  height: '89px',
  borderRadius: '12px',
  overflow:'hidden',
  display: 'flex',
  flexShrink: 1,
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  }
};
