import React from "react";
import { Box, Button, ButtonProps, SxProps, Theme } from "@mui/material";
import { ButtonStyle, EndormentStyle } from "./CustomButton.styles";
import { COLOR } from "../../utils/color";

export interface CustomButtonPropsI extends Omit<ButtonProps, "variant"> {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  variant?: "contained" | "outlined" | "text" | "dashed" | "text-secondary";
  endormentSx?: {
    start?: SxProps<Theme>;
    end?: SxProps<Theme>;
  };
  startEndorment?: React.ReactNode;
  endEndorment?: React.ReactNode;
}

const CustomButton: React.FC<CustomButtonPropsI> = (props) => {
  const {
    children,
    sx,
    variant = "contained",
    endormentSx,
    startEndorment,
    endEndorment,
    ...buttonProps
  } = props;

  const getButtonStyleByVariant = (
    variant: CustomButtonPropsI["variant"]
  ): SxProps<Theme> => {
    switch (variant) {
      case "dashed":
        return {
          border: `1px dashed ${COLOR.neutral300}`,
          backgroundColor: COLOR.neutral50,
          color: COLOR.neutral500,
          fontWeight: 500,
        };
      case "text":
        return {
          backgroundColor: "transparent",
          color: COLOR.primary500,
          border: "none",
          "&:hover": {
            backgroundColor: COLOR.primary100,
          },
          "&:active": {
            backgroundColor: COLOR.primary50,
          },
          "&:disabled": {
            backgroundColor: "transparent",
            color: COLOR.primary300,
          },
        };
      case "outlined":
        return {
          backgroundColor: COLOR.primary50,
          color: COLOR.primary500,
          border: `1px solid ${COLOR.primary300}`,
          "&:hover": {
            backgroundColor: COLOR.primary100,
          },
          "&:active": {
            backgroundColor: COLOR.primary300,
          },
          "&:disabled": {
            backgroundColor: COLOR.primary50,
            color: COLOR.primary200,
          },
        };
      case "text-secondary":
        return {
          backgroundColor: COLOR.neutral50,
          color: "black",
          border: "none",
          "&:hover": {
            backgroundColor: COLOR.neutral100,
          },
          "&:active": {
            backgroundColor: COLOR.neutral200,
          },
          "&:disabled": {
            backgroundColor: "transparent",
            color: COLOR.neutral400,
          },
        }
      case "contained":
      default:
        return {
          backgroundColor: COLOR.primary500,
          color: "white",
          border: `1px solid ${COLOR.primary500}`,
          "&:hover": {
            backgroundColor: COLOR.primary600,
          },
          "&:active": {
            backgroundColor: COLOR.primary700,
          },
          "&:disabled": {
            backgroundColor: COLOR.primary300,
            color: COLOR.primary200,
          },
        };
    }
  };

  const styleByVariant = getButtonStyleByVariant(variant);
  const mergedStyles = {
    ...ButtonStyle,
    ...styleByVariant,
    ...(sx || {}),
  };

  const cloneWithColor = (endorment: React.ReactNode) => {
    if (React.isValidElement(endorment)) {
      const endormentProps = (endorment as React.ReactElement).props;
      const combinedProps = {
        color:
          endormentProps.color ||
          ((mergedStyles as any).color as string),
        ...endormentProps,
      };
      return React.cloneElement(
        endorment as React.ReactElement,
        combinedProps
      );
    }
    return endorment;
  };

  return (
    <Button sx={mergedStyles} {...buttonProps}>
      {startEndorment && (
        <Box
          sx={
            {
              ...EndormentStyle,
              mr: 2,
              ...(endormentSx?.start || {}),
            } as SxProps<Theme>
          }
        >
          {cloneWithColor(startEndorment)}
        </Box>
      )}
      {children}
      {endEndorment && (
        <Box
          sx={
            {
              ...EndormentStyle,
              ml: 2,
              ...(endormentSx?.end || {}),
            } as SxProps<Theme>
          }
        >
          {cloneWithColor(endEndorment)}
        </Box>
      )}
    </Button>
  );
};

export default CustomButton;
