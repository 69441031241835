import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import {
  downloadSignedUrl,
  findUserByIdAPI,
  getCompanyById,
  getDataByDomainName,
  getDomainAvailabiity,
  getProductById,
  getProductCompactList,
  getPublicProductById,
  getPublicProductScheduleById,
  getPublicTransactionId,
  getScheduleDetailsByProductId,
  getTransactionById,
  getTransactionByQuery,
  getUserCompanies,
} from "../api/request";
import { AxiosResponse } from "axios";
import {
  APIGetByDomainNameResponse,
  APIGetDomainAvailabilityResponse,
  APIGetProductByIdResponse,
  APIGetProductCompactListResponse,
  APIGetPublicProductById,
  APIGetPublicProductScheduleDetailByIdResponse,
  APIGetPublicTransactionByIdResponse,
  APIGetScheduleDetailsByProductIdResponse,
  APIGetTransactionByIdResponse,
  APIGetTransactionQueries,
  APIGetTransactionResponse,
  CompanyByIdResponse,
  DownloadSignedUrlResponse,
  UserCompaniesResponse,
} from "../api/request.types";

export type QueryResult<ResponseType, ErrorType = unknown> = UseQueryResult<
  AxiosResponse<ResponseType>,
  ErrorType
>;
export type QueryOptions<ResponseType, ErrorType = unknown> = UseQueryOptions<
  AxiosResponse<ResponseType>,
  ErrorType
>;

export const useFindUserById = (userId: string) => {
  return useQuery(["findUserById", userId], () => findUserByIdAPI(userId), {
    enabled: !!userId,
    staleTime: 1000 * 60 * 5,
  });
};

export const useGetCompanyById = (
  companyId: string,
  queryProps?:
    | Omit<
      UseQueryOptions<
        AxiosResponse<CompanyByIdResponse, any>,
        unknown,
        AxiosResponse<CompanyByIdResponse, any>,
        string[]
      >,
      "queryKey" | "queryFn"
    >
    | undefined
) => {
  return useQuery(
    ["getCompanyById", companyId],
    () => getCompanyById(companyId),
    {
      ...queryProps,
      enabled: !!companyId,
    }
  );
};
export const useGetUserCompanies = (
  queryProps?:
    | Omit<
      UseQueryOptions<
        AxiosResponse<UserCompaniesResponse, any>,
        unknown,
        AxiosResponse<UserCompaniesResponse, any>,
        string[]
      >,
      "queryKey" | "queryFn"
    >
    | undefined
) => {
  return useQuery(["getUserCompanies"], () => getUserCompanies(), {
    enabled: false,
    ...queryProps,
  });
};

export const useGetDataByDomainName = (
  domainName: string,
  queryProps?:
    | Omit<
      UseQueryOptions<
        AxiosResponse<APIGetByDomainNameResponse, any>,
        unknown,
        AxiosResponse<APIGetByDomainNameResponse, any>,
        string[]
      >,
      "queryKey" | "queryFn"
    >
    | undefined
) => {
  return useQuery(
    ["getByDomainName", domainName],
    () => getDataByDomainName(domainName),
    {
      ...queryProps,
    }
  );
};

export const useGetDomainAvailability = (
  domainName: string,
  queryProps?:
    | Omit<
      UseQueryOptions<
        AxiosResponse<APIGetDomainAvailabilityResponse, any>,
        unknown,
        AxiosResponse<APIGetDomainAvailabilityResponse, any>,
        string[]
      >,
      "queryKey" | "queryFn"
    >
    | undefined
) => {
  return useQuery(
    ["getDomainAvailability", domainName],
    () => getDomainAvailabiity(domainName),
    {
      ...queryProps,
    }
  );
};

export const useGetPublicProductById = (
  productId: string,
  queryProps?:
    | Omit<
      UseQueryOptions<
        AxiosResponse<APIGetPublicProductById, any>,
        unknown,
        AxiosResponse<APIGetPublicProductById, any>,
        string[]
      >,
      "queryKey" | "queryFn"
    >
    | undefined
) => {
  return useQuery(
    ["getProductById", productId],
    () => getPublicProductById(productId),
    {
      ...queryProps,
    }
  );
};

export const useDownloadSignedUrl = (
  path: string,
  queryProps?:
    | Omit<
      UseQueryOptions<
        AxiosResponse<DownloadSignedUrlResponse, any>,
        unknown,
        AxiosResponse<DownloadSignedUrlResponse, any>,
        string[]
      >,
      "queryKey" | "queryFn"
    >
    | undefined
) => {
  return useQuery(["downloadSignedUrl", path], () => downloadSignedUrl(path), {
    ...queryProps,
  });
};

// date format: YYYY-MM-DD
export const useGetPublicProductScheduleById = (productId: string, date: string, queryProps?: Omit<UseQueryOptions<AxiosResponse<APIGetPublicProductScheduleDetailByIdResponse, any>, unknown, AxiosResponse<APIGetPublicProductScheduleDetailByIdResponse, any>, string[]>, "queryKey" | "queryFn"> | undefined) => {
  return useQuery(['getPublicProductScheduleById', productId, date], () => getPublicProductScheduleById(productId, date), {
    ...queryProps,
  });
}

// date format: YYYY-MM-DD
export const useGetScheduleDetailByProductId = (productId: string, date: string, queryProps?: Omit<UseQueryOptions<AxiosResponse<APIGetScheduleDetailsByProductIdResponse, any>, unknown, AxiosResponse<APIGetScheduleDetailsByProductIdResponse, any>, string[]>, "queryKey" | "queryFn"> | undefined) => {
  return useQuery(['getScheduleDetailsByProductId', productId, date], () => getScheduleDetailsByProductId(productId, date), {
    ...queryProps,
    enabled: !!productId && !!date,
    refetchInterval: 300000, // 5 minutes 
  })
};

export const useGetProductCompactList = (companyId: string, queryProps?: Omit<UseQueryOptions<AxiosResponse<APIGetProductCompactListResponse, any>, unknown, AxiosResponse<APIGetProductCompactListResponse, any>, string[]>, "queryKey" | "queryFn"> | undefined) => {
  return useQuery(['getProductCompactList', companyId], () => getProductCompactList(companyId), {
    ...queryProps,
    enabled: !!companyId,
  })
}

export const useGetPublicTransactionById = (
  transactionId: string,
  queryProps?:
    | Omit<
      UseQueryOptions<
        AxiosResponse<APIGetPublicTransactionByIdResponse, any>,
        unknown,
        AxiosResponse<APIGetPublicTransactionByIdResponse, any>,
        string[]
      >,
      "queryKey" | "queryFn"
    >
    | undefined
) => {
  return useQuery(
    ["getTransactionById", transactionId],
    () => getPublicTransactionId(transactionId),
    {
      ...queryProps,
    }
  );
};


export const useGetTransactionByQuery = (query: APIGetTransactionQueries, queryProps?: Omit<UseQueryOptions<AxiosResponse<APIGetTransactionResponse, any>, unknown, AxiosResponse<APIGetTransactionResponse, any>, string[]>, "queryKey" | "queryFn"> | undefined) => {
  return useQuery(["getTransactionListByQuery", `compId_${query.companyID}`, `end_${query?.startDate}` || `end_${query?.endDate}` || "", `stat_${query?.status}`], () => getTransactionByQuery(query), {
    ...queryProps,
    enabled: !!query.companyID,
  })
};

export const useGetProductByProductId = (productId: string, queryProps?: Omit<UseQueryOptions<AxiosResponse<APIGetProductByIdResponse, any>, unknown, AxiosResponse<APIGetProductByIdResponse, any>, string[]>, "queryKey" | "queryFn"> | undefined) => {
  return useQuery(
    ['useGetProductByProductId', productId],
    () => getProductById(productId), {
      ...queryProps,
      enabled: !!productId,
    }
  );
}

export const useGetTransactionById = (transactionId: string, queryProps?: Omit<UseQueryOptions<AxiosResponse<APIGetTransactionByIdResponse, any>, unknown, AxiosResponse<APIGetTransactionByIdResponse, any>, string[]>, "queryKey" | "queryFn"> | undefined) => {
  return useQuery(['useGetTransactionById', transactionId], () => getTransactionById(transactionId), {
    ...queryProps,
    enabled: !!transactionId,
  })
}