import styled from "@emotion/styled";
import { COLOR } from "../../utils/color";

export const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: '4px',
    width: 16,
    height: 16,
    padding: 0,
    backgroundColor: "white",
    border: `1px solid ${COLOR.neutral500}`,
    ".Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
    },
    "input:hover ~ &": {
    },
    "input:disabled ~ &": {
        boxShadow: "none",
        background: "rgba(206,217,224,.5)",
    },
}));

export const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: COLOR.primary500,
    border: 'none',
    "&::before": {
        display: "block",
        width: 16,
        height: 16,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    "input:hover ~ &": {
        
    },
});
