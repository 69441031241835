import React, { useState } from 'react';
import { Box, BoxProps, Typography, ClickAwayListener, SxProps } from '@mui/material';
import InputTitle from '../InputTitle/InputTitle';
import HelperText from '../HelperText/HelperText';
import { FormBaseProps } from '../../../types/globalTypes';
import { ContainerStyle, DisabledContainerStyle, EndormentStyle, ErrorContainerStyle, InputStyle } from '../form.styles';
import { Theme } from '@emotion/react';
import { COLOR } from '../../../utils/color';
import { OptionListSx } from './SingleSelect.styles';

export interface Option {
  value: string;
  label: string;
}

interface SingleSelectPropsI extends BoxProps, FormBaseProps {
  startEndorment?: React.ReactNode;
  endEndorment?: React.ReactNode;
  disabled?: boolean;
  error?: boolean;
  optionProps: {
    value?: Option['value'];
    onChange: (selectedOption: SingleSelectPropsI['optionProps']['value']) => void;
    options: Array<Option>;
    defaultValue?: SingleSelectPropsI['optionProps']['value'];
    placeholder?: string;
    type?: React.HTMLInputTypeAttribute;
    disabledValues?: Array<Option['value']>;
  };
};

const SingleSelect: React.FC<SingleSelectPropsI> = (props) => {
  const { value, onChange, options, placeholder, disabledValues = [] } = props.optionProps;
  const [open, setOpen] = useState(false);
  const handleSelect = (selectedValue: string) => {
    onChange(selectedValue);
    // to prevent conflict with ClickAwaylistener
    setTimeout(() => {
      setOpen(false);
    }, 0);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, ...(props?.sx || {}) }}>
      <InputTitle
        title={props.title}
        required={props.required}
        textProps={{
          sx: { mb: 1 }
        }}
      />
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Box
          sx={{
            position: 'relative',
            ...ContainerStyle,
            ...(props.disabled ? DisabledContainerStyle : {}),
            ...(props.error ? ErrorContainerStyle : {}),
          } as SxProps<Theme>}
          onClick={() => {
            if (!props.disabled) {
              setOpen((prev) => !prev);
            }
          }}
        >
          {props.startEndorment && (
            <Box component="span" sx={{ ...EndormentStyle, mr: 1 }}>
              {props.startEndorment}
            </Box>
          )}
          <Box
            sx={{
              ...InputStyle,
              cursor: props.disabled ? 'not-allowed' : 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              py: '4px',
            }}
          >
            <Typography variant="body1">
              {options.find((option) => option.value === value)?.label || placeholder}
            </Typography>
            <Box component="span" className={open ? 'arrow-up' : 'arrow-down'} />
          </Box>
          {props.endEndorment && (
            <Box component="span" sx={{ ...EndormentStyle, ml: 1 }}>
              {props.endEndorment}
            </Box>
          )}

          {open && (
            <Box sx={OptionListSx}>
              {options.map((option) => {
                const isSelected = option.value === value;
                const isDisabled = disabledValues.some(disVal => disVal === option.value);
                return (
                  <Box
                    key={option.value}
                    sx={{
                      p: 2,
                      cursor: isDisabled ? 'not-allowed' : 'pointer',
                      pointerEvents: isDisabled ? 'none' : 'auto',
                      '&:hover': {
                        backgroundColor: isDisabled ? COLOR.neutral300 : COLOR.primary200,
                      },
                      backgroundColor: isSelected
                        ? COLOR.primary200
                        : isDisabled
                          ? COLOR.neutral300
                          : 'transparent',
                      transition: 'background-color 0.3s ease, border 0.3s ease',
                    }}
                    onClick={() => {
                      if (!isDisabled) {
                        handleSelect(option.value);
                      }
                    }}
                  >
                    {option.label}
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </ClickAwayListener>
      <HelperText {...props.helper} />
    </Box>
  );
};

export default SingleSelect;
