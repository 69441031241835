import React, { useEffect, useState } from 'react';
import { Button, Box, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import SingleSelect from '../SingleSelect/SingleSelect';
import { generateIntervalOptions } from '../../../utils/helper';
import InputTitle from '../InputTitle/InputTitle';

interface TimeRangePickerProps {
  initialStartTime?: string;
  initialEndTime?: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onClose: (startTime: Dayjs, endTime: Dayjs) => void;
  intervalDuration: number;
  disabledIntervals: Array<string>;
}

const TimeRangePicker: React.FC<TimeRangePickerProps> = ({
  initialStartTime = '08:00',
  initialEndTime = '09:00',
  intervalDuration = 30,
  open,
  onOpenChange,
  onClose,
  disabledIntervals,
}) => {
  const intervalOptions = generateIntervalOptions(intervalDuration);
  const [startTime, setStartTime] = useState<Dayjs>(dayjs(intervalOptions[0].value, 'HH.mm'));
  const [endTime, setEndTime] = useState<Dayjs>(dayjs(intervalOptions[1].value, 'HH.mm'));
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setStartTime(dayjs(initialStartTime, 'HH.mm'));
    setEndTime(dayjs(initialEndTime, 'HH.mm'));
  }, [initialStartTime, initialEndTime]);


  const handleClose = () => {
    if (endTime.isBefore(startTime)) {
      setError('End time must be after start time.');
      return;
    }
    if (endTime.diff(startTime, 'minute') < intervalDuration) {
      setError(`Time Range at least must have ${intervalDuration} minute difference`);
      return;
    } 
    setError('');
    onClose(startTime, endTime);
    onOpenChange(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => onOpenChange(false)}
      fullWidth
      maxWidth="sm"
      PaperProps={{ style: { minHeight: '500px' } }}
    >
      <DialogTitle>
        Select Time Range
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <InputTitle title='Start Time'/>
          <SingleSelect
            optionProps={{
              value: startTime.format('HH.mm'),
              onChange: (newValue) => {
                setStartTime(dayjs(newValue, 'HH.mm'));
                setEndTime(dayjs(newValue, 'HH.mm').add(intervalDuration, 'minute'));
              },
              options: intervalOptions,
              disabledValues: disabledIntervals,
            }}
          />
          <InputTitle title='End Time' textProps={{ sx: { mt : 2 }}}/>
          <SingleSelect
            optionProps={{
              value: endTime.format('HH.mm'),
              onChange: (newValue) => {
                setEndTime(dayjs(newValue, 'HH.mm'));
              },
              options: intervalOptions,
              disabledValues: disabledIntervals,
            }}
          />
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => {
          e.preventDefault();
          onOpenChange(false);
        }}>Cancel</Button>
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimeRangePicker;
