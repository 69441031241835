import React from 'react';
import { IconSVGProps } from '../../types/globalTypes';

const TransactionIcon: React.FC<IconSVGProps> = ({
  size = 18,
  color = "#EF4444",
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3334 0.666504H4.66675C2.45761 0.666504 0.666748 2.45737 0.666748 4.6665V13.3332C0.666748 15.5423 2.45761 17.3332 4.66675 17.3332H13.3334C15.5426 17.3332 17.3334 15.5423 17.3334 13.3332V4.6665C17.3334 2.45737 15.5426 0.666504 13.3334 0.666504ZM7.57259 5.0891C7.80614 5.18584 7.95841 5.41373 7.95841 5.66652V12.3332C7.95841 12.6784 7.67859 12.9582 7.33341 12.9582C6.98824 12.9582 6.70841 12.6784 6.70841 12.3332V7.17541L6.10869 7.77513C5.86461 8.01921 5.46888 8.01921 5.2248 7.77513C4.98073 7.53105 4.98073 7.13532 5.2248 6.89125L6.89147 5.22458C7.07022 5.04583 7.33904 4.99236 7.57259 5.0891ZM10.0417 12.3332C10.0417 12.5859 10.194 12.8138 10.4276 12.9106C10.6611 13.0073 10.9299 12.9538 11.1087 12.7751L12.7754 11.1084C13.0194 10.8643 13.0194 10.4686 12.7754 10.2245C12.5313 9.98046 12.1355 9.98046 11.8915 10.2245L11.2917 10.8243L11.2917 5.66648C11.2917 5.32131 11.0119 5.04148 10.6667 5.04148C10.3216 5.04148 10.0417 5.32131 10.0417 5.66648V12.3332Z"
        fill={color}
      />
    </svg>
  );
};

export default TransactionIcon;
