import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import SubMenuHeader from "../../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft } from "react-icons/fa";
import AppContainer from "../../../components/Layout/AppContainer/AppContainer";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import CustomButton from "../../../components/CustomButton/CustomButton";
import TextInput from "../../../components/Forms/TextInput/TextInput";
import SearchIcon from "../../../assets/svg/SearchIcon";
import TargetIcon from "../../../assets/svg/TargetIcon";
import { COLOR } from "../../../utils/color";
import LocationIcon from "../../../assets/svg/LocationIcon";
import { requestGeolocationPermission } from "../../../utils/permission";
import { ContainerStyle, ElipsisText } from "../../../styles/global.styles";
import {
  BpCheckedIcon,
  BpIcon,
} from "../../../components/CheckboxIcon/CheckboxIcon";
import OpacityButton from "../../../components/OpacityButton/OpacityButton";
import { FooterContainer } from "../AddProduct/AddProduct.styles";
import { useNavigate } from "react-router-dom";
import { useElementHeight } from "../../../hooks/useElementHeight";
import MapIframe from "../../../components/MapIframe/MapIframe";
import { getEmbedMapSrc } from "../../../utils/maps";
import { isValidNumber } from "../../../utils/helper";
import { GEOLOCATION_PERMISSION } from "../../../types/permission";
import { useLocationContext } from "../../../context/LocationPageContext";
import { useDispatch } from "react-redux";
import { openDialog } from "../../../redux/reducers/confirmationDialog";
import { errorLogger } from "../../../utils/logger";

export interface LocationData {
  lat: string | null;
  long: string | null;
  search: string;
  lastChanged: "latlong" | "search" | "none";
  isCustomLocation: boolean;
  error: string;
}
export const DEFAULT_LOCATION_DATA: LocationData = {
  lat: null,
  long: null,
  search: "",
  lastChanged: "none",
  isCustomLocation: false,
  error: "",
};

const DEFAULT_USER_LOCATION = {
  lat: -612.3123,
  long: 123.1231,
};

const LocationBusiness: React.FC = () => {
  const { locationData, setLocationData, saveLocation } = useLocationContext();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [geolocationPermission, setGeolocationPermission] =
    useState<GEOLOCATION_PERMISSION>(GEOLOCATION_PERMISSION.INITIAL);

  const footerRef = useRef<HTMLDivElement>(null);
  const bottomSheetRef = useRef<BottomSheetRef>(null);

  const footerHeight = useElementHeight(footerRef);
  const navigate = useNavigate();

  const dismissSheet = () => {
    bottomSheetRef?.current?.snapTo(({ maxHeight }) => maxHeight / 3.5);
  };

  const handleChangeLocationData = useCallback(
    (
      data: Partial<Omit<LocationData, "lastChanged">>,
      lastChanged?: LocationData["lastChanged"]
    ) => {
      setLocationData((prev) => ({
        ...prev,
        ...data,
        ...(lastChanged ? { lastChanged } : {}),
      }));
    },
    [setLocationData]
  );

  const _resetValue = useCallback(() => {
    handleChangeLocationData(DEFAULT_LOCATION_DATA, "none");
  }, [handleChangeLocationData]);

  const _textByLocationPermission = (permission: GEOLOCATION_PERMISSION) => {
    switch (permission) {
      case GEOLOCATION_PERMISSION.GRANTED:
        return [locationData.lat, locationData.long].join(", ");
      case GEOLOCATION_PERMISSION.INITIAL:
        return "Tekan untuk mendapatkan lokasi saat ini";
      case GEOLOCATION_PERMISSION.DENIED:
      default:
        return "Mohon berikan akses ke lokasi";
    }
  };

  const getGeoLocation = async () => {
    try {
      setIsLoading(true);
      const { status, position } = await requestGeolocationPermission();
      setGeolocationPermission(status);
      const lat = position?.latitude ?? 0;
      const long = position?.longitude ?? 0;
      if (
        status === GEOLOCATION_PERMISSION.GRANTED &&
        (lat !== 0 || long !== 0)
      ) {
        handleChangeLocationData(
          {
            lat: lat.toString(),
            long: long.toString(),
          },
          "latlong"
        );
        dismissSheet();
      }
    } catch (error) {
      errorLogger(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    _resetValue();
  }, [_resetValue]);

  return (
    <AppContainer>
      <Box sx={ContainerStyle}>
        <SubMenuHeader
          leftNav={{
            icon: <FaArrowLeft />,
            onClick: () => {
              dispatch(
                openDialog({
                  title: "Konfirmasi",
                  message:
                    "Apakah anda yakin ingin kembali? Lokasi yang kamu pilih belum tersimpan!",
                  secondaryBtn: {
                    text: "Ya, Saya Yakin",
                    onClick: () => {
                      navigate(-1);
                    },
                  },
                })
              );
            },
          }}
          text={"Pilih Lokasi Usaha"}
        />
        <MapIframe
          src={getEmbedMapSrc(
            locationData.lastChanged === "latlong"
              ? [locationData.lat, locationData.long].join(",")
              : locationData.search
          )}
          style={{ width: "100%", height: "70%", pointerEvents: "none" }}
        />
        <BottomSheet
          ref={bottomSheetRef}
          open
          blocking={false}
          defaultSnap={({ maxHeight }) => maxHeight / 2}
          snapPoints={({ maxHeight }) => [
            maxHeight * 0.6, // middle snap
            maxHeight / 3.5, // lower snap
          ]}
        >
          <Box sx={{ px: 2, mt: 1, position: "relative" }}>
            <TextInput
              startEndorment={<SearchIcon />}
              textInputProps={{
                placeholder: "Cari lokasi mu",
                value: locationData.search,
                onChange: (e) =>
                  handleChangeLocationData(
                    {
                      search: e.target.value,
                    },
                    "search"
                  ),
              }}
              disabled={isLoading}
            />
            <CustomButton
              fullWidth
              startEndorment={<TargetIcon />}
              disabled={isLoading}
              sx={{ mt: 2, px: 3 }}
              onClick={() => {
                getGeoLocation();
              }}
            >
              <Box sx={{ width: "100%", textAlign: "left" }}>
                <Typography fontWeight={600}>
                  Gunakan Lokasi anda saat ini
                </Typography>
                <Typography
                  fontWeight={400}
                  variant="caption"
                  style={ElipsisText(1)}
                >
                  {_textByLocationPermission(geolocationPermission)}
                </Typography>
              </Box>
            </CustomButton>

            <OpacityButton
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                py: 2,
              }}
              disabled={isLoading}
              disableOpacity={true}
              onClick={() => {
                const isCustom = !locationData.isCustomLocation;
                handleChangeLocationData({
                  isCustomLocation: isCustom,
                  ...(!isCustom
                    ? {
                        lat: null,
                        long: null,
                      }
                    : {}),
                });
              }}
            >
              <Checkbox
                inputProps={{
                  "aria-label": "Custom Location",
                }}
                checked={locationData.isCustomLocation}
                disabled={isLoading}
                disableRipple
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                sx={{
                  p: 0,
                  mr: 1,
                  pointerEvents: "none",
                }}
              />
              <Typography>Atur lokasi secara manual</Typography>
            </OpacityButton>
            {locationData.isCustomLocation && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: 2,
                  pb: 4,
                  marginBottom: `${footerHeight}px`,
                }}
              >
                <TextInput
                  title="Lat"
                  required
                  disabled={isLoading}
                  sx={{ flex: 1 }}
                  textInputProps={{
                    value: locationData.lat ?? "",
                    onChange: (e) =>
                      handleChangeLocationData({
                        lat: e.target.value,
                      }),
                  }}
                  error={!isValidNumber(locationData.lat ?? "")}
                />
                <TextInput
                  title="Long"
                  required
                  sx={{ flex: 1 }}
                  textInputProps={{
                    value: locationData.long ?? "",
                    onChange: (e) =>
                      handleChangeLocationData({
                        long: e.target.value,
                      }),
                  }}
                  error={!isValidNumber(locationData.long ?? "")}
                />
              </Box>
            )}
          </Box>
          <Box
            ref={footerRef}
            sx={{
              ...FooterContainer,
              position: "absolute",
              bottom: 0,
              backgroundColor: "white",
            }}
          >
            <CustomButton
              fullWidth
              disabled={
                locationData.lastChanged === "none" ||
                !isValidNumber(locationData.lat ?? "") ||
                !isValidNumber(locationData.long ?? "") ||
                isLoading
              }
              onClick={() => {
                saveLocation();
                navigate(-1);
              }}
            >
              <Typography variant="body1" fontWeight={500}>
                simpan
              </Typography>
            </CustomButton>
          </Box>
        </BottomSheet>
      </Box>
    </AppContainer>
  );
};

export default LocationBusiness;
