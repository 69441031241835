import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TIMEZONES } from "../../utils/constant";

export type BusinessDataType = {
  url: string;
  name: string;
  description: string;
  location: {
    lat: number;
    long: number;
    search: string;
    useManualLocation: boolean;
  };
  timezone: string;
  locationDetail: string;
  image?: string;
  templateId: string;
  color: string;
};

export type BusinessState = {
  data: BusinessDataType;
  editData: BusinessDataType;
};

export const initialState: BusinessState = {
  editData: {
    url: "",
    name: "",
    description: "",
    location: {
      lat: 0,
      long: 0,
      search: "",
      useManualLocation: false,
    },
    locationDetail: "",
    timezone: TIMEZONES[0],
    image: undefined,
    templateId: "",
    color: "",
  },
  data: {
    url: "",
    name: "",
    description: "",
    location: {
      lat: 0,
      long: 0,
      search: "",
      useManualLocation: false,
    },
    locationDetail: "",
    timezone: TIMEZONES[0],
    image: undefined,
    templateId: "",
    color: "",
  },
};

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    setBusinessData: (
      state,
      action: PayloadAction<Partial<BusinessDataType>>
    ) => {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          location: {
            ...state.data.location,
            ...action.payload.location,
          },
        },
      };
    },
    resetBusinessData: (state) => {
      return initialState;
    },
    setEditBusinessData: (
      state,
      action: PayloadAction<Partial<BusinessDataType>>
    ) => {
      return {
        ...state,
        editData: {
          ...state.editData,
          ...action.payload,
          location: {
            ...state.editData.location,
            ...action.payload.location,
          },
        },
      };
    },
    resetEditBusinessData: (state) => {
      return initialState;
    },
  },
});

// const editBusinessSlice = createSlice({
//   name: "editBusiness",
//   initialState,
//   reducers: {
//     setEditBusinessData: (
//       state,
//       action: PayloadAction<Partial<BusinessDataType>>
//     ) => {
//       console.log("XX", action, state);
//       return {
//         ...state,
//         editData: {
//           ...state.data,
//           ...action.payload,
//           location: {
//             ...state.data.location,
//             ...action.payload.location,
//           },
//         },
//       };
//     },
//     resetEditBusinessData: (state) => {
//       return initialState;
//     },
//   },
// });

export const {
  setBusinessData,
  resetBusinessData,
  setEditBusinessData,
  resetEditBusinessData,
} = businessSlice.actions;
// export const { setEditBusinessData, resetEditBusinessData } =
//   editBusinessSlice.actions;
export default businessSlice.reducer;
