import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { CSSProperties } from "react";
import { COLOR } from "../utils/color";

export const FormContainerStyle: SxProps<Theme> = {
    px: 3,
    py: 3,
    display: "flex",
    flexDirection: "column",
    width: "100%",
};

export const FieldContainer: SxProps<Theme> = {
    width: "100%",
    mt: 2,
};

export const RowField: SxProps<Theme> = {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    gap: 2,
};

export const ElipsisText = (maxLine: number = 1) =>
    ({
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        WebkitLineClamp: maxLine,
    } as CSSProperties);

export const ContainerStyle: SxProps<Theme> = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
};

export const TextButtonSx: SxProps<Theme> = {
    justifyContent: "flex-start",
    px: 1,
    color: COLOR.neutral500,
    fontWeight: "normal",
    backgroundColor: "white",
    borderColor: COLOR.neutral300,
    "&:hover": {
        backgroundColor: COLOR.neutral100,
    },
    "&:active": {
        backgroundColor: COLOR.neutral300,
    },
};
