import { Box, Typography } from '@mui/material';
import React from 'react';
import { ScheduleAccordionHeader } from '../ScheduleAccordionHeader/ScheduleAccordionHeader';
import { ScheduleDetailItem } from '../../../../components/ScheduleDetailItem/ScheduleDetailItem';
import { COLOR } from '../../../../utils/color';
import { AccordionContent, AccordionItem, AccordionTitle } from '../../../../components/AccordionItem/AccordionItem';

export type ScheduleAccordionTransactionDetailType = {
  fullName: string; email: string; itemCount: number; transactionId: string;
}
export interface ScheduleAccordionProps {
  startTime: string;
  endTime: string;
  remainingQuota: number;
  transactionDetails: ScheduleAccordionTransactionDetailType[];
  open: boolean;
  onToggle: () => void;
  onClickDetail: (detail: ScheduleAccordionTransactionDetailType, index: number) => void;
}

export const ScheduleAccordion: React.FC<ScheduleAccordionProps> = ({
  startTime, endTime, remainingQuota, transactionDetails, open, onToggle, onClickDetail,
}) => {
  return (
    <Box border={`1px solid ${COLOR.neutral300}`} borderRadius={2} overflow="hidden" width="100%">
      <AccordionItem open={open} onToggle={onToggle}>
        <AccordionTitle>
          <ScheduleAccordionHeader
            open={open}
            startTime={startTime}
            endTime={endTime}
            remainingQuota={remainingQuota}
            totalQuota={remainingQuota + transactionDetails.length}
          />
        </AccordionTitle>
        <AccordionContent pb={1}>
          {transactionDetails.length === 0 && (
            <Box p={2} sx={{ backgroundColor: COLOR.neutral50 }}>
              <Typography fontSize={12} textAlign="center">Tidak Ada Jadwal</Typography>
            </Box>
          )}
          {transactionDetails.map((detail, index) => (
            <ScheduleDetailItem
              key={index}
              fullName={detail.fullName}
              email={detail.email}
              itemCount={detail.itemCount}
              onClick={() => onClickDetail(detail, index)}
            />
          ))}
        </AccordionContent>
      </AccordionItem>
    </Box>
  );
};