import API from "./config";
import endpoints from "./endpoints";
import {
  APICalculateTransactionPayload,
  APICalculateTransactionResponse,
  APICancelTransactionResponse,
  APIChangeProductQuotaPayload,
  APIChangeProductQuotaResponse,
  APICreateCompanyBodyPayload,
  APICreateNewTransactionPayload,
  APICreateNewTransactionResponse,
  APICreateProductBodyPayload,
  APICreateProductResponse,
  APIDeleteProductByIdResponse,
  APIEditCompanyBodyPayload,
  APIGetByDomainNameResponse,
  APIGetDomainAvailabilityResponse,
  APIGetProductByIdResponse,
  APIGetProductCompactListResponse,
  APIGetPublicProductById,
  APIGetPublicProductScheduleDetailByIdResponse,
  APIGetPublicTransactionByIdResponse,
  APIGetScheduleDetailsByProductIdResponse,
  APIGetTransactionByIdResponse,
  APIGetTransactionQueries,
  APIGetTransactionResponse,
  APIRefundTransactionPayload,
  APIRefundTransactionResponse,
  APIUpdateProductByIdPayload,
  APIUpdateProductByIdResponse,
  APIUploadCompanyLogoPayload,
  APIUploadProductPhotosPayload,
  APIUploadProductPhotosResponse,
  CompanyByIdResponse,
  CreateCompanyResponse,
  DownloadSignedUrlResponse,
  FindUserByIdResponse,
  GoogleLoginResponse,
  LoginGooglePayload,
  LoginPayload,
  LoginResponse,
  RegisterPayload,
  UpdateCompanyResponse,
  UploadCompanyLogoResponse,
  UserCompaniesResponse,
} from "./request.types";

export const registerAPI = (data: RegisterPayload) => {
  return API({
    method: "POST",
    url: endpoints.REGISTER,
    data,
  });
};
export const loginAPI = (data: LoginPayload) => {
  return API<LoginResponse>({
    method: "POST",
    url: endpoints.LOGIN,
    data,
  });
};

export const loginGoogleAPI = (data: LoginGooglePayload) => {
  return API<GoogleLoginResponse>({
    method: "POST",
    url: endpoints.LOGIN_GOOGLE,
    data,
  });
};

export const requestTokenAPI = () => {
  return API({
    method: "POST",
    url: endpoints.REFRESH_TOKEN,
  });
};

export const logoutAPI = () => {
  return API({
    method: "POST",
    url: endpoints.LOGOUT,
  });
};

export const findUserByIdAPI = (userId: string) => {
  return API<FindUserByIdResponse>({
    method: "GET",
    url: endpoints.BASE_URL + "/" + userId,
  });
};

export const createCompanyAPI = (data: APICreateCompanyBodyPayload) => {
  return API<CreateCompanyResponse>({
    method: "POST",
    url: `${endpoints.COMPANY}/`,
    data,
  });
};
export const updateCompanyAPI = (
  companyId: string,
  data: APIEditCompanyBodyPayload
) => {
  console.log(data, 100);
  return API<CreateCompanyResponse>({
    method: "PUT",
    url: `${endpoints.COMPANY}/${companyId}`,
    data,
  });
};
export const createProductAPI = (data: APICreateProductBodyPayload) => {
  return API<APICreateProductResponse>({
    method: "POST",
    url: endpoints.PRODUCT,
    data,
  });
};

export const getProductById = (productId: string) => {
  return API<APIGetProductByIdResponse>({
    method: "GET",
    url: `${endpoints.PRODUCT}/${productId}`,
  });
};

export const deleteProductById = (productId: string) => {
  return API<APIDeleteProductByIdResponse>({
    method: "DELETE",
    url: `${endpoints.PRODUCT}/${productId}`,
  });
};
export const uploadCompanyLogo = (data: APIUploadCompanyLogoPayload) => {
  const formData = new FormData();
  formData.append("file", data.file);
  return API<UploadCompanyLogoResponse>({
    method: "POST",
    url: endpoints.COMPANY_LOGO + `/${data.companyId}`,
    data: formData,
  });
};
export const downloadSignedUrl = (path: string) => {
  return API<DownloadSignedUrlResponse>({
    method: "GET",
    url: endpoints.STORAGE_DOWNLOAD,
    data: {
      filePath: path,
    },
  });
};

export const getCompanyById = (companyId: string) => {
  return API<CompanyByIdResponse>({
    method: "GET",
    url: endpoints.COMPANY + `/${companyId}`,
  });
};
export const getUserCompanies = () => {
  return API<UserCompaniesResponse>({
    method: "GET",
    url: endpoints.COMPANY,
  });
};

export const uploadProductPhotos = (
  data: APIUploadProductPhotosPayload,
  companyId: string,
  productId: string
) => {
  const formData = new FormData();
  data.forEach((file) => {
    formData.append("file", file);
  });
  return API<APIUploadProductPhotosResponse>({
    method: "POST",
    url: `${endpoints.PRODUCT_STORAGE_UPLOAD}/${companyId}/${productId}`,
    data: formData,
  });
};

export const createProduct = (data: APICreateProductBodyPayload) => {
  return API<APICreateProductResponse>({
    method: "POST",
    url: endpoints.PRODUCT,
    data,
  });
};

export const getDataByDomainName = (domainName: string) => {
  return API<APIGetByDomainNameResponse>({
    method: "GET",
    url: endpoints.COMPANY_BY_DOMAIN + `/${domainName}`,
  });
};

export const getDomainAvailabiity = (domainName: string) => {
  return API<APIGetDomainAvailabilityResponse>({
    method: "GET",
    url: endpoints.CHECK_DOMAIN_AVAILABILITY + `/${domainName}`,
  });
};

export const getPublicProductById = (productId: string) => {
  return API<APIGetPublicProductById>({
    method: "GET",
    url: endpoints.GET_PUBLIC_PRODUCT_BY_ID + `/${productId}`,
  });
};

export const getPublicProductScheduleById = (
  productId: string,
  date: string
) => {
  return API<APIGetPublicProductScheduleDetailByIdResponse>({
    method: "GET",
    url: `${endpoints.GET_PUBLIC_PRODUCT_BY_ID}/${productId}/${date}`,
  });
};

export const getScheduleDetailsByProductId = (
  productId: string,
  date: string
) => {
  return API<APIGetScheduleDetailsByProductIdResponse>({
    method: "GET",
    url: `${endpoints.GET_SCHEDULE_DETAILS_BY_PRODUCT_ID}/${productId}/${date}`,
  });
};

export const getProductCompactList = (companyId: string) => {
  return API<APIGetProductCompactListResponse>({
    method: "GET",
    url: `${endpoints.GET_PRODUCT_COMPACT_LIST}/${companyId}`,
  });
};
// {
//   "companyID": "da7b99fb-592c-4ffd-b104-d2a70579fa4f",
//   "date": "2024-08-30",
//   "productDetail": [
//       {
//           "productID": "9747a169-f94b-4750-8003-ea9b47eb270c",
//           "scheduleDetails": [
//               {
//                   "startTime": "08.00",
//                   "endTime": "09.00",
//                   "date": "2024-08-30",
//                   "qty": 2
//               },
//               {
//                   "startTime": "17.00",
//                   "endTime": "18.00",
//                   "date": "2024-08-30",
//                   "qty": 1
//               }
//           ]
//       }
//   ]
// }
export const getCalculateTransaction = (
  data: APICalculateTransactionPayload
) => {
  return API<APICalculateTransactionResponse>({
    method: "POST",
    url: endpoints.PUBLIC_CALCULATE_TRANSACTION,
    data,
  });
};

export const createNewTransaction = (data: APICreateNewTransactionPayload) => {
  return API<APICreateNewTransactionResponse>({
    method: "POST",
    url: endpoints.PUBLIC_CREATE_NEW_TRANSACTION,
    data,
  });
};

export const cancelTransaction = (transactionId: string) => {
  return API<APICancelTransactionResponse>({
    method: "POST",
    url: `${endpoints.PUBLIC_CANCEL_TRANSACTION}/${transactionId}`,
  });
};

export const refundTransaction = (data: APIRefundTransactionPayload) => {
  return API<APIRefundTransactionResponse>({
    method: "POST",
    url: `${endpoints.PUBLIC_REFUND_TRANSACTION}/${data.transactionID}`,
    data,
  });
};
export const getPublicTransactionId = (transactionId: string) => {
  return API<APIGetPublicTransactionByIdResponse>({
    method: "GET",
    url: `${endpoints.PUBLIC_GET_TRANSACTION_BY_ID}/${transactionId}`,
  });
};

export const changeProductQuota = (
  productId: string,
  data: APIChangeProductQuotaPayload
) => {
  return API<APIChangeProductQuotaResponse>({
    method: "PUT",
    url: `${endpoints.PRODUCT}/${productId}/quota`,
    data,
  });
};

export const getTransactionByQuery = (query: APIGetTransactionQueries) => {
  return API<APIGetTransactionResponse>({
    method: "GET",
    url: `${endpoints.TRANSACTION}`,
    params: query,
  });
};

export const getTransactionById = (transactionId: string) => {
  return API<APIGetTransactionByIdResponse>({
    method: "GET",
    url: `${endpoints.TRANSACTION}/${transactionId}`,
  });
};

export const updateProductById = (productId: string, data: APIUpdateProductByIdPayload) => {
  return API<APIUpdateProductByIdResponse>({
    method: 'PUT',
    url:`${endpoints.PRODUCT}/${productId}`,
    data,
  })
}