import Joi from "joi";
import { TIMEZONES } from "../utils/constant";

export const businessDataSchema = Joi.object({
  url: Joi.string()
    .min(4)
    .regex(/^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$/)
    .required()
    .messages({
      "string.base": "Url harus berupa teks",
      "string.min": "Url Usaha harus lebih dari 3 karakter",
      "any.required": "Url wajib diisi",
      "string.empty": "Url wajib diisi",
      "string.pattern.base":
        "Url tidak valid, gunakan hanya karakter alfanumerik dan tanda hubung.",
    }),
  name: Joi.string().min(4).required().messages({
    "string.base": "Nama harus berupa teks",
    "string.min": "Nama harus lebih dari 3 karakter",
    "any.required": "Nama wajib diisi",
    "string.empty": "Nama wajib diisi",
  }),
  description: Joi.string().min(3).required().messages({
    "string.base": "Deskripsi harus berupa teks",
    "string.min": "Deskripsi harus lebih dari 2 kata",
    "any.required": "Deskripsi wajib diisi",
    "string.empty": "Deskripsi wajib diisi",
  }),
  location: Joi.object({
    lat: Joi.number()
      .optional()
      .messages({
        "number.base": "Latitude harus berupa angka",
      })
      .default(0),
    long: Joi.number()
      .optional()
      .messages({
        "number.base": "Longitude harus berupa angka",
      })
      .default(0),
    search: Joi.string().optional().allow("").default(""),
    useManualLocation: Joi.boolean().optional().default(false),
  }),
  locationDetail: Joi.string().optional().allow(""),
  timezone: Joi.string().optional().default(TIMEZONES[0]),
  image: Joi.any().optional(),
  templateId: Joi.string().optional().allow("").default(""),
  color: Joi.string().optional().allow("").default(""),
}).unknown();
