import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { CSSProperties } from "react";
import { COLOR } from "../../utils/color";
import { MIN_FONT_SIZE_FORM } from "../../utils/constant";

export const ContainerStyle: SxProps<Theme> = {
    display: "flex",
    alignItems: "center",
    fontSize: MIN_FONT_SIZE_FORM,
    fontFamily: 'inherit',
    py: 1,
    px: 2,
    minHeight: '46px',
    fontWeight: 400,
    borderRadius: 3,
    border: `1px solid ${COLOR.neutral300}`,
    "&:focus-within": {
        borderColor: COLOR.primary500,
    },
};

export const DisabledContainerStyle: SxProps<Theme> = {
    pointerEvents: "none",
    cursor: 'not-allowed',
    backgroundColor: COLOR.neutral100,
    color: COLOR.neutral500,
};

export const ErrorContainerStyle: SxProps<Theme> = {
    borderColor: COLOR.danger500,
}

export const InputStyle: CSSProperties = {
    border: "none",
    font: "inherit",
    width:'100%',
    outline: 'none',
    backgroundColor: "inherit",
    fontSize: '16px',
    lineHeight: '24px',
};
export const PasswordInputStyle: CSSProperties = {
    fontFamily: 'Verdana',
    letterSpacing: '0.125em',
};
export const DefaultInputStyle: CSSProperties = {
  fontFamily: 'Poppins',
};

export const EndormentStyle: SxProps<Theme> = {
    minWidth: MIN_FONT_SIZE_FORM,
    minHeight: MIN_FONT_SIZE_FORM,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    font: "inherit",
};



export const textAreaContainnerStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
    fontSize: MIN_FONT_SIZE_FORM,
    padding: '8px 16px',
    fontWeight: 400,
    borderRadius: '12px',
    border: `1px solid ${COLOR.neutral300}`,
    borderColor: COLOR.neutral300,
    width: "100%",
    outline: 'none',
    font: 'inherit',
    resize: 'vertical',
};


export const textAreaDisabledContainerStyle: CSSProperties = {
    pointerEvents: "none",
    cursor: 'not-allowed',
    backgroundColor: COLOR.neutral100,
    color: COLOR.neutral500,
    borderColor: COLOR.neutral300,
};
export const textAreaFocusedContainerStyle: CSSProperties = {
    borderColor: COLOR.primary500,
}
export const textAreaErrorContainerStyle: CSSProperties = {
    borderColor: COLOR.danger500,
}