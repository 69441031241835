import { Navigate, Outlet } from 'react-router-dom';
import useTokenCheck from '../hooks/useTokenCheck';
import MobileContainer from '../components/Layout/MobileContainer/MobileContainer';

interface RouteWrapperPropsI {
  to: string;
}
const RouteWrapper = (props: RouteWrapperPropsI) => {
  const [isLoggedIn] = useTokenCheck();

  if (!isLoggedIn) {
    return (
      <MobileContainer>
        <Outlet />
      </MobileContainer>
    )
  }

  return (
    <Navigate to={props.to} />
  )
};
export default RouteWrapper;