import { useMutation, UseMutationResult } from "react-query";
import {
  APICalculateTransactionPayload,
  APIChangeProductQuotaPayload,
  APICreateCompanyBodyPayload,
  APICreateNewTransactionPayload,
  APICreateProductBodyPayload,
  APIEditCompanyBodyPayload,
  APIRefundTransactionPayload,
  APIUpdateProductByIdPayload,
  APIUploadCompanyLogoPayload,
  LoginGooglePayload,
  LoginPayload,
  LoginResponse,
  RegisterPayload,
} from "../api/request.types";
import {
  cancelTransaction,
  changeProductQuota,
  createCompanyAPI,
  createNewTransaction,
  createProductAPI,
  deleteProductById,
  getCalculateTransaction,
  loginAPI,
  loginGoogleAPI,
  logoutAPI,
  refundTransaction,
  registerAPI,
  requestTokenAPI,
  updateCompanyAPI,
  updateProductById,
  uploadCompanyLogo,
  uploadProductPhotos,
} from "../api/request";
import { ErrorResponse } from "react-router-dom";
import { AxiosResponse } from "axios";

export type MutationResult<TData = any, TError = any> = UseMutationResult<
  AxiosResponse<TData>,
  TError,
  any
>;

export const useRegister = () => {
  return useMutation((data: RegisterPayload) => registerAPI(data), {
    onSuccess: (data) => {
      console.log("Registration successful", data);
    },
    onError: (error) => {
      console.error("Registration error", error);
    },
  });
};
export const useLogin = (): MutationResult<LoginResponse, ErrorResponse> => {
  return useMutation<AxiosResponse<LoginResponse>, ErrorResponse, LoginPayload>(
    (data) => loginAPI(data),
    {
      onSuccess: (response) => {
        console.log("Login successful", response.data);
        console.log("Full response:", response);
      },
      onError: (error) => {
        console.error("Login error", error);
      },
    }
  );
};

export const useLoginGoogle = (): MutationResult<
  LoginResponse,
  ErrorResponse
> => {
  return useMutation<
    AxiosResponse<LoginResponse>,
    ErrorResponse,
    LoginGooglePayload
  >((data) => loginGoogleAPI(data), {
    onSuccess: (response) => {
      console.log("Login successful", response.data);
      console.log("Full response:", response);
    },
    onError: (error) => {
      console.error("Login error", error);
    },
  });
};
export const useRequestToken = () => {
  return useMutation(() => requestTokenAPI(), {
    onSuccess: (data) => {
      console.log("Token refreshed successfully", data);
    },
    onError: (error) => {
      console.error("Token refresh error", error);
    },
  });
};

export const useLogout = () => {
  return useMutation(() => logoutAPI(), {
    onSuccess: () => {
      console.log("Logout successful");
    },
    onError: (error) => {
      console.error("Logout error", error);
    },
  });
};

// export const useDownloadSignedUrl = (queryOpt?: Omit<UseMutationOptions<AxiosResponse<DownloadSignedUrlResponse, any>, unknown, string, unknown>, "mutationFn"> | undefined) => {
//   return useMutation((path: string) => downloadSignedUrl(path), {
//     ...queryOpt
//   });
// };

export const useCreateCompany = () => {
  return useMutation((data: APICreateCompanyBodyPayload) =>
    createCompanyAPI(data)
  );
};

export const useEditCompany = () => {
  return useMutation(
    (data: { companyId: string; companyEdit: APIEditCompanyBodyPayload }) =>
      updateCompanyAPI(data.companyId, data.companyEdit)
  );
};

export const useUploadBusinessImage = () => {
  return useMutation((data: APIUploadCompanyLogoPayload) =>
    uploadCompanyLogo(data)
  );
};
export const useCreateProduct = () => {
  return useMutation((data: APICreateProductBodyPayload) =>
    createProductAPI(data)
  );
};
export const useUploadProductPhotos = () => {
  return useMutation(
    (data: { files: Array<File | string>; companyId: string; productId: string }) =>
      uploadProductPhotos(data.files, data.companyId, data.productId)
  );
};

export const useCalculateTransaction = () => {
  return useMutation((data: APICalculateTransactionPayload) =>
    getCalculateTransaction(data)
  );
};

export const useCreateNewTransaction = () => {
  return useMutation((data: APICreateNewTransactionPayload) =>
    createNewTransaction(data)
  );
};

export const useChangeProductQuota = () => {
  return useMutation(
    (data: APIChangeProductQuotaPayload & { productId: string }) => {
      const { productId, ...payloadData } = data;
      return changeProductQuota(data.productId, payloadData);
    }
  );
};
export const useDeleteProductById = () => {
  return useMutation((productId: string) => deleteProductById(productId));
};

export const useCancelTransaction = () => {
  return useMutation((transactionId: string) =>
    cancelTransaction(transactionId)
  );
};

export const useRefundTransaction = () => {
  return useMutation((data: APIRefundTransactionPayload) =>
    refundTransaction(data)
  );
};

export const useUpdateProductById = () => {
  return useMutation(({ productId, ...data }: APIUpdateProductByIdPayload & { productId: string }) => updateProductById(productId, data));
}
// export const useCreateProduct = () => {
//   const uploadPhotosMutation = useMutation((photos) =>
//     Promise.all(photos.map(uploadPhotoAPI))
//   );

//   const createProductMutation = useMutation((data) =>
//     createProductAPI(data)
//   );

//   const createProduct = async (businessData: Omit<APICreateProductBodyPayload, 'photos'>) => {
//     try {
//       const uploadedPhotoPath: Array<string> = await uploadPhotosMutation.mutateAsync(businessData.photos);

//       await createProductMutation.mutateAsync({
//         ...businessData,
//         photos: uploadedPhotoPath,
//       });
//     } catch (error) {
//       console.error('Error creating product:', error);
//     }
//   };

//   return { createProduct, uploadPhotosMutation, createProductMutation };
// };
