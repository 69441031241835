import dayjs, { Dayjs } from "dayjs";
import {
  CurrencyCodeType,
  GoogleMapSrcOptions,
  PAYMENT_STATUS,
  ProductData,
  SchedulePriceType,
  TRANSACTION_STATUS,
} from "../types/globalTypes";
import { COLOR } from "./color";
import { DEFAULT_GEOLOCATION } from "./constant";
import { ManageSchedulesType } from "../context/ScheduleContext";
import { ScheduleItem } from "../components/Tables/ScheduleTables";
import {
  APICreateProductBodyPayload,
  GetProductByIdResponse,
  ScheduleDetailWithQty,
} from "../api/request.types";
import { StatusTextPropsI } from "../components/StatusText/StatusText";
import { initialState } from "../redux/reducers/product";

export const dashedBorderBgImage = (
  bgColor: string = COLOR.neutral50,
  borderColor: string = COLOR.neutral300,
  spaceBetweenDot: string = "8px",
  borderRadius: string = "8px"
) => {
  const svgString = `
  <svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'>
      <rect width='100%' height='100%' fill='${bgColor}' rx='${borderRadius}' ry='${borderRadius}' 
      stroke='${borderColor}' stroke-width='5' 
      stroke-dasharray='${spaceBetweenDot}' stroke-dashoffset='30' stroke-linecap='square'/>
  </svg>`;
  return `url("data:image/svg+xml,${encodeURIComponent(svgString)}")`;
};

export const formatNumber = (
  number: number,
  locale = "id-ID",
  options?: Intl.NumberFormatOptions
) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
  });
  return formatter.format(number);
};

export const formatCurrency = (
  number: number,
  currency: CurrencyCodeType,
  options?: Intl.NumberFormatOptions
): string => {
  const currencySymbolMap: Record<CurrencyCodeType, string> = {
    IDR: "Rp",
    USD: "$",
    EUR: "€",
    GBP: "£",
    JPY: "¥",
    AUD: "A$",
  };

  const symbol = currencySymbolMap[currency] || "";

  return `${symbol} ${formatNumber(number, "id-ID", options)}`;
};

export const parseValidNumber = (value: string): number | undefined => {
  const numberRegex = /^-?(0|[1-9]\d*)(\.\d+)?$/;
  if (numberRegex.test(value)) {
    return parseFloat(value);
  } else {
    return undefined;
  }
};
export const isValidNumber = (value: string) => {
  const numberRegex = /^-?\d*(\.\d+)?$/;
  return numberRegex.test(value);
};

export const isValidPhoneNumber = (value: string) => {
  if (value.length > 10 && value.length < 12) return true;
  return false;
};
export const stripFormatting = (value: string) => value.replace(/\D/g, "");
export const formatPhoneNumber = (phoneNumber: string) =>
  phoneNumber.replace(/\D/g, "").replace(/(\d{4})(?=\d)/g, "$1-");

export const localStorageSize = () => {
  let _lsTotal = 0,
    _xLen,
    _x;
  for (_x in localStorage) {
    if (!localStorage.hasOwnProperty(_x)) continue;
    _xLen = (localStorage[_x].length + _x.length) * 2;
    _lsTotal += _xLen;
  }
  return (_lsTotal / 1024).toFixed(2);
};

export const generateGoogleMapSrc = ({
  address,
  zipCode,
  latitude,
  longitude,
}: GoogleMapSrcOptions) => {
  const baseUrl = "https://www.google.com/maps?q=";
  let query = "";

  if (latitude !== undefined && longitude !== undefined) {
    query = `${latitude},${longitude}`;
  } else if (address) {
    query = zipCode ? `${address} ${zipCode}` : address;
  } else {
    throw new Error(
      "Either address or latitude and longitude must be provided."
    );
  }

  const encodedQuery = encodeURIComponent(query);
  return `${baseUrl}${encodedQuery}&output=embed`;
};

export const selectedLocationStr = (location: {
  search: string;
  lat: number;
  long: number;
}) => {
  const { search, lat, long } = location;
  const locationBySearch = search;
  const locationByGeolocation = [lat, long].join(",");
  const defaultGeolocationStr = [
    DEFAULT_GEOLOCATION.lat,
    DEFAULT_GEOLOCATION.long,
  ].join(",");

  if (locationBySearch.length !== 0) return locationBySearch;
  if (locationByGeolocation !== defaultGeolocationStr)
    return locationByGeolocation;
  return null;
};

export const hasErrors = (
  errors: Record<string, string | undefined>
): boolean => {
  return Object.values(errors).some((error) => error !== undefined);
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      } else {
        reject(new Error("Failed to convert file to base64"));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const generateTimeIntervals = (data: {
  startTime?: Dayjs;
  duration: number;
}) => {
  const intervals = [];
  const startOfDay = data.startTime || dayjs().startOf("day");
  const endOfDay = dayjs().endOf("day");

  for (
    let time = startOfDay;
    time <= endOfDay;
    time = time.add(data.duration, "minute")
  ) {
    intervals.push(time.format("HH.mm"));
  }

  return intervals;
};

export const generateIntervalOptions = (intervalDuration: number) =>
  generateTimeIntervals({ duration: intervalDuration }).map((e) => ({
    label: e,
    value: e,
  }));


export const generateDisabledTimeIntervals = (
  schedules: SchedulePriceType[],
  intervalDuration: number,
  currentSchedule?: SchedulePriceType,
): string[] => {
  const disabledIntervals: string[] = [];

  schedules.forEach(({ time }) => {
    const { startTime, endTime } = time;
    if (currentSchedule && dayjs(startTime).isSame(dayjs(currentSchedule.time.startTime)) && dayjs(endTime).isSame(dayjs(currentSchedule.time.endTime))) {
      return;
    }
    let currentTime = dayjs(startTime);
    const end = dayjs(endTime);

    while (currentTime.isBefore(end)) {
      disabledIntervals.push(currentTime.format("HH.mm"));
      currentTime = currentTime.add(intervalDuration, "minute");
    }
  });

  return Array.from(new Set(disabledIntervals));
};

export const convertSchedulesToList = (
  scheduleObject: ProductData["schedules"]
): ScheduleItem[] => {
  const scheduleList: ScheduleItem[] = [];
  for (const day in scheduleObject) {
    if (scheduleObject.hasOwnProperty(day)) {
      const schedules = scheduleObject[day];
      schedules.forEach((schedule) => {
        scheduleList.push({
          day: day,
          startTime: dayjs(schedule.startTime, "HH.mm").toDate(),
          endTime: dayjs(schedule.endTime, "HH.mm").toDate(),
          price: schedule.price,
        });
      });
    }
  }

  return scheduleList;
};

export const convertProductScheduleToManagedSchedule = (
  scheduleObject: ProductData["schedules"]
) => {
  const managedSchedules: ManageSchedulesType = {};
  for (const day in scheduleObject) {
    const schedules = scheduleObject[day];
    managedSchedules[day] = schedules.map((schedule) => ({
      price: schedule.price,
      time: {
        startTime: dayjs(schedule.startTime, "HH.mm").toDate(),
        endTime: dayjs(schedule.endTime, "HH.mm").toDate(),
        disabled: false,
      },
    }));
  }
  return managedSchedules;
};

export const parseProductScheduleToAPISchedule = (
  scheduleObject: ProductData["schedules"]
): APICreateProductBodyPayload["scheduleDetails"] => {
  const scheduleDetails: APICreateProductBodyPayload["scheduleDetails"] = {};
  for (const day in scheduleObject) {
    const schedules = scheduleObject[day];
    scheduleDetails[day] = schedules.map((schedule) => ({
      startTime: schedule.startTime,
      endTime: schedule.endTime,
      price: schedule.price,
    }));
  }
  return scheduleDetails;
};

export const convertProductScheduleToStateSchedule = (productSchedules: GetProductByIdResponse['scheduleDetails']): ProductData['schedules'] => {
  const scheduleData: ProductData['schedules'] = {
    ...initialState.edit.schedules,
  };
  for (const day in productSchedules) {
    const schedules = productSchedules[day];
    scheduleData[day] = schedules.map((schedule) => ({
      startTime: dayjs(schedule.startTime, 'HH.mm').format('HH.mm'),
      endTime: dayjs(schedule.endTime, 'HH.mm').format('HH.mm'),
      price: schedule.price,
    }));
  }
  return scheduleData;
}
export const isBase64 = (str: string): boolean => {
  if (str.length % 4 !== 0) {
    return false;
  }
  const base64Pattern = /^[A-Za-z0-9+/=]+$/;
  return base64Pattern.test(str);
};

export const isBase64Image = (str: string): boolean => {
  const imagePattern = /^data:image\/[a-zA-Z]+;base64,/;
  return imagePattern.test(str) && isBase64(str.split(",")[1]);
};

export const base64ToFile = (base64String: string, fileName: string): File => {
  const [header, data] = base64String.split(",");
  const mime = header.match(/:(.*?);/)?.[1] || "";

  const byteString = atob(data);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([arrayBuffer], { type: mime });

  let extension = "";
  if (mime) {
    extension = mime.split("/")[1];
  }

  const fileNameWithExtension = `${fileName}.${extension}`;

  return new File([blob], fileNameWithExtension, { type: mime });
};

export const getStatusTextPropsByStatus = (
  status: PAYMENT_STATUS | TRANSACTION_STATUS
): StatusTextPropsI => {
  console.log(status)
  switch (status) {
    case PAYMENT_STATUS.UNPAID:
      return {
        children: "Belum Dibayar",
        backgroundColor: COLOR.warning500,
        color: COLOR.warning50,
      };
    case PAYMENT_STATUS.PAID:
      return {
        children: "Sudah Dibayar",
        backgroundColor: COLOR.success500,
        color: COLOR.success50,
      };
    case PAYMENT_STATUS.CANCELLED:
      return {
        children: "Dibatalkan",
        backgroundColor: COLOR.danger500,
        color: COLOR.danger50,
      };
    case PAYMENT_STATUS.REFUNDED:
      return {
        children: "Dikembalikan",
        backgroundColor: COLOR.danger500,
        color: COLOR.danger50,
      };
    case PAYMENT_STATUS.REFUND_CANCELLED:
      return {
        children: "Refund Dibatalkan",
        backgroundColor: COLOR.danger500,
        color: COLOR.danger50,
      };
    case PAYMENT_STATUS.REQUEST_REFUND:
      return {
        children: "Pengajuan Pengembalian Dana",
        backgroundColor: COLOR.warning500,
        color: COLOR.warning50,
      };
    case PAYMENT_STATUS.PENDING:
      return {
        children: "Proses Pembayaran",
        backgroundColor: COLOR.warning500,
        color: COLOR.warning50,
      };
    case PAYMENT_STATUS.PARTIAL_REFUND:
      return {
        children: "Dikembalikan Sebagian",
        backgroundColor: COLOR.warning500,
        color: COLOR.warning50,
      };
    case TRANSACTION_STATUS.COMPLETE:
      return {
        children: "Selesai",
        backgroundColor: COLOR.success500,
        color: COLOR.success50,
      };
    case TRANSACTION_STATUS.ON_PROGRESS:
      return {
        children: "Sedang Berjalan",
        backgroundColor: COLOR.primary500,
        color: COLOR.primary50,
      };
    case TRANSACTION_STATUS.COMING_SOON:
      return {
        children: "Akan Datang",
        backgroundColor: COLOR.neutral500,
        color: COLOR.neutral50,
      };
    case TRANSACTION_STATUS.CANCELED:
      return {
        children: "Batal",
        backgroundColor: COLOR.danger500,
        color: COLOR.danger50,
      };
    case TRANSACTION_STATUS.CREATED:
      return {
        children: "Baru Dibuat",
        backgroundColor: COLOR.warning500,
        color: COLOR.warning50,
      };
    default:
      return {
        children: "Status Tidak Diketahui",
        backgroundColor: COLOR.neutral500,
        color: COLOR.neutral50,
      };
  }
};

export const hexToRgba = (hex: string, alpha: number) => {
  hex = hex.replace(/^#/, "");

  let r = parseInt(hex.slice(0, 2), 16);
  let g = parseInt(hex.slice(2, 4), 16);
  let b = parseInt(hex.slice(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const checkRefundAllowability = (
  scheduleDetails: ScheduleDetailWithQty[]
) => {
  if (!scheduleDetails.length) return false;
  const sortedSchedule = scheduleDetails.sort((scheduleA, scheduleB) =>
    scheduleB.date > scheduleA.date
      ? 1
      : scheduleA.date === scheduleB.date
        ? scheduleA.startTime > scheduleB.startTime
          ? 1
          : -1
        : -1
  );
  return dayjs(
    `${sortedSchedule[0]?.date} ${sortedSchedule[0]?.startTime}`,
    "YYYY-MM-DD HH.mm"
  )
    .subtract(1, "hours")
    .isAfter(dayjs());
};

export const formatCoordinate = (input: string) => {
  let sanitized = input.replace(/[^0-9]/g, '');
 
  if (sanitized.length > 3) {
    sanitized = sanitized.slice(0, 3) + '.' + sanitized.slice(3);
  }
  if (input.startsWith('-')) {
    sanitized = '-' + sanitized;
  }

  return sanitized;
}
