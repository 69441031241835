import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../components/CustomButton/CustomButton';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column' }}>
      <Typography>Page Not Found!</Typography>
      <CustomButton sx={{ mt: 2 }} onClick={() => {
        navigate(-1);
      }}>
        <Typography>Go Back</Typography>
      </CustomButton>
    </Box>
  );
};

export default NotFound;