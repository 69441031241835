import { useNavigate } from 'react-router-dom';
import { COOKIE } from '../utils/constant';
import { Box, Typography } from '@mui/material';
import CustomButton from '../components/CustomButton/CustomButton';
import Cookies from 'js-cookie';

const UnAuthorizedPage = () => {
  const navigate = useNavigate();

  const logoutUser = () => {
    Cookies.remove(COOKIE.TOKEN, { path: '/'});
  }

  return (
    <Box>
      <Typography>Your Role have no Access to This Page</Typography>
      <CustomButton
        onClick={() => {
          logoutUser();
          navigate(-1);
        }}
      >
        GO BACK
      </CustomButton>
    </Box>
  );
};
export default UnAuthorizedPage;