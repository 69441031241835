import React from 'react';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { COOKIE, RoutePath } from '../../utils/constant';
import { clearAll } from '../../redux/actions';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/CustomButton/CustomButton';
import MobileContainer from '../../components/Layout/MobileContainer/MobileContainer';
import { useLogout } from '../../query/mutations';
import Cookies from 'js-cookie';
import { Box } from '@mui/material';
import { useFindUserById, useGetCompanyById } from '../../query/queries';
import { RootReducerState } from '../../redux/reducers';
import { COLOR } from '../../utils/color';

const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutQuery = useLogout();
  const userReducer = useSelector((state: RootReducerState) => state.userReducer);
  const getUserProfileById = useFindUserById(userReducer.data.userId || "");
  const userProfile = getUserProfileById.data?.data?.data;
  const logoutUser = () => {
    if (Cookies.get(COOKIE.TOKEN)) {
      logoutQuery.mutate(undefined, {
        onSuccess: () => {
          dispatch(clearAll());
          Cookies.remove(COOKIE.TOKEN, { path: '/' });
          navigate(RoutePath.LOGIN, { replace: true });
        }
      });
    } else {
      navigate(RoutePath.LOGIN, { replace: true });
    }
  };

  return (
    <MobileContainer>
      <Box px={2} display="flex" justifyContent="center" flexDirection="column" width="100%" height="100%">
        <Box py={3} px={2} mb={4} border={`1px solid ${COLOR.neutral300}`} sx={{ borderRadius: 5, wordBreak:'break-all' }}>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" gap={2}>
            <Typography>Nama: </Typography><Typography fontSize={14}>{userProfile?.fullName}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" gap={2}>
            <Typography>Email: </Typography><Typography fontSize={14}>{userProfile?.email}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" gap={2}>
            <Typography>No Hp: </Typography><Typography fontSize={14}>{userProfile?.phoneNumber || "-"}</Typography>
          </Box>
        </Box>
        <CustomButton disabled={logoutQuery.isLoading} onClick={() => {
          logoutUser();
        }}>{logoutQuery.isLoading ? 'Loading...' : 'Logout'}</CustomButton>
      </Box>
    </MobileContainer>
  );
};

export default Profile;
