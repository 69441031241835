import { createSlice, PayloadAction } from '@reduxjs/toolkit';


export type UserReducerState = {
  data: {
    companyId?: string;
    userId?: string;
  };
}

export const initialState: UserReducerState = {
  data: {
    companyId: undefined,
    userId: undefined,
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserReducerState['data']>) => {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        }
      }
    },
    resetUserData: () => {
      return initialState;
    },
  },
});

export const { setUserData, resetUserData } = userSlice.actions;
export default userSlice.reducer;
