import React, { forwardRef } from 'react';
import { BottomSheet, BottomSheetProps, BottomSheetRef } from 'react-spring-bottom-sheet';
import SchedulePrice from '../SchedulePrice/SchedulerPrice';
import { ManageSchedulesType } from '../../context/ScheduleContext';
import { SchedulePriceType } from '../../types/globalTypes';
import { Box } from '@mui/material';

interface ScheduleBottomSheetProps {
  bottomSheetProps?: Omit<BottomSheetProps, 'children' | 'open' | 'ref'>;
  schedules: ManageSchedulesType;
  handleSchedulesChange: (day: string) => (updatedSchedules: SchedulePriceType[]) => void;
  isCustomPrice: boolean;
  price: number;
  open: boolean;
}

const ScheduleBottomSheet = forwardRef<BottomSheetRef, ScheduleBottomSheetProps>(
  ({ bottomSheetProps, schedules, handleSchedulesChange, ...props }, ref) => {
    return (
      <BottomSheet
        ref={ref}
        open={props.open}
        {...bottomSheetProps}
        style={{
          ...bottomSheetProps?.style,
        }}
      >
        <Box px={2} pb={5}>
          {Object.keys(schedules).map(day => {
            const daySchedules = schedules[day];
            return (
              <SchedulePrice
                key={day}
                title={day}
                schedules={daySchedules}
                onSchedulesChange={handleSchedulesChange(day)}
                priceProps={{
                  isCustomPrice: props.isCustomPrice,
                  price: props.price,
                }}
              />
            )
          })}
        </Box>
      </BottomSheet>
    );
  }
);

// Export the component
export default ScheduleBottomSheet;
