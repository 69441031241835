import { useEffect, useState } from "react";
import ENV from "../utils/env";

const useSnap = () => {
  const [snap, setSnap] = useState(null);
  console.log(snap)
  useEffect(() => {
    const midtransClientKey = ENV.MIDTRANS_CLIENT_KEY;
    const script = document.createElement("script");
    script.src = `${ENV.MIDTRANS_API_URL}/snap/snap.js`;
    script.setAttribute("data-client-key", midtransClientKey);
    script.onload = () => {
      setSnap(window.snap);
    };
    document.body.append(script);
    return () => {
      const scriptNode = document.getElementById("script");
      if (scriptNode) {
        document.body.removeChild(scriptNode);
      }
    };
  }, []);

  const snapEmbed = (snap_token, embedId, action) => {
    if (snap) {
      snap.embed(snap_token, {
        embedId,
        onSuccess: (result) => {
          console.log("success", result);
          action.onSuccess(result);
        },
        onPending: (result) => {
          console.log("pending", result);
          action.onPending(result);
        },
        onClose: () => {
          action.onClose();
        },
      });
    } else {
      console.error("Snap is not loaded yet.");
    }
  };

  return { snapEmbed };
};

export default useSnap;
