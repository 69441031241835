import React, { CSSProperties, useEffect, useRef } from "react";

interface MapIframeProps extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  src: string;
  style?: CSSProperties;
  iframeProps?: React.IframeHTMLAttributes<HTMLIFrameElement>;
}

const MapIframe: React.FC<MapIframeProps> = ({ src, style, iframeProps }) => {
  const iframeContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const iframeContainer = iframeContainerRef.current;

    if (iframeContainer) {
      const oldIframe = iframeContainer.querySelector('iframe');

      if (oldIframe) {
        const newIframe = oldIframe.cloneNode() as HTMLIFrameElement;
        newIframe.src = src;
        iframeContainer.replaceChild(newIframe, oldIframe);
      } else {
        const newIframe = document.createElement('iframe');
        newIframe.src = src;
        newIframe.width = "100%";
        newIframe.height = "100%";
        newIframe.style.border = "0";
        //@ts-ignore
        newIframe.loading = 'lazy';
        newIframe.referrerPolicy = "no-referrer-when-downgrade";
        Object.assign(newIframe, iframeProps);
        iframeContainer.appendChild(newIframe);
      }
    }
  }, [src, iframeProps]);

  return <div ref={iframeContainerRef} style={style} />;
};

export default MapIframe;
