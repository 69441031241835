import Joi from 'joi';
import { DURATION_PERIOD } from '../utils/constant';
import { DAY } from '../utils/date';

// const locationSchema = Joi.object({
//   mapLocation: Joi.string().required(),
//   completeAddress: Joi.string().required(),
//   useManualLocation: Joi.boolean().required(),
//   longitude: Joi.number().required().min(-180).max(180), // Longitude must be between -180 and 180
//   latitude: Joi.number().required().min(-90).max(90),   // Latitude must be between -90 and 90
//   timezone: Joi.string().required().valid(...TIMEZONES)
// });

const scheduleDetailSchema = Joi.object({
  startTime: Joi.string().pattern(/^(?:[01]\d|2[0-3]).[0-5]\d$/).required(),
  endTime: Joi.string().pattern(/^(?:[01]\d|2[0-3]).[0-5]\d$/).required(),
  price: Joi.number().integer().min(0).required(),
});

const schedulesSchema = Joi.object(
  Object.keys(DAY).reduce((schema, day) => {
    schema[day] = Joi.array().items(scheduleDetailSchema).required();
    return schema;
  }, {} as Record<string, Joi.Schema>)
).required();

export const productDataSchema = Joi.object({
  images: Joi.array().items(Joi.object({
    base64: Joi.string().optional().allow(""),
    file: Joi.any().optional(),
  })),
  name: Joi.string().min(5).required(),
  price: Joi.number().min(0).max(100000000).when('isCustomPrice', {
    is: true,
    then: Joi.number().min(1).required(),
    otherwise: Joi.number().optional().default(0)
  }),
  duration: Joi.number().min(1).max(1410).required(), // 0-1410 minutes (12 hours 30 minutes)
  durationUnit: Joi.string().valid(DURATION_PERIOD.MINUTES).optional().default(DURATION_PERIOD.MINUTES),
  quota: Joi.number().min(1).max(10000).required(),
  isCustomPrice: Joi.boolean().optional().default(false),
  schedules: schedulesSchema,
  description: Joi.string().min(5).required(),
  location: Joi.object({
    lat: Joi.number().optional().messages({
      'number.base': 'Latitude harus berupa angka',
    }).default(0),
    long: Joi.number().optional().messages({
      'number.base': 'Longitude harus berupa angka',
    }).default(0),
    search: Joi.string().optional().allow('').default(""),
    useManualLocation: Joi.boolean().optional().default(false),
  }),
  locationDetail: Joi.string().optional().default("").allow(""),
  allowReschedule: Joi.boolean().optional().default(false),
  allowRefund: Joi.boolean().optional().default(false),
});
export const editProductDataSchema = Joi.object({
  images: Joi.array().items(Joi.object({
    base64: Joi.string().optional().allow(""),
    file: Joi.any().optional(),
    url: Joi.any().optional().allow(null),
  })),
  name: Joi.string().min(5).required(),
  price: Joi.number().min(0).max(100000000).when('isCustomPrice', {
    is: true,
    then: Joi.number().min(1).required(),
    otherwise: Joi.number().optional().default(0)
  }),
  duration: Joi.number().min(1).max(1410).required(), // 0-1410 minutes (12 hours 30 minutes)
  durationUnit: Joi.string().valid(DURATION_PERIOD.MINUTES).optional().default(DURATION_PERIOD.MINUTES),
  quota: Joi.number().min(1).max(10000).required(),
  isCustomPrice: Joi.boolean().optional().default(false),
  schedules: schedulesSchema,
  description: Joi.string().min(5).required(),
  location: Joi.object({
    lat: Joi.number().optional().messages({
      'number.base': 'Latitude harus berupa angka',
    }).default(0),
    long: Joi.number().optional().messages({
      'number.base': 'Longitude harus berupa angka',
    }).default(0),
    search: Joi.string().optional().allow('').default(""),
    isCustomLocation: Joi.boolean().optional().default(false),
  }),
  locationDetail: Joi.string().optional().default("").allow(""),
  allowReschedule: Joi.boolean().optional().default(false),
  allowRefund: Joi.boolean().optional().default(false),
});
// useSinglePrice: Joi.boolean().required(),
// allowReschedule: Joi.boolean().required(),
// duration: Joi.number().min(0).max(1410).required(), // 0-1410 minutes (12 hours 30 minutes)
// durationPeriod: Joi.string().valid(DURATION_PERIOD.MINUTES).required(),
// quota: Joi.number().min(0).max(100).required(),
// price: Joi.number().min(0).max(100000000).required(),
// photos: Joi.array().items(Joi.string()).required(),
// location: locationSchema.required(),
// scheduleDetails: Joi.object().pattern(
//   Joi.string().valid(...Object.values(DAY_ENUM)),
//   Joi.array().items(scheduleDetailSchema)
// ).required(),
// disabled: Joi.boolean().required(),
// addOn: Joi.array().items(Joi.any()).required()
