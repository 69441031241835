// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mySwiper .swiper-pagination-bullet {
  transition: background-color 0.3s;
}

.mySwiper .swiper-pagination-bullet-active {
  background-color: var(--active-bullet-color, #fff); /* Default active bullet color */
}
`, "",{"version":3,"sources":["webpack://./src/components/ImageCarousel/ImageCarousel.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC;;AAEA;EACE,kDAAkD,EAAE,gCAAgC;AACtF","sourcesContent":[".mySwiper .swiper-pagination-bullet {\r\n  transition: background-color 0.3s;\r\n}\r\n\r\n.mySwiper .swiper-pagination-bullet-active {\r\n  background-color: var(--active-bullet-color, #fff); /* Default active bullet color */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
