import { combineReducers } from "redux";
import { CLEAR_ALL } from "../actions/actionList";
import confirmationDialogReducer, {
  ConfirmationDialogState,
} from "./confirmationDialog"; // Add this line
import businessReducer, { BusinessState } from "./business";
import userReducer, { UserReducerState } from "./user";
import productReducer, { ProductReducerState } from "./product";
export interface RootReducerState {
  confirmationDialog: ConfirmationDialogState;
  businessReducer: BusinessState;
  userReducer: UserReducerState;
  productReducer: ProductReducerState;
}

const appReducer = combineReducers({
  confirmationDialog: confirmationDialogReducer,
  businessReducer,
  userReducer,
  productReducer,
});

const rootReducer: typeof appReducer = (state, action) => {
  if (action.type === CLEAR_ALL) {
    localStorage.clear();
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
