import { Box, Typography } from "@mui/material";
import CustomButton from "../../components/CustomButton/CustomButton";
import AppContainer from "../../components/Layout/AppContainer/AppContainer";
import { FaCheckCircle } from "react-icons/fa";
import { COLOR } from "../../utils/color";
import { ButtonContainer, ContainerStyle, StatusBorder, StatusCircle, StatusContainer } from "./StatusInfo.styles";
import { CSSObject } from "@emotion/react";
import { useStatusInfo } from "../../context/StatusInfoContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash.isempty";
import { ROUTE_NAME, RoutePath } from "../../utils/constant";

const pulseAnimation: CSSObject = {
  animation: "pulse 1s infinite",
  "@keyframes pulse": {
    "0%": {
      transform: "scale(0.9)",
    },
    "50%": {
      transform: "scale(1)",
    },
    "100%": {
      transform: "scale(0.9)",
    },
  },
};

const StatusInfo = () => {
  const { statusInfoContent } = useStatusInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (isEmpty(statusInfoContent.primaryBtn?.text) || isEmpty(statusInfoContent.secondaryBtn)) {
      navigate(RoutePath[ROUTE_NAME.HOME], { replace: true });
    }
  }, [navigate, statusInfoContent, statusInfoContent.primaryBtn, statusInfoContent.secondaryBtn])
  return (
    <AppContainer sx={ContainerStyle}>
      <Box sx={StatusContainer}>
        <Box sx={StatusBorder}>
          <Box
            sx={{
              ...pulseAnimation,
              ...StatusCircle,
            }}
          >
            <FaCheckCircle color={COLOR.success500} size={"80px"} />
          </Box>
        </Box>
        <Typography
          variant="h6"
          textAlign="center"
          fontWeight={600}
          sx={{ mb: 3, mt: 5 }}
        >
          {statusInfoContent.title}
        </Typography>
        <Typography variant="body1" textAlign="center">
          {statusInfoContent.description}
        </Typography>
      </Box>
      <Box sx={ButtonContainer}>
        <CustomButton
          fullWidth
          onClick={statusInfoContent.primaryBtn?.onClick}
        >
          {statusInfoContent.primaryBtn?.text}
        </CustomButton>
        <CustomButton
          fullWidth
          variant="text"
          sx={{ mt: 1 }}
          onClick={statusInfoContent.secondaryBtn?.onClick}
        >
          {statusInfoContent.secondaryBtn?.text}
        </CustomButton>
      </Box>
    </AppContainer>
  );
};

export default StatusInfo;
