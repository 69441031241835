import { SxProps, Theme } from "@mui/material";
import { MIN_FONT_SIZE_BUTTON_ICON } from "../../utils/constant";
import { COLOR } from "../../utils/color";

export const ButtonStyle: SxProps<Theme> = {
  borderRadius: 3,
  textTransform: "capitalize",
  fontWeight: 600,
  p: '12px',
  px: 4,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width:'100%',
  alignItems: 'center',
  height: 'fit-content',
  "&:disabled": {
    cursor: "not-allowed",
    pointerEvents: "auto",
  },
};

export const EndormentStyle: SxProps<Theme> = {
  minWidth: MIN_FONT_SIZE_BUTTON_ICON,
  minHeight: MIN_FONT_SIZE_BUTTON_ICON,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  font: 'inherit',
  color: 'inherit',
};


export const BorderedButtonContainer: SxProps<Theme> = {
  border: `1px dashed ${COLOR.neutral300}`,
  backgroundColor: COLOR.neutral50,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: COLOR.neutral500,
  fontWeight: 500,
  flexDirection: "row",
  borderRadius: '12px',
  cursor: "pointer",
  lineHeight: 'normal',
  "&:hover": {
    filter: "brightness(98%)",
  },
  "&:active": {
    filter: "brightness(95%)",
  },
};

