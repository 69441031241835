import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { COLOR } from "../../utils/color";

export const DatePickerContainer: SxProps<Theme> = {
  display: "flex",
  flexDirection: "row",
  overflowX: "scroll",
  gap: 2,
  px: 2,
  "&::-webkit-scrollbar": {
    display: "none",
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
};

export const MonthPickerContainer: SxProps<Theme> = {
  display: "flex",
  flexDirection: "row",
  color: COLOR.neutral500,
  justifyContent: "space-between",
  alignItems: "center",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
};

export const DateContainer: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  px: 2,
  minWidth: "60px",
  minHeight: "80px",
  py: 1,
  borderRadius: 2,
  border: `1px solid ${COLOR.neutral300}`,
  cursor: 'pointer',
  opacity: 1,
  color: 'black',
};

export const disabledDateSx: SxProps<Theme> = {
  backgroundColor: 'white',
  border: `1px solid ${COLOR.neutral300}`,
  color: '#262422',
  cursor: "not-allowed",
  opacity: 0.5,
};
