import React from 'react';
import { IconSVGProps } from '../../types/globalTypes';

const ProductIcon: React.FC<IconSVGProps> = ({
  size = 18,
  color = "#22C55E",
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3334 13.9998V4.80089C17.3334 4.78384 17.3332 4.76682 17.3326 4.74984H11.5001V6.49984C11.5001 7.88055 10.3808 8.99984 9.00008 8.99984C7.61937 8.99984 6.50008 7.88055 6.50008 6.49984V4.74984H0.667529C0.667009 4.76682 0.666748 4.78384 0.666748 4.80089V13.9998C0.666748 15.8408 2.15913 17.3332 4.00008 17.3332H14.0001C15.841 17.3332 17.3334 15.8408 17.3334 13.9998ZM14.0798 1.39695L16.3959 3.24984H1.60426L3.92037 1.39695C4.51141 0.924108 5.24578 0.666504 6.00269 0.666504L11.9975 0.666513C12.7544 0.666514 13.4888 0.924117 14.0798 1.39695Z"
        fill={color}
      />
    </svg>
  );
};

export default ProductIcon;
