import { ThemeProvider } from "@emotion/react"
import { createTheme, ThemeOptions } from "@mui/material";

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: 'Poppins',
  },
};


const ThemeWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const theme = createTheme(themeOptions);

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}

export default ThemeWrapper;