import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Checkbox, SxProps, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetProductByProductId } from '../../query/queries';
import SubMenuHeader from '../../components/Layout/SubMenuHeader/SubMenuHeader';
import { FaArrowLeft } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../redux/reducers';
import isEmpty from 'lodash.isempty';
import { initialState, setProductEditData } from '../../redux/reducers/product';
import { DEFAULT_PRODUCT_DATA, DEFAULT_SNACKBAR_PROPS, DURATION_PERIOD, ROUTE_NAME, RoutePath, TIMEZONES, UNIT_TEXT } from '../../utils/constant';
import { convertProductScheduleToManagedSchedule, convertProductScheduleToStateSchedule, convertSchedulesToList, fileToBase64, formatNumber, parseProductScheduleToAPISchedule } from '../../utils/helper';
import AppContainer from '../../components/Layout/AppContainer/AppContainer';
import { ContainerStyle, FooterContainer, ImageGridContainer } from './ProductDetail.styles';
import CustomButton from '../../components/CustomButton/CustomButton';
import { FirstImageComponent, SmallImageComponent } from '../Business/AddProduct/components/ImageComponent';
import HiddenInput from '../../components/HiddenInput/HiddenInput';
import { FieldContainer, FormContainerStyle, RowField, TextButtonSx } from '../../styles/global.styles';
import TextInput from '../../components/Forms/TextInput/TextInput';
import TextArea from '../../components/Forms/TextArea/TextArea';
import { COLOR } from '../../utils/color';
import NumberInput from '../../components/Forms/NumberInput/NumberInput';
import OpacityButton from '../../components/OpacityButton/OpacityButton';
import { Theme } from '@emotion/react';
import { BpCheckedIcon, BpIcon } from '../../components/CheckboxIcon/CheckboxIcon';
import InputTitle from '../../components/Forms/InputTitle/InputTitle';
import ScheduleTable from '../../components/Tables/ScheduleTables';
import LocationIcon from '../../assets/svg/LocationIcon';
import { DEFAULT_PRODUCT_DATA_ERRORS, ProductErrorType } from '../Business/AddProduct/AddProduct';
import { EditProductData, ProductData, Schedule, SchedulePriceType } from '../../types/globalTypes';
import { editProductDataSchema, productDataSchema } from '../../joiSchema/product';
import Joi, { ValidationErrorItem } from 'joi';
import { compressImage, isValidFileSize, MAX_FILE_SIZE_IN_MB } from '../../utils/image';
import { LuPencil } from 'react-icons/lu';
import ScheduleBottomSheet from '../../components/BottomSheets/ScheduleBottomSheet';
import dayjs from 'dayjs';
import LocationBottomSheet from '../../components/BottomSheets/LocationBottomSheet';
import { setEditBusinessData } from '../../redux/reducers/business';
import { enqueueSnackbar } from 'notistack';
import { errorLogger } from '../../utils/logger';
import { useUpdateProductById, useUploadProductPhotos } from '../../query/mutations';

type DetailProductParamsType = { productId: string; mode: "edit" | "view" };
const DetailProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userData } = useSelector((state: RootReducerState) => state.userReducer);
  const { productId, mode = "" } = useParams<DetailProductParamsType>();
  const productReducer = useSelector((state: RootReducerState) => state.productReducer);
  const editProductData = productReducer.edit;

  const getProductByIdQueries = useGetProductByProductId(productId || "");
  const fetchedProductDetail = useMemo(() => { return getProductByIdQueries?.data?.data?.data || DEFAULT_PRODUCT_DATA }, [getProductByIdQueries?.data?.data?.data])
  const uploadProductPhotosMutation = useUploadProductPhotos();

  const [sheetState, setSheetState] = useState<{ schedule: boolean; location: boolean; }>({
    schedule: false,
    location: false
  });

  const [errors, setErrors] = useState<ProductErrorType>(DEFAULT_PRODUCT_DATA_ERRORS);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const scheduleList = convertSchedulesToList(editProductData.schedules);
  const updateProductByIdMutation = useUpdateProductById();

  const handlePriceChange = (value: string): number => {
    const MIN = 0;
    const MAX = 100000000000000000000;
    const numberValue = parseInt(value, 10) || initialState.edit.price;
    return Math.min(Math.max(numberValue, MIN), MAX);
  };


  const updateSchedulePrices = (price: number): Schedule => {
    const updatedSchedules: Schedule = {};

    Object.keys(editProductData.schedules).forEach((scheduleDay) => {
      updatedSchedules[scheduleDay] = editProductData.schedules[scheduleDay].map((schedule) => ({
        ...schedule,
        price,
      }));
    });
    return updatedSchedules;
  };


  const handleOnChange = (key: keyof ProductData) => (
    e:
      | React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
      > | any
  ) => {
    e?.preventDefault?.();
    let updatedProductData: any = { ...editProductData };
    switch (key) {
      case "price":
        const newPrice = handlePriceChange(e.target.value);
        updatedProductData.price = newPrice;
        updatedProductData.schedules = updateSchedulePrices(editProductData.isCustomPrice ? newPrice : 0);
        break;

      case "isCustomPrice":
        const newIsCustomPrice = !editProductData.isCustomPrice;
        updatedProductData.isCustomPrice = newIsCustomPrice;
        updatedProductData.schedules = updateSchedulePrices(newIsCustomPrice ? editProductData.price : 0);
        break;
      case "duration":
      case "quota":
        updatedProductData[key] = parseInt(e?.target?.value, 10) || 0;
        break;
      default:
        updatedProductData[key] = e?.target?.value;
        break;
    }
    dispatch(setProductEditData(updatedProductData as EditProductData));
  };

  const handleBlur =
    (field: keyof ProductData) =>
      () => {
        const { error } = Joi.object({
          [field]: productDataSchema.extract(field),
        }).validate({ [field]: editProductData[field] });
        if (error) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: error.message,
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
  const validateError = () => {
    const { error } = editProductDataSchema.validate(editProductData, { abortEarly: false });
    if (error) {
      const errors = error.details.reduce((acc: any, err: ValidationErrorItem) => {
        return { ...acc, [err.path[0]]: err.message };
      }, {});
      setErrors((prevErrors) => ({ ...prevErrors, ...errors }));
      return true;
    }
    return false;
  }
  const handleSchedulesChange = (day: string) => (updatedSchedules: SchedulePriceType[]) => {
    const newSchedule = updatedSchedules.map(sched => {
      return {
        endTime: dayjs(sched.time.endTime).format('HH.mm'),
        startTime: dayjs(sched.time.endTime).format('HH.mm'),
        price: sched.price,
      }
    });
    const changedSchedules: EditProductData['schedules'] = {
      ...editProductData.schedules,
      [day]: newSchedule,
    };
    dispatch(setEditBusinessData(changedSchedules))
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      const newImage: EditProductData['images'] = [];
      for (const file of files) {
        if (!isValidFileSize(file)) {
          alert(
            `File size should be less than ${MAX_FILE_SIZE_IN_MB / (1024 * 1024)
            } MB`
          );
          continue;
        }

        try {
          const compressedFile = await compressImage(file);
          const base64String = await fileToBase64(compressedFile);
          newImage.push({
            base64: base64String,
            file: compressedFile,
            url: null,
          });
        } catch (error) {
          console.error("Error compressing the file:", error);
        }
      }

      const emptyIndices = editProductData.images.reduce<number[]>(
        (acc, image, index) => {
          const isEmptySlot = image.base64 === "" && image.url === null;
          if (isEmptySlot) acc.push(index);
          return acc;
        }, []);

      const updatedImages = [...editProductData.images];
      newImage.forEach((imageData) => {
        if (emptyIndices.length > 0) {
          updatedImages[emptyIndices.shift()!] = imageData;
        } else {
          updatedImages.shift();
          updatedImages.push(imageData);
        }
      });

      const newProductData = { ...editProductData, images: updatedImages };
      dispatch(setProductEditData(newProductData));
    }
  };
  const removeImage = (index: number, e: React.MouseEvent) => {
    e.stopPropagation();

    const updatedImages = editProductData.images.filter((_, i) => i !== index);
    updatedImages.push({
      base64: '',
      file: null,
      url: null
    });
    const updatedProductData = { ...editProductData, images: updatedImages };
    dispatch(setProductEditData(updatedProductData))
  };

  const handleOnClickUpdate = async () => {
    const hasError = validateError();
    if (!hasError && userData.companyId) {
      try {
        await updateProductByIdMutation.mutateAsync({
          productId: fetchedProductDetail.productID,
          companyID: userData.companyId,
          name: editProductData.name,
          description: editProductData.description,
          useSinglePrice: editProductData.isCustomPrice,
          allowReschedule: editProductData.allowReschedule,
          duration: editProductData.duration,
          durationPeriod: editProductData.durationUnit as DURATION_PERIOD,
          quota: editProductData.quota,
          price: editProductData.price,
          photos: [],
          location: {
            mapLocation: editProductData.location.search,
            completeAddress: editProductData.locationDetail,
            useManualLocation: false,
            longitude: editProductData.location.lat,
            latitude: editProductData.location.long,
            timezone: TIMEZONES[0],
          },
          scheduleDetails: parseProductScheduleToAPISchedule(editProductData.schedules),
          disabled: false,
          addOn: [],
        });
        if (!isEmpty(editProductData.images)) {
          const files = editProductData.images
            .map(({ file, url }) => {
              return file || url;
            })
            .filter((file): file is File | string => file !== null && file !== undefined);
          if (files.length !== 0) {
            const photosPayload = {
              companyId: userData.companyId,
              productId: fetchedProductDetail.productID,
              files: files,
            };
            await uploadProductPhotosMutation.mutateAsync(photosPayload);
          }
        }
        navigate(RoutePath[ROUTE_NAME.PRODUCT], { replace: true })
      } catch (error: any) {
        errorLogger(error);
        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: 'error',
          message: `Terjadi kesalahan! Mohon coba beberapa saat lagi! ${error?.data?.message}`
        })
      }
    } else {
      enqueueSnackbar({
        ...DEFAULT_SNACKBAR_PROPS,
        variant: 'warning',
        message: `Mohon periksa kembali data yang anda input! (${userData.companyId}) ${hasError}`
      })
    }
  };

  useEffect(() => {
    const isValidMode = ["view", "edit"].includes(mode);
    if (productId && !isValidMode) {
      navigate(-1);
    }
  }, [productId, mode, navigate]);

  const initializeData = useCallback(() => {
    if (!getProductByIdQueries.isLoading && getProductByIdQueries.isFetched && !isEmpty(fetchedProductDetail)) {
      const images = new Array(5).fill(null).map((_, idx) => ({
        file: null,
        base64: '',
        url: fetchedProductDetail.photosSignedURL[idx] || null,
      }));
      dispatch(setProductEditData({
        images,
        name: fetchedProductDetail.name,
        price: fetchedProductDetail.price || 0,
        duration: fetchedProductDetail.duration,
        durationUnit: fetchedProductDetail.durationPeriod || DURATION_PERIOD.MINUTES,
        quota: fetchedProductDetail.quota || 0,
        isCustomPrice: fetchedProductDetail.useSinglePrice,
        schedules: convertProductScheduleToStateSchedule(fetchedProductDetail.scheduleDetails),
        description: fetchedProductDetail.description,
        location: {
          lat: fetchedProductDetail.location.latitude,
          long: fetchedProductDetail.location.longitude,
          search: fetchedProductDetail.location.mapLocation,
          isCustomLocation: fetchedProductDetail.location.useManualLocation,
        },
        locationDetail: fetchedProductDetail.location.completeAddress,
        allowReschedule: false,
      }));
    }
  }, [dispatch, fetchedProductDetail, getProductByIdQueries.isFetched, getProductByIdQueries.isLoading]);


  useEffect(initializeData, [initializeData])
  const selectedLocation = editProductData
    .location
    .isCustomLocation ?
    [
      editProductData.location.lat,
      editProductData.location.long
    ].join(', ') :
    editProductData.location.search;

  return (
    <AppContainer
      bottomChildren={
        <Box sx={FooterContainer}>
          <CustomButton
            fullWidth
            onClick={handleOnClickUpdate}
          >
            <Typography variant="body1" fontWeight={500}>
              Update
            </Typography>
          </CustomButton>
        </Box>
      }
    >
      <Box sx={ContainerStyle}>
        <SubMenuHeader
          leftNav={{
            icon: <FaArrowLeft />,
            onClick: () => {
              navigate(RoutePath[ROUTE_NAME.PRODUCT], { replace: true })
            }
          }}
          text={`${mode === 'edit' ? 'Ubah' : 'Lihat'} Produk`}
        />
        <Box sx={ImageGridContainer}>
          {editProductData.images.map((image, index) => {
            const displayedImg = image.url || image.base64;
            if (index === 0)
              return (
                <FirstImageComponent
                  key={index}
                  image={displayedImg}
                  onClickClose={(e) => removeImage(0, e)}
                  onClick={() => {
                    if (!displayedImg) {
                      fileInputRef?.current?.click?.();
                    }
                  }}
                />
              );
            return (
              <SmallImageComponent
                key={index}
                image={displayedImg}
                index={index}
                onClick={() => {
                  if (!displayedImg) {
                    fileInputRef?.current?.click?.()
                  }
                }}
                onClickClose={(e) => removeImage(index, e)}
              />
            );
          })}
        </Box>
        <HiddenInput
          ref={fileInputRef}
          onChange={handleFileChange}
          hideMethod="none"
          multiple={true}
          disabled={isLoading}
        />
        <Box sx={FormContainerStyle}>
          <Box sx={FieldContainer}>
            <TextInput
              title="Nama Produk"
              required
              textInputProps={{
                placeholder: "Ketik Nama Produk-Mu",
                value: editProductData.name,
                onChange: handleOnChange("name"),
                onBlur: handleBlur("name"),
                disabled: isLoading,
              }}
              error={errors.name !== undefined}
              helper={{
                color: COLOR.danger500,
                text: errors.name
              }}
            />
          </Box>
          <Box sx={FieldContainer}>
            <TextArea
              title="Deskripsi Produk"
              required
              textAreaProps={{
                value: editProductData.description,
                onBlur: handleBlur("description"),
                onChange: handleOnChange("description"),
                placeholder:
                  "Deskripsikan produk anda yang anda ingin perlihatkan di website anda.",
                disabled: isLoading,
              }}
              error={errors.description !== undefined}
              helper={{
                color: COLOR.danger500,
                text: errors.description
              }}
            />
          </Box>
          <Box sx={FieldContainer}>
            <Box sx={RowField}>
              <NumberInput
                title="Durasi"
                disabled={isLoading}
                required
                sx={{ width: "100%" }}
                endEndorment={
                  <Typography
                    component="span"
                    width="100%"
                    whiteSpace="nowrap"
                    textTransform="lowercase"
                    color={COLOR.neutral500}
                  >
                    / {UNIT_TEXT?.[editProductData.durationUnit as DURATION_PERIOD] || UNIT_TEXT[DURATION_PERIOD.MINUTES]}
                  </Typography>
                }
                numberInputProps={{
                  placeholder: "30",
                  value: editProductData.duration
                    ? String(editProductData.duration)
                    : undefined,
                  onChange: handleOnChange("duration"),
                  onBlur: handleBlur("duration"),
                }}
                error={errors.duration !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.duration
                }}
              />
              <NumberInput
                title="Kuota"
                required
                sx={{ width: "100%" }}
                disabled={isLoading}
                numberInputProps={{
                  placeholder: "0",
                  value: String(editProductData.quota),
                  onChange: handleOnChange("quota"),
                  onBlur: handleBlur("quota"),
                }}
                error={errors.quota !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.quota
                }}
              />
            </Box>
          </Box>

          <Box sx={FieldContainer}>
            <NumberInput
              title="Harga"
              required
              startEndorment={"Rp"}
              formatFunction={(value) => formatNumber(Number(value))}
              disabled={!editProductData.isCustomPrice || isLoading}
              numberInputProps={{
                placeholder: "100.000",
                value: String(editProductData.price),
                onChange: !editProductData.isCustomPrice || isLoading ? () => { } : handleOnChange("price"),
                onBlur: handleBlur("price"),
              }}
              error={errors.price !== undefined}
              helper={{
                color: COLOR.danger500,
                text: errors.price
              }}
            />
          </Box>

          <OpacityButton
            sx={
              {
                ...RowField,
                ...FieldContainer,
                alignItems: "center",
              } as SxProps<Theme>
            }
            disableOpacity={true}
            disabled={isLoading}
            onClick={handleOnChange("isCustomPrice")}
          >
            <Checkbox
              inputProps={{
                "aria-label": "Custom Price",
              }}
              checked={editProductData.isCustomPrice}
              disableRipple
              onClick={handleOnChange("isCustomPrice")}
              checkedIcon={<BpCheckedIcon />}
              icon={<BpIcon />}
              sx={{ p: 0 }}
            />
            <Typography>Gunakan satu harga</Typography>
          </OpacityButton>
          <Box sx={FieldContainer}>
            <InputTitle
              title="Jadwal Tersedia"
              required
              textProps={{
                sx: {
                  mb: 1,
                },
              }}
            />
            <ScheduleTable
              schedule={scheduleList}
            />
          </Box>
          <CustomButton
            variant="dashed"
            sx={{ mt: 2 }}
            disabled={isLoading}
            startEndorment={<LuPencil size={18} />}
            onClick={() => {
              setSheetState(prev => ({ ...prev, schedule: true }));
            }}
          >
            <Typography
              component="span"
              fontWeight="inherit"
              fontSize="inherit"
            >
              Atur Jadwal
            </Typography>
          </CustomButton>
          <Box sx={FieldContainer}>
            <InputTitle title="Pilih Lokasi" required />
            <CustomButton
              variant="outlined"
              sx={{ mt: 2, ...TextButtonSx, }}
              endormentSx={{
                start: { mr: 1 },
              }}
              startEndorment={<LocationIcon />}
              disabled={isLoading}
              onClick={() => {
                setSheetState(prev => ({ ...prev, location: true }));
              }}
            >
              <Typography
                component="span"
                fontWeight="inherit"
                fontSize="inherit"
                color={
                  (selectedLocation)
                    ? "black"
                    : COLOR.neutral500
                }
              >
                {selectedLocation || 'Belum Pilih lokasi'}
              </Typography>
            </CustomButton>
          </Box>
          <Box sx={FieldContainer}>
            <TextArea
              title="Alamat Lengkap"
              required={false}
              disabled={isLoading}
              textAreaProps={{
                value: editProductData.locationDetail,
                onChange: handleOnChange("locationDetail"),
                onBlur: handleBlur("locationDetail"),
                placeholder: "Tulis alamat lengkap disini",
              }}
            />
          </Box>
        </Box>
      </Box>
      <ScheduleBottomSheet
        isCustomPrice={editProductData.isCustomPrice}
        price={editProductData.price}
        handleSchedulesChange={handleSchedulesChange}
        schedules={convertProductScheduleToManagedSchedule(editProductData.schedules)}
        open={sheetState.schedule}
        bottomSheetProps={{
          onDismiss: () => setSheetState(prev => ({ ...prev, schedule: false })),
          blocking: false,
        }}
      />
      <LocationBottomSheet
        open={sheetState.location}
        businessLocation={fetchedProductDetail.location}
        onClickSave={(savedLocation) => {
          dispatch(setProductEditData({
            location: {
              isCustomLocation: savedLocation.isCustomLocation,
              lat: Number(savedLocation.lat || 0),
              long: Number(savedLocation.long || 0),
              search: savedLocation.search,
            }
          }));
          setSheetState(prev => ({ ...prev, location: false }))
        }}
        bottomSheetProps={{
          onDismiss: () => setSheetState(prev => ({ ...prev, location: false }))
        }}
      />
    </AppContainer>
  );
};

export default DetailProduct;
