import { Theme } from "@emotion/react";
import { Box, SxProps, Typography } from "@mui/material";
import { useRef } from "react";
import { COLOR } from "../../../utils/color";
import OpacityButton from "../../OpacityButton/OpacityButton";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

type NavPropType = {
  icon?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  disableOpacity?: boolean;
}
interface SubMenuHeaderPropsI {
  leftNav: NavPropType;
  rightNav?: NavPropType;
  text: string;
}
const SubMenuHeader: React.FC<SubMenuHeaderPropsI> = (props) => {
  const { leftNav, rightNav } = props;
  const iconStyle: SxProps<Theme> = {
    width: 50,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  }

  const handleOnLeftNavClick = leftNav.onClick || (() => {
    navigate(-1);
  })

  const leftIcon: SxProps<Theme> = {
    left: 5,
  }
  const rightIcon: SxProps<Theme> = {
    right: 0,
    pointerEvents: rightNav?.onClick ? 'auto' : 'none',
  }
  const leftIconRef = useRef<HTMLButtonElement | null>(null);
  const rightIconRef = useRef<HTMLButtonElement | null>(null);
  const iconWidth = Math.max(
    (leftIconRef?.current?.clientWidth) || 0,
    (rightIconRef?.current?.clientWidth) || 0,
    50
  )
  const navigate = useNavigate();
  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', position: 'relative', borderBottom: `1px solid ${COLOR.neutral200}`, py: 2 }}>
      <OpacityButton
        onClick={handleOnLeftNavClick}
        sx={{
          ...iconStyle,
          ...leftIcon,
          ...leftNav.sx,
          ...(leftNav?.icon ? {} : { display: 'none' })
        }}
        disabled={leftNav.disabled}
        disableOpacity={leftNav.disableOpacity}
        ref={leftIconRef}
      >
        {leftNav.icon || <FaArrowLeft />}
      </OpacityButton>
      <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', mx: `${iconWidth}px` }}>
        <Typography variant="h6" fontWeight={600} textTransform="capitalize">
          {props.text}
        </Typography>
      </Box>
      <OpacityButton
        onClick={rightNav?.onClick}
        sx={{
          ...iconStyle,
          ...rightIcon,
          ...rightNav?.sx,
          ...(rightNav?.icon ? {} : { display: 'none' })
        }}
        disabled={rightNav?.disabled}
        disableOpacity={rightNav?.disableOpacity}
        ref={rightIconRef}
      >
        {rightNav?.icon}
      </OpacityButton>
    </Box>
  )
};

export default SubMenuHeader;
