import React from 'react';
import { IconSVGProps } from '../../types/globalTypes';

const PhoneIcon: React.FC<IconSVGProps> = ({ size = 20, color = "black", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 17V15.3541C19 14.5363 18.5021 13.8008 17.7428 13.4971L15.7086 12.6835C14.7429 12.2971 13.6422 12.7156 13.177 13.646L13 14C13 14 10.5 13.5 8.5 11.5C6.5 9.5 6 7 6 7L6.35402 6.82299C7.28438 6.35781 7.70285 5.25714 7.31654 4.29136L6.50289 2.25722C6.19916 1.4979 5.46374 1 4.64593 1H3C1.89543 1 1 1.89543 1 3C1 11.8366 8.16344 19 17 19C18.1046 19 19 18.1046 19 17Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhoneIcon;
