import React, { createContext, useContext, useState } from 'react';

interface StatusBtnProps {
  onClick?: () => void;
  text?: string;
}

interface StatusInfoContentType {
  title?: string;
  description?: string;
  primaryBtn?: StatusBtnProps;
  secondaryBtn?: StatusBtnProps;
}

interface StatusInfoContextProps {
  statusInfoContent: StatusInfoContentType;
  setStatusInfoContent: React.Dispatch<React.SetStateAction<StatusInfoContentType>>;
}

const StatusInfoContext = createContext<StatusInfoContextProps | undefined>(undefined);

export const useStatusInfo = () => {
  const context = useContext(StatusInfoContext);
  if (!context) {
    throw new Error('useStatusInfo must be used within a StatusInfoProvider');
  }
  return context;
};

export const StatusInfoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [statusInfoContent, setStatusInfoContent] = useState<StatusInfoContentType>({
    title: '',
    description: '',
    primaryBtn: {
      text: '',
      onClick: () => {},
    },
    secondaryBtn: {
      text: '',
      onClick: () => {},
    },
  });

  return (
    <StatusInfoContext.Provider value={{ statusInfoContent, setStatusInfoContent }}>
      {children}
    </StatusInfoContext.Provider>
  );
};

