import React from 'react';
import { IconSVGProps } from '../../types/globalTypes';

const SearchIcon: React.FC<IconSVGProps> = ({
  size = 24,
  color = "#94A3B8",
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.25 10.5C18.25 14.7802 14.7802 18.25 10.5 18.25C6.21979 18.25 2.75 14.7802 2.75 10.5C2.75 6.21979 6.21979 2.75 10.5 2.75C14.7802 2.75 18.25 6.21979 18.25 10.5ZM10.5 19.75C15.6086 19.75 19.75 15.6086 19.75 10.5C19.75 5.39137 15.6086 1.25 10.5 1.25C5.39137 1.25 1.25 5.39137 1.25 10.5C1.25 15.6086 5.39137 19.75 10.5 19.75ZM21.4217 21.4217C20.6506 22.1928 19.4004 22.1928 18.6293 21.4217L16.7563 19.5488C17.8465 18.7936 18.7936 17.8465 19.5488 16.7563L21.4217 18.6292C22.1928 19.4003 22.1928 20.6506 21.4217 21.4217Z"
        fill={color}
      />
    </svg>
  );
};

export default SearchIcon;
