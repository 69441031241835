import { Box, Typography } from '@mui/material';
import { COLOR } from '../../utils/color';

interface ScheduleDetailItemProps {
  fullName: string;
  email: string;
  itemCount: number;
  onClick?: () => void;
}

export const ScheduleDetailItem: React.FC<ScheduleDetailItemProps> = ({ fullName, email, itemCount, onClick }) => (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent="space-between"
    p={1}
    gap={2}
    sx={{
      '&:hover, &:active': {
        backgroundColor: COLOR.primary50,
        cursor: 'pointer',
      },
    }}
    onClick={onClick}
  >
    <Box display="flex" flexDirection="column">
      <Typography fontWeight={600} fontSize={12}>{fullName}</Typography>
      <Typography fontWeight={400} fontSize={12} color={COLOR.neutral400}>{email}</Typography>
    </Box>
    <Typography fontWeight={400} fontSize={12} color={COLOR.neutral400}>({itemCount} item)</Typography>
  </Box>
);
