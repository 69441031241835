import React, { forwardRef, ChangeEvent, InputHTMLAttributes, CSSProperties } from 'react';

interface HiddenInputProps extends InputHTMLAttributes<HTMLInputElement> {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  accept?: string; 
  type?: string; 
  hideMethod?: 'hidden' | 'absolute' | 'none';
}

const HiddenInput = forwardRef<HTMLInputElement, HiddenInputProps>((
  { onChange, accept = 'image/*', type = 'file', hideMethod = 'hidden', ...props },
  ref
) => {
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
  };

  const getStyle = (): CSSProperties => {
    switch (hideMethod) {
      case 'hidden':
        return {
          opacity: 0,
          position: 'fixed',
          top: '-9999px',
          left: 0,
        } as CSSProperties;
      case 'absolute':
        return {
          position: 'absolute',
          left: '-9999px',
          top: 0,
        } as CSSProperties;
      case 'none':
        return {
          display: 'none',
        } as CSSProperties;
      default:
        return {};
    }
  };

  return (
    <input
      type={type}
      accept={accept}
      ref={ref}
      style={getStyle()}
      onChange={handleOnChange}
      {...props}
    />
  );
});


export default HiddenInput;
