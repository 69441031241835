import React, { useEffect, useState } from 'react';
import { isBase64Image } from '../../utils/helper';
import { errorLogger } from '../../utils/logger';
import isEmpty from 'lodash.isempty';
import { CircularProgress } from '@mui/material';
import { useDownloadSignedUrl } from '../../query/queries';

export interface ImageRendererProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
  alt?: string;
}

const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};
const placeholderImg = "https://placehold.co/200/#e2e8f0/FFF";
const ImageRenderer: React.FC<ImageRendererProps> = ({ src = placeholderImg, alt = 'Image', ...props }) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>(isEmpty(src) ? placeholderImg : src);
  const isImagePath = !isEmpty(src) && !isEmpty(src) && !isValidUrl(src) && !isBase64Image(src);

  const downloadSignedUrlQuery = useDownloadSignedUrl(isImagePath ? src : "", {
    enabled: isImagePath,
    retry: false,
  });

  useEffect(() => {
    if (downloadSignedUrlQuery.isSuccess && downloadSignedUrlQuery.data) {
      setImageUrl(downloadSignedUrlQuery.data.data.data);
    } else if (!isImagePath && src) {
      setImageUrl(src);
    } else if (isEmpty(src)) {
      setImageUrl(placeholderImg);
    }
  }, [downloadSignedUrlQuery.isSuccess, downloadSignedUrlQuery.data, src, isImagePath]);

  if (downloadSignedUrlQuery.isLoading) {
    return <CircularProgress />;
  }

  if (downloadSignedUrlQuery.isError) {
    errorLogger('Failed to download signed URL:', downloadSignedUrlQuery.error);
    return <img src={placeholderImg} alt={alt} {...props} />;
  }

  return <img src={imageUrl} alt={alt} {...props} />;
};

export default ImageRenderer;