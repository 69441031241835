import { Box, SxProps, Typography } from "@mui/material";
import MobileContainer from "../../components/Layout/MobileContainer/MobileContainer";
import { useEffect, useMemo } from "react";
import SubMenuHeader from "../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_NAME, RoutePath, TIMEZONE_LABEL, TIMEZONE_SHORTLABEL } from "../../utils/constant";
import { useGetTransactionById } from "../../query/queries";
import RowKeyValue from "../../components/RowKeyValue/RowKeyValue";
import dayjs from "dayjs";
import { getStatusTextPropsByStatus } from "../../utils/helper";
import StatusText from "../../components/StatusText/StatusText";
import { APIGetTransactionById } from "../../api/request.types";
import { PAYMENT_STATUS } from "../../types/globalTypes";
import { Theme } from "@emotion/react";
import { COLOR } from "../../utils/color";

const DEFAULT_TRANSACTION_DATA: APIGetTransactionById = {
  _id: "",
  transactionID: "",
  companyID: "",
  date: "",
  fullName: "",
  email: "",
  phoneNumber: "",
  productDetail: [],
  location: "",
  total: 0,
  notes: "",
  paymentStatus: PAYMENT_STATUS.UNPAID,
  paidWith: "",
  snapToken: "",
  snapRedirectURL: "",
  refundReason: "",
  isManuallyAdded: false,
  transactionTimestamp: {
    paidAt: "",
    requestRefundAt: null,
    refundedAt: null,
    refundCancelledAt: null,
    cancelledAt: null,
    _id: ""
  },
  createdAt: "",
  updatedAt: "",
  companyLogo: "",
  timezone: ""
};


const TransactionDetail = () => {
  const navigate = useNavigate();
  const { transactionId } = useParams<{ transactionId: string }>();
  const transactionByIdQueries = useGetTransactionById(transactionId || "");
  const transactionData = useMemo(() => {
    return transactionByIdQueries.data?.data?.data || DEFAULT_TRANSACTION_DATA;
  }, [transactionByIdQueries.data?.data?.data])
  useEffect(() => {
    if (!transactionId) {
      navigate(RoutePath[ROUTE_NAME.TRANSACTION], { replace: true });
    }
  }, [transactionId, navigate]);

  const titleSx: SxProps<Theme> = {
    fontWeight: 600,
  }
  return (
    <MobileContainer>
      <SubMenuHeader
        leftNav={{
          icon: <FaArrowLeft />,
        }}
        text={"Detail Transaksi"}
      />
      <Box display="flex" flexDirection="column" gap={2} pt={2} pb={5}>
        <Box display="flex" flexDirection="column" px={2}>
          <Typography fontWeight={600} variant="caption" color={COLOR.neutral500} textAlign="center">ID Transaksi: {transactionData.transactionID}</Typography>
          <Typography sx={titleSx}>Informasi Pembeli</Typography>
          <RowKeyValue title={"Nama"} value={transactionData?.fullName} />
          <RowKeyValue title={"Email"} value={transactionData?.email || "-"} />
          <RowKeyValue title={"No Telp"} value={transactionData?.phoneNumber} />
          <RowKeyValue title="Alamat:" value={transactionData.location}
          />
        </Box>
        <Box width="100%" height="6px" sx={{ backgroundColor: COLOR.neutral200 }} />
        <Box display="flex" flexDirection="column" px={2}>
          <Typography sx={titleSx}>Detail Produk</Typography>
          {
            transactionData?.productDetail?.map(product => {
              return (
                <Box display="flex" flexDirection="column">
                  <RowKeyValue
                    title=""
                    value={product.productName}
                  />
                  <RowKeyValue title="Jadwal:" value="" />
                  <Box>
                    {product.scheduleDetails?.map(
                      (schedule, key) => {
                        const start = dayjs(schedule.startTime, "HH.mm");
                        const end = dayjs(schedule.endTime, "HH.mm");
                        const date = dayjs(transactionData.date, "YYYY-MM-DD");
                        return (
                          <RowKeyValue
                            key={key}
                            title=""
                            value={`${date.format("DD MMM YYYY")} / ${start.format(
                              "HH.mm"
                            )} - ${end.format("HH.mm")} ${TIMEZONE_SHORTLABEL?.[transactionData.timezone] || ""
                              } (${end.diff(start, "minute")} menit)`}
                          />
                        );
                      }
                    )}
                  </Box>
                </Box>
              )
            })
          }
        </Box>
        <Box width="100%" height="6px" sx={{ backgroundColor: COLOR.neutral200 }} />
        <Box display="flex" flexDirection="column" px={2}>
          <Typography sx={titleSx}>Detail Pembayaran</Typography>
          <RowKeyValue
            title="Status:"
            renderValue={
              <StatusText
                {...getStatusTextPropsByStatus(transactionData.paymentStatus)}
              />
            }
          />
          <RowKeyValue
            title="Metode:"
            value={transactionData.paidWith}
          />
          <RowKeyValue
            title="Tanggal:"
            value={dayjs(transactionData.date, "YYYY-MM-DD").format(
              "DD MMM YYYY"
            )}
          />
          <RowKeyValue
            title="Waktu:"
            value={dayjs(transactionData.createdAt).format("HH.mm") + ` ${TIMEZONE_SHORTLABEL[transactionData.timezone] || TIMEZONE_LABEL[0]}`}
          />


        </Box>
      </Box>
    </MobileContainer>
  );
};

export default TransactionDetail;
