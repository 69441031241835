import axios, { AxiosError, AxiosResponse, AxiosRequestHeaders, AxiosRequestConfig } from "axios";
import Cookies from 'js-cookie';
import endpoints from "./endpoints";
import { COOKIE, ROUTE_NAME, RoutePath } from "../utils/constant";

const axiosInstance = axios.create({
  timeout: 10000,
});

const refreshToken = async (): Promise<string | null> => {
  try {
    const response = await axiosInstance.post(endpoints.REFRESH_TOKEN);
    const newToken = response.data.token;
    if (newToken) {
      Cookies.set(COOKIE.TOKEN, newToken, { path: '/', expires: 7 });
      return newToken;
    }
    return null;
  } catch (error) {
    console.error("Failed to refresh token", error);
    return null;
  }
};

const API = async <Response>(apiConfig: Partial<AxiosRequestConfig<any>>): Promise<AxiosResponse<Response>> => {
  let userToken = Cookies.get(COOKIE.TOKEN);
  if (userToken) {
    apiConfig.headers = {
      ...apiConfig.headers,
      Authorization: `Bearer ${userToken}`,
    } as AxiosRequestHeaders;
  }

  try {
    return await axiosInstance(apiConfig);
  } catch (error) {
    const axiosError = error as AxiosError;

    const unauthorizedPage = [
      RoutePath[ROUTE_NAME.LOGIN],
      RoutePath[ROUTE_NAME.REGISTER],
    ];
    const isUnAuthedPage = unauthorizedPage.includes(window.location.pathname);
    if (axiosError.response?.status === 401 && !isUnAuthedPage) {
      const newToken = await refreshToken();
      if (newToken) {
        apiConfig.headers = {
          ...apiConfig.headers,
          Authorization: `Bearer ${newToken}`,
        } as AxiosRequestHeaders;

        return await axiosInstance(apiConfig);
      } else {
        Cookies.remove(COOKIE.TOKEN, { path: '/' });
        window.location.href = RoutePath[ROUTE_NAME.LOGIN];
        throw new Error("Token refresh failed. Please log in again.");
      }
    }
    throw axiosError;
  }
};

export default API;
