import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Checkbox, SxProps, Typography } from "@mui/material";
import AppContainer from "../../../components/Layout/AppContainer/AppContainer";
import {
  ContainerStyle,
  FooterContainer,
  ImageGridContainer,
} from "./AddProduct.styles";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { COLOR } from "../../../utils/color";
import { DEFAULT_SNACKBAR_PROPS, DURATION_PERIOD, ROUTE_NAME, RoutePath, TIMEZONES, UNIT_TEXT } from "../../../utils/constant";
import SubMenuHeader from "../../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft } from "react-icons/fa";
import HiddenInput from "../../../components/HiddenInput/HiddenInput";
import {
  compressImage,
  isValidFileSize,
  MAX_FILE_SIZE_IN_MB,
} from "../../../utils/image";
import NumberInput from "../../../components/Forms/NumberInput/NumberInput";
import { convertProductScheduleToManagedSchedule, convertSchedulesToList, fileToBase64, formatNumber, parseProductScheduleToAPISchedule, selectedLocationStr } from "../../../utils/helper";
import {
  FieldContainer,
  FormContainerStyle,
  RowField,
  TextButtonSx,
} from "../../../styles/global.styles";
import TextInput from "../../../components/Forms/TextInput/TextInput";
import { ProductData, Schedule } from "../../../types/globalTypes";
import {
  BpCheckedIcon,
  BpIcon,
} from "../../../components/CheckboxIcon/CheckboxIcon";
import OpacityButton from "../../../components/OpacityButton/OpacityButton";
import { Theme } from "@emotion/react";
import InputTitle from "../../../components/Forms/InputTitle/InputTitle";
import ScheduleTable from "../../../components/Tables/ScheduleTables";
import { LuPencil } from "react-icons/lu";
import LocationIcon from "../../../assets/svg/LocationIcon";
import TextArea from "../../../components/Forms/TextArea/TextArea";
import { useLocationContext } from "../../../context/LocationPageContext";
import { productDataSchema } from "../../../joiSchema/product";
import Joi, { ValidationErrorItem } from "joi";
import { useManageSchedule } from "../../../context/ScheduleContext";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "../../../redux/reducers";
import { initialState, resetProductData, setProductData } from "../../../redux/reducers/product";
import { useCreateProduct, useUploadProductPhotos } from "../../../query/mutations";
import { enqueueSnackbar } from "notistack";
import { errorLogger } from "../../../utils/logger";
import isEmpty from "lodash.isempty";
import { FirstImageComponent, SmallImageComponent } from "./components/ImageComponent";

export const DEFAULT_PRODUCT_DATA_ERRORS: ProductErrorType = {
  name: undefined,
  description: undefined,
  duration: undefined,
  quota: undefined,
  price: undefined,
  location: undefined,
}

export type ProductErrorType = Record<
  keyof Pick<ProductData,
    'name' |
    'description' |
    'duration' |
    'quota' |
    'price' |
    'location'
  >,
  string | undefined>;


const AddProduct: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const { data: productData } = useSelector((state: RootReducerState) => state.productReducer);
  const { data: userData } = useSelector((state: RootReducerState) => state.userReducer);

  const [errors, setErrors] = useState<ProductErrorType>(DEFAULT_PRODUCT_DATA_ERRORS);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { savedLocation } = useLocationContext();
  const { setSchedules } = useManageSchedule();
  const scheduleList = convertSchedulesToList(productData.schedules);
  const createProductMutation = useCreateProduct();
  const uploadProductPhotosMutation = useUploadProductPhotos();

  const onSuccessCreateProduct = () => {
    enqueueSnackbar('Sukses menambahkan produk!', {
      ...DEFAULT_SNACKBAR_PROPS,
      variant: 'success',
    });
    clearField();
    navigate(RoutePath[ROUTE_NAME.PRODUCT]);
  }
  const handleCreateProduct = async (companyId: string) => {
    if (isEmpty(companyId)) return;
    try {
      setIsLoading(true);
      const response = await createProductMutation.mutateAsync({
        companyID: companyId,
        name: productData.name,
        description: productData.description,
        useSinglePrice: productData.isCustomPrice,
        allowReschedule: productData.allowReschedule,
        duration: productData.duration,
        durationPeriod: productData.durationUnit as DURATION_PERIOD,
        quota: productData.quota,
        price: productData.price,
        photos: [],
        location: {
          mapLocation: productData.location.search,
          completeAddress: productData.locationDetail,
          useManualLocation: false,
          longitude: productData.location.lat,
          latitude: productData.location.long,
          timezone: TIMEZONES[0],
        },
        scheduleDetails: parseProductScheduleToAPISchedule(productData.schedules),
        disabled: false,
        allowRefund: false,
        addOn: [],
      });
      const createdProductId = response.data.data;
      if (!isEmpty(productData.images)) {
        const files = productData.images
          .map(({ file }) => {
            return file;
          })
          .filter((file): file is File => file !== null && file !== undefined);

        if (files.length !== 0) {
          await uploadProductPhotosMutation.mutateAsync({
            companyId: companyId,
            productId: createdProductId,
            files: files,
          });
        }
      }

      onSuccessCreateProduct();
    } catch (error) {
      errorLogger(error);
      enqueueSnackbar('Sedang terjadi kendala, mohon coba beberapa saat lagi', {
        ...DEFAULT_SNACKBAR_PROPS,
        variant: 'error',
      })
    } finally {
      setIsLoading(false);
    }
  }

  const handleOnChange = (key: keyof ProductData) => (
    e:
      | React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
      > | any
  ) => {
    e?.preventDefault?.();
    let updatedProductData: any = { ...productData };
    switch (key) {
      case "price":
        const newPrice = handlePriceChange(e.target.value);
        updatedProductData.price = newPrice;
        updatedProductData.schedules = updateSchedulePrices(productData.isCustomPrice ? newPrice : 0);
        break;

      case "isCustomPrice":
        const newIsCustomPrice = !productData.isCustomPrice;
        updatedProductData.isCustomPrice = newIsCustomPrice;
        updatedProductData.schedules = updateSchedulePrices(newIsCustomPrice ? productData.price : 0);
        break;
      case "duration":
      case "quota":
        updatedProductData[key] = parseInt(e?.target?.value, 10) || 0;
        break;
      default:
        updatedProductData[key] = e?.target?.value;
        break;
    }
    dispatch(setProductData(updatedProductData as ProductData));
  };

  const handlePriceChange = (value: string): number => {
    const MIN = 0;
    const MAX = 100000000000000000000;
    const numberValue = parseInt(value, 10) || initialState.data.price;
    return Math.min(Math.max(numberValue, MIN), MAX);
  };

  const updateSchedulePrices = (price: number): Schedule => {
    const updatedSchedules: Schedule = {};

    Object.keys(productData.schedules).forEach((scheduleDay) => {
      updatedSchedules[scheduleDay] = productData.schedules[scheduleDay].map((schedule) => ({
        ...schedule,
        price,
      }));
    });
    return updatedSchedules;
  };

  const handleBlur =
    (field: keyof ProductData) =>
      () => {
        const { error } = Joi.object({
          [field]: productDataSchema.extract(field),
        }).validate({ [field]: productData[field] });
        if (error) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: error.message,
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
  const validateError = () => {
    const { error } = productDataSchema.validate(productData, { abortEarly: false });
    if (error) {
      const errors = error.details.reduce((acc: any, err: ValidationErrorItem) => {
        return { ...acc, [err.path[0]]: err.message };
      }, {});
      setErrors((prevErrors) => ({ ...prevErrors, ...errors }));
      return true;
    }
    return false;
  }

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      const newImage: ProductData['images'] = [];
      for (const file of files) {
        if (!isValidFileSize(file)) {
          alert(
            `File size should be less than ${MAX_FILE_SIZE_IN_MB / (1024 * 1024)
            } MB`
          );
          continue;
        }

        try {
          const compressedFile = await compressImage(file);
          const base64String = await fileToBase64(compressedFile);
          newImage.push({
            base64: base64String,
            file: compressedFile,
          });
        } catch (error) {
          console.error("Error compressing the file:", error);
        }
      }

      const emptyIndices = productData.images.reduce<number[]>(
        (acc, image, index) => {
          if (image.base64 === "") {
            acc.push(index);
          }
          return acc;
        },
        []
      );

      const updatedImages = [...productData.images];
      newImage.forEach((imageData) => {
        if (emptyIndices.length > 0) {
          updatedImages[emptyIndices.shift()!] = imageData;
        } else {
          updatedImages.shift();
          updatedImages.push(imageData);
        }
      });

      const newProductData = { ...productData, images: updatedImages };
      dispatch(setProductData(newProductData));
    }
  };
  const removeImage = (index: number, e: React.MouseEvent) => {
    e.stopPropagation();

    const updatedImages = productData.images.filter((_, i) => i !== index);
    updatedImages.push({
      base64: '',
      file: null,
    });
    const updatedProductData = { ...productData, images: updatedImages };
    dispatch(setProductData(updatedProductData))
  };

  const previousLocationDataRef = useRef(productData.location);
  useEffect(() => {
    const dataToBeSaved =
      savedLocation.lastChanged === 'latlong'
        ? {
          lat: Number(savedLocation.lat),
          long: Number(savedLocation.long),
          search: '',
          useManualLocation: savedLocation.isCustomLocation,
        }
        : {
          search: savedLocation.search,
          lat: 0,
          long: 0,
          useManualLocation: savedLocation.isCustomLocation,
        };

    const isChanged = JSON.stringify(dataToBeSaved) !== JSON.stringify(previousLocationDataRef.current);
    if (isChanged) {
      dispatch(setProductData({
        location: {
          ...dataToBeSaved,
        },
      }));
      previousLocationDataRef.current = { ...dataToBeSaved };
    }
  }, [dispatch, savedLocation]);

  const clearField = useCallback(() => {
    dispatch(resetProductData());
    setErrors(DEFAULT_PRODUCT_DATA_ERRORS)
  }, [dispatch]);
  const handleDeployProduct = () => {
    const hasError = validateError();
    if (!hasError && userData.companyId) {
      handleCreateProduct(userData.companyId);
    } else {
      enqueueSnackbar({
        ...DEFAULT_SNACKBAR_PROPS,
        variant: 'warning',
        message: `Mohon periksa kembali data yang anda input! (${userData.companyId}) ${hasError}`
      })
    }
  }
  const selectedLocation = selectedLocationStr({
    search: productData.location.search,
    lat: productData.location.lat,
    long: productData.location.long
  });

  useEffect(() => {
    if (isEmpty(userData.companyId)) {
      navigate(RoutePath[ROUTE_NAME.HOME], { replace: true });
    }
  }, [navigate, userData.companyId]);



  return (
    <AppContainer
      bottomChildren={
        <Box sx={FooterContainer}>
          <CustomButton
            fullWidth
            onClick={() => {
              handleDeployProduct();
            }}
          >
            <Typography variant="body1" fontWeight={500}>
              Terbitkan
            </Typography>
          </CustomButton>
        </Box>
      }
    >
      <Box sx={ContainerStyle}>
        <SubMenuHeader
          leftNav={{
            icon: <FaArrowLeft />,
            onClick: () => {
              navigate(RoutePath[ROUTE_NAME.PRODUCT], { replace: true })
            }
          }}
          text={"Tambah Produk"}
        />
        <Box sx={ImageGridContainer}>
          {productData.images.map((image, index) => {
            if (index === 0)
              return (
                <FirstImageComponent
                  key={index}
                  image={image.base64}
                  onClickClose={(e) => removeImage(0, e)}
                  onClick={() => {
                    if (!image.base64) {
                      fileInputRef?.current?.click?.();
                    }
                  }}
                />
              );
            return (
              <SmallImageComponent
                key={index}
                image={image.base64}
                index={index}
                onClick={() => {
                  if (!image.base64) {
                    fileInputRef?.current?.click?.()
                  }
                }}
                onClickClose={(e) => removeImage(index, e)}
              />
            );
          })}
        </Box>
        <HiddenInput
          ref={fileInputRef}
          onChange={handleFileChange}
          hideMethod="none"
          multiple={true}
          disabled={isLoading}
        />
        <Box sx={FormContainerStyle}>
          <Box sx={FieldContainer}>
            <TextInput
              title="Nama Produk"
              required
              textInputProps={{
                placeholder: "Ketik Nama Produk-Mu",
                value: productData.name,
                onChange: handleOnChange("name"),
                onBlur: handleBlur("name"),
                disabled: isLoading,
              }}
              error={errors.name !== undefined}
              helper={{
                color: COLOR.danger500,
                text: errors.name
              }}
            />
          </Box>
          <Box sx={FieldContainer}>
            <TextArea
              title="Deskripsi Produk"
              required
              textAreaProps={{
                value: productData.description,
                onBlur: handleBlur("description"),
                onChange: handleOnChange("description"),
                placeholder:
                  "Deskripsikan produk anda yang anda ingin perlihatkan di website anda.",
                disabled: isLoading,
              }}
              error={errors.description !== undefined}
              helper={{
                color: COLOR.danger500,
                text: errors.description
              }}
            />
          </Box>
          <Box sx={FieldContainer}>
            <Box sx={RowField}>
              <NumberInput
                title="Durasi"
                disabled={isLoading}
                required
                sx={{ width: "100%" }}
                endEndorment={
                  <Typography
                    component="span"
                    width="100%"
                    whiteSpace="nowrap"
                    textTransform="lowercase"
                    color={COLOR.neutral500}
                  >
                    / {UNIT_TEXT?.[productData.durationUnit as DURATION_PERIOD] || UNIT_TEXT[DURATION_PERIOD.MINUTES]}
                  </Typography>
                }
                numberInputProps={{
                  placeholder: "30",
                  value: productData.duration
                    ? String(productData.duration)
                    : undefined,
                  onChange: handleOnChange("duration"),
                  onBlur: handleBlur("duration"),
                }}
                error={errors.duration !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.duration
                }}
              />
              <NumberInput
                title="Kuota"
                required
                sx={{ width: "100%" }}
                disabled={isLoading}
                numberInputProps={{
                  placeholder: "0",
                  value: String(productData.quota),
                  onChange: handleOnChange("quota"),
                  onBlur: handleBlur("quota"),
                }}
                error={errors.quota !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.quota
                }}
              />
            </Box>
          </Box>

          <Box sx={FieldContainer}>
            <NumberInput
              title="Harga"
              required
              startEndorment={"Rp"}
              formatFunction={(value) => formatNumber(Number(value))}
              disabled={!productData.isCustomPrice || isLoading}
              numberInputProps={{
                placeholder: "100.000",
                value: String(productData.price),
                onChange: !productData.isCustomPrice || isLoading ? () => { } : handleOnChange("price"),
                onBlur: handleBlur("price"),
              }}
              error={errors.price !== undefined}
              helper={{
                color: COLOR.danger500,
                text: errors.price
              }}
            />
          </Box>

          <OpacityButton
            sx={
              {
                ...RowField,
                ...FieldContainer,
                alignItems: "center",
              } as SxProps<Theme>
            }
            disableOpacity={true}
            disabled={isLoading}
            onClick={handleOnChange("isCustomPrice")}
          >
            <Checkbox
              inputProps={{
                "aria-label": "Custom Price",
              }}
              checked={productData.isCustomPrice}
              disableRipple
              onClick={handleOnChange("isCustomPrice")}
              checkedIcon={<BpCheckedIcon />}
              icon={<BpIcon />}
              sx={{ p: 0 }}
            />
            <Typography>Gunakan satu harga</Typography>
          </OpacityButton>
          <Box sx={FieldContainer}>
            <InputTitle
              title="Jadwal Tersedia"
              required
              textProps={{
                sx: {
                  mb: 1,
                },
              }}
            />
            <ScheduleTable
              schedule={scheduleList}
            />
          </Box>
          <CustomButton
            variant="dashed"
            sx={{ mt: 2 }}
            disabled={isLoading}
            startEndorment={<LuPencil size={18} />}
            onClick={() => {
              const managedSchedule = convertProductScheduleToManagedSchedule(productData.schedules)
              setSchedules(managedSchedule);
              navigate(RoutePath[ROUTE_NAME.MANAGE_SCHEDULE]);
            }}
          >
            <Typography
              component="span"
              fontWeight="inherit"
              fontSize="inherit"
            >
              Atur Jadwal
            </Typography>
          </CustomButton>
          <Box sx={FieldContainer}>
            <InputTitle title="Pilih Lokasi" />
            <CustomButton
              variant="outlined"
              sx={{ mt: 2, ...TextButtonSx, }}
              endormentSx={{
                start: { mr: 1 },
              }}
              startEndorment={<LocationIcon />}
              disabled={isLoading}
              onClick={() => {
                navigate(RoutePath[ROUTE_NAME.LOCATION_BUSINESS]);
              }}
            >
              <Typography
                component="span"
                fontWeight="inherit"
                fontSize="inherit"
                color={
                  (selectedLocation)
                    ? "black"
                    : COLOR.neutral500
                }
              >
                {selectedLocation || 'Belum Pilih lokasi'}
              </Typography>
            </CustomButton>
          </Box>
          <Box sx={FieldContainer}>
            <TextArea
              title="Alamat Lengkap"
              required={false}
              disabled={isLoading}
              textAreaProps={{
                value: productData.locationDetail,
                onChange: handleOnChange("locationDetail"),
                onBlur: handleBlur("locationDetail"),
                placeholder: "Tulis alamat lengkap disini",
              }}
            />
          </Box>
        </Box>
      </Box>
    </AppContainer>
  );
};

export default AddProduct;
