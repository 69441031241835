import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

export const OptionListSx: SxProps<Theme> = {
  position: 'absolute',
  top: '100%',
  left: 0,
  right: 0,
  bgcolor: 'white',
  border: '1px solid',
  borderColor: 'divider',
  boxShadow: 1,
  zIndex: 999,
  mt: 1,
  maxHeight: 200,
  overflowY: 'auto',
  borderRadius: 2,
};