import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";
import { Box, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTE_NAME, RoutePath } from "../../utils/constant";
import CustomButton from "../../components/CustomButton/CustomButton";
import { COLOR } from "../../utils/color";
import MobileContainer from "../../components/Layout/MobileContainer/MobileContainer";
import { useSelector } from "react-redux";
import { RootReducerState } from "../../redux/reducers";
import isEmpty from "lodash.isempty";
import {
  useGetCompanyById,
  useGetTransactionByQuery,
} from "../../query/queries";
import Wallet from "./components/Wallet/Wallet";
import ProfileAvatar from "./components/ProfileAvatar/ProfileAvatar";
import OpacityButton from "../../components/OpacityButton/OpacityButton";
import GearIcon from "../../assets/svg/GearIcon";
import { CurrencyCodeType, PAYMENT_STATUS } from "../../types/globalTypes";
import TransactionCard from "../../components/TransactionCard/TransactionCard";
import dayjs from "dayjs";
import NeemaLogoPNG from '../../assets/images/neema-logo.png';

const Business: React.FC = () => {
  const businessImage = require("../../assets/images/business-image.png");
  const navigate = useNavigate();
  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );
  const getTransactionListQueries = useGetTransactionByQuery({
    companyID: userReducer.data.companyId,
    endDate: dayjs().format("YYYY-MM-DD"),
    startDate: dayjs().format("YYYY-MM-DD"),
  });

  const transactionList = useMemo(() => {
    return getTransactionListQueries.data?.data?.data || [];
  }, [getTransactionListQueries.data?.data?.data]);

  const _renderNoBusinessComponent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          flexDirection: "column",
          px: 2,
        }}
      >
        <Box sx={{ width: 280, minHeight: 280 }}>
          <img
            src={businessImage}
            alt="business-image"
            className="img-default"
          />
        </Box>
        <Typography
          variant="h6"
          sx={{
            px: 3,
            maxWidth: "400px",
            textAlign: "center",
            fontWeight: 500,
            color: COLOR.neutral600,
          }}
        >
          Buka Usaha Anda Sekarang dan Nikmati Kemudahan dalam Pemesanan Jadwal!
        </Typography>

        <CustomButton
          onClick={() => navigate(RoutePath.CREATE_BUSINESS)}
          sx={{ mt: 5, width: "fit-content" }}
        >
          <Typography variant="body1" fontWeight={500}>
            Buka Usaha
          </Typography>
        </CustomButton>
      </Box>
    );
  };

  const _renderBusinessByCompanyId = () => {
    const companyId = userReducer.data.companyId;
    const companyQuery = useGetCompanyById(companyId as string, {
      enabled: true,
    });
    const companyDetail = companyQuery.data?.data.data;
    const navigateToTransaction = () => {
      const today = dayjs();
      const startDate = today.subtract(7, "day").format("YYYY-MM-DD");
      const endDate = today.format("YYYY-MM-DD");
      const status = "all";

      navigate({
        pathname: RoutePath[ROUTE_NAME.TRANSACTION],
        search: `?startDate=${startDate}&endDate=${endDate}&status=${status}`,
      });
    };
    return (
      <Box>
        <Box sx={{ backgroundColor: COLOR.primary500, pb: 2 }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            ml={2}
          >
            <ProfileAvatar
              imgProps={{
                src: companyDetail?.logoSignedURL || NeemaLogoPNG,
              }}
              name={companyDetail?.name || ""}
              url={companyDetail?.companyDomainName || ""}
              isLoading={companyQuery.isLoading}
            />
            {/* <OpacityButton
              sx={{
                pl: 1,
                py: 2,
                pr: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <GearIcon color="white" />
            </OpacityButton> */}
          </Box>
          <Wallet
            sx={{ marginBottom: "-100px", mx: 2 }}
            amount={0}
            onClickTransaction={() => {
              navigateToTransaction();
            }}
            onClickWithdraw={() => {}}
            onClickProduct={() => {
              navigate(RoutePath[ROUTE_NAME.PRODUCT]);
            }}
            onClickBusinessProfile={() => {
              navigate(
                `${RoutePath[ROUTE_NAME.EDIT_BUSINESS]}/${
                  companyId as string
                }/edit`
              );
            }}
          />
        </Box>

        <Box pt="100px" px={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            pt={1}
            mb={2}
          >
            <CustomButton
              onClick={() => navigate(RoutePath[ROUTE_NAME.PRODUCT])}
            >
              List Produk
            </CustomButton>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            pt={1}
            mb={2}
          >
            <Typography fontWeight={600} fontSize="18px">
              Transaksi Hari Ini
            </Typography>
            <OpacityButton
              onClick={() => navigate(RoutePath[ROUTE_NAME.TRANSACTION])}
            >
              <Typography
                fontWeight={400}
                variant="caption"
                color={COLOR.primary500}
              >
                Lihat Semua
              </Typography>
            </OpacityButton>
          </Box>
          <Box display="flex" flexDirection="column" gap={2} pb={5}>
            {!getTransactionListQueries.isLoading &&
              transactionList.length === 0 && (
                <Typography variant="caption">
                  Belum ada transaksi hari ini.
                </Typography>
              )}

            {getTransactionListQueries.isLoading &&
              new Array(10).fill("").map((_, idx) => (
                <Box
                  key={`skeleton-${idx}`}
                  sx={{
                    border: `1px solid ${COLOR.neutral200}`,
                    borderRadius: "12px",
                    width: "100%",
                    pb: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: `1px solid ${COLOR.neutral200}`,
                      p: 1,
                      gap: 2,
                    }}
                  >
                    <Skeleton width="30%" height="20px" />
                    <Skeleton width="40%" height="20px" />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    px={1}
                    gap={2}
                  >
                    <Box
                      flex={1}
                      textAlign="left"
                      display="flex"
                      flexDirection="column"
                    >
                      <Skeleton width="130px" height="100%" />
                      <Skeleton width="100px" height="100%" />
                      <Skeleton width="180px" height="100%" />
                    </Box>
                  </Box>
                </Box>
              ))}

            {!getTransactionListQueries.isLoading &&
              transactionList.length > 0 &&
              transactionList.map((transactionDetail) => (
                <TransactionCard
                  key={transactionDetail.transactionID}
                  onClick={() => {
                    navigate(
                      RoutePath[ROUTE_NAME.TRANSACTION] +
                        `/${transactionDetail.transactionID}`
                    );
                  }}
                  id={transactionDetail.transactionID}
                  createdAt={new Date(transactionDetail.createdAt)}
                  timezone={transactionDetail.timezone}
                  name={transactionDetail.productName}
                  qty={transactionDetail.totalItems}
                  status={transactionDetail.paymentStatus as PAYMENT_STATUS}
                  amount={transactionDetail.total}
                  currency={transactionDetail.currency as CurrencyCodeType}
                />
              ))}
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <MobileContainer>
      {isEmpty(userReducer.data.companyId)
        ? _renderNoBusinessComponent()
        : _renderBusinessByCompanyId()}
    </MobileContainer>
  );
};

export default Business;
