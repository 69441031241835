import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { COLOR } from "../../utils/color";

export const ContainerStyle: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  width: '100%',
  height: '100%',
  alignItems: 'center',
  p: 3
};

export const StatusContainer: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flexGrow: 1,
  justifyContent: "center",
  alignItems: "center",
};

export const StatusBorder: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  alignSelf: "center",
  width: "120px",
  height: "120px",
};

export const StatusCircle: SxProps<Theme> = {
  borderRadius: "50%",
  backgroundColor: COLOR.success100,
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "120px",
  height: "120px",
  alignSelf: "center",
};

export const ButtonContainer: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  py: 1,
};

