import { Box, Skeleton, Typography } from "@mui/material";
import { ImageRendererProps } from "../../../../components/ImageRenderer/ImageRenderer";
import OpacityButton from "../../../../components/OpacityButton/OpacityButton";
import { enqueueSnackbar } from "notistack";
import { COLOR } from "../../../../utils/color";
import { DEFAULT_SNACKBAR_PROPS, getBusinessPreviewUrl } from "../../../../utils/constant";
import AvatarImg from "../../../../components/AvatarImg/AvatarImg";
import { FaCopy } from "react-icons/fa";

interface ProfileAvatarPropsI {
  imgProps: ImageRendererProps;
  name: string;
  url: string;
  isLoading: boolean;
}
const ProfileAvatar = (props: ProfileAvatarPropsI) => {
  if (props.isLoading) {
    return (
      <Box width="100%" height="100%" display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap={2} py={2}>
        <Box sx={{
          borderRadius: '50%',
          overflow: 'hidden',
          width: '60px',
          height: '60px',
          border: `4px solid rgba(255,255,255,0.2)`,
        }} display="flex" justifyContent="center" alignItems="center">
          <Skeleton variant="circular" width={80} height={80} />
        </Box>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Skeleton height={30} />
          <Skeleton />
        </Box>
      </Box >
    )
  }
  const businessUrl = getBusinessPreviewUrl(props.url);
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      gap={2}
      py={2}
    >

      <AvatarImg
        imgProps={{
          src: 'https://placehold.co/200/#e2e8f0/FFF',
          style: { objectFit: 'cover' },
          ...props.imgProps,
        }}
        size={60}
        borderHexColor="#ffffff"
        borderAlphaColor={0.6}
      />
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        sx={{
          wordBreak: 'break-all'
        }}
      >
        <Typography fontWeight={600} fontSize={20} color={"white"}>
          {props.name}
        </Typography>
        <OpacityButton
          onClick={() => {
            navigator.clipboard.writeText(businessUrl);
            enqueueSnackbar({
              ...DEFAULT_SNACKBAR_PROPS,
              variant: 'success',
              message: `Link berhasil disalin: ${businessUrl}`,
            });
          }}
        >
          <Typography
            fontWeight={400}
            fontSize={14}
            color={COLOR.neutral200}
            textAlign="left"
          >
            {businessUrl} <FaCopy />
          </Typography>
        </OpacityButton>
      </Box>
    </Box>
  )
};

export default ProfileAvatar;