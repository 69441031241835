import React from 'react';
import { IconSVGProps } from '../../types/globalTypes';

const WarningIcon: React.FC<IconSVGProps> = ({ size = 20, color = "black", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.468 4.16592C11.3536 2.61136 13.6464 2.61136 14.532 4.16592L22.2041 17.6324C23.0649 19.1433 21.9445 21 20.1721 21H4.82789C3.05546 21 1.9351 19.1433 2.79587 17.6324L10.468 4.16592ZM13.4999 17C13.4999 17.5523 13.0522 18 12.4999 18C11.9477 18 11.4999 17.5523 11.4999 17C11.4999 16.4477 11.9477 16 12.4999 16C13.0522 16 13.4999 16.4477 13.4999 17ZM13.2499 9C13.2499 8.58579 12.9142 8.25 12.4999 8.25C12.0857 8.25 11.7499 8.58579 11.7499 9V14C11.7499 14.4142 12.0857 14.75 12.4999 14.75C12.9142 14.75 13.2499 14.4142 13.2499 14V9Z"
        fill={color}
      />
    </svg>
  );
};

export default WarningIcon;
