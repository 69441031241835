import { useDynamicPage } from "../../../context/DynamicPageContext";
import { Box, Skeleton, Typography } from "@mui/material";
import { ProductContainer, ProductImage } from "./Template1.styles";
import { formatCurrency } from "../../../utils/helper";
import { COLOR } from "../../../utils/color";
import {
  MAX_PAGE_WIDTH,
  ROUTE_NAME,
  RoutePath,
  UNIT_TEXT,
} from "../../../utils/constant";
import OpacityButton from "../../../components/OpacityButton/OpacityButton";
import { useNavigate } from "react-router-dom";
import AvatarImg from "../../../components/AvatarImg/AvatarImg";

const DHome = () => {
  const {
    // subdomain,
    data,
    loading
    // error,
  } = useDynamicPage();
  const navigate = useNavigate();

  if (loading) return (
    <Box
      sx={{
        maxWidth: MAX_PAGE_WIDTH,
        height: "100%",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        pt: 5,
      }}
    >
      <Skeleton variant="circular" width={80} height={80} />
      <Box px={2} py={2} width="100%">
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="100%" />
        <Box display="flex" flexDirection="row" gap={2} mt={2} height="60px">
          <Skeleton variant="rounded" width="100%" height="100%"/>
          <Skeleton variant="rounded" width="100%" height="100%"/>
        </Box>
      </Box>

      <Skeleton
        variant="rectangular"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          borderTopLeftRadius: "18px",
          borderTopRightRadius: "18px",
          width: "100%",
          height: '100%',
        }}
      />
    </Box>
  )
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        overflowY: "scroll",
      }}
    >
      <Box
        sx={{
          maxWidth: MAX_PAGE_WIDTH,
          backgroundColor: data.color,
          height: "100%",
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            px: 2,
            py: 5,
            color: "white",
          }}
        >
          <AvatarImg
            imgProps={{
              className: "img-default",
              src:
                data?.logoSignedURL || "https://placehold.co/200/#e2e8f0/FFF",
              alt: "product-image",
            }}
          />

          <Box width="80%" textAlign="center" my={3}>
            <Typography fontWeight={700} variant="h5">
              {data?.name}
            </Typography>
            <Typography
              mt={1}
              fontWeight={400}
              textAlign="center"
              variant="body1"
            >
              {data?.description}
            </Typography>
          </Box>
          <Box
            width="100%"
            borderTop="1px solid white"
            borderBottom="1px solid white"
            display="grid"
            gridTemplateColumns="4fr 1px 4fr"
            p={2}
            textAlign="center"
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <Typography fontSize={16}>{data.openingHours}</Typography>
              <Typography fontSize={14}>Jam Buka</Typography>
            </Box>
            <Box
              sx={{ width: "100%", height: "100%", backgroundColor: "white" }}
            />
            <Box>
              <Typography fontSize={16}>{data.products.length}</Typography>
              <Typography fontSize={14}>Produk</Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            borderTopLeftRadius: "18px",
            borderTopRightRadius: "18px",
            pt: 3,
            pb: 10,
          }}
        >
          <Typography px={2} fontWeight={600}>Semua Produk / Service</Typography>
          {data.products.map((productData, index) => (
            <OpacityButton
              sx={{
                ...ProductContainer,
                py: 2,
                px: 2,
                "&:hover": {
                  backgroundColor: COLOR.neutral50,
                },
              }}
              key={index}
              onClick={() => {
                navigate(
                  RoutePath[ROUTE_NAME.DYNAMIC_PRODUCT_DETAIL] +
                  `/${productData.productID}`
                );
              }}
            >
              <Box sx={ProductImage}>
                <img
                  alt="product-image"
                  src={productData.thumbnailPhotoSignedURL}
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", ml: 2 }}>
                <Typography fontWeight={400} variant="caption" color={COLOR.neutral500} sx={{ wordBreak: 'break-all' }}>
                  {productData.productID}
                </Typography>
                <Typography fontWeight={400}>
                  {productData.name}
                </Typography>
                <Typography fontWeight={600}>
                  {formatCurrency(productData.price, "IDR")}
                  <Typography
                    component="span"
                    color={COLOR.neutral500}
                    fontWeight={400}
                  >
                    {" "}
                    / {productData.duration}{" "}
                    {UNIT_TEXT[productData.durationPeriod]}
                  </Typography>
                </Typography>
              </Box>
            </OpacityButton>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default DHome;
