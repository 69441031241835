import { GEOLOCATION_PERMISSION, GeolocationPermissionResponse } from "../types/permission";

export const requestGeolocationPermission = (): Promise<GeolocationPermissionResponse> => {
  return new Promise((resolve, reject) => {
      const successCallback: PositionCallback = (
          position: GeolocationPosition
      ) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          resolve({
              status: GEOLOCATION_PERMISSION.GRANTED,
              position: {
                  latitude,
                  longitude,
              },
          });
      };
      const errorCallback: PositionErrorCallback = (
          positionError: GeolocationPositionError
      ) => {
          if (
              positionError.code ===
              GeolocationPositionError.PERMISSION_DENIED
          ) {
              resolve({ status: GEOLOCATION_PERMISSION.DENIED });
          } else if (
              positionError.code ===
              GeolocationPositionError.POSITION_UNAVAILABLE
          ) {
              resolve({
                  status: GEOLOCATION_PERMISSION.POSITION_UNAVAILABLE,
              });
          } else if (
              positionError.code === GeolocationPositionError.TIMEOUT
          ) {
              resolve({ status: GEOLOCATION_PERMISSION.TIMEOUT });
          } else {
              reject(positionError);
          }
      };
      navigator.geolocation.getCurrentPosition(
          successCallback,
          errorCallback
      );
  });
};