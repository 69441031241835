import React, { createContext, useContext, useState, ReactNode } from 'react';
import { DAY } from '../utils/date';
import { SchedulePriceType } from '../types/globalTypes';

export type ManageSchedulesType = Record<string, SchedulePriceType[]>;
interface ManageScheduleContextType {
  schedules: ManageSchedulesType,
  setSchedules: React.Dispatch<React.SetStateAction<ManageSchedulesType>>,
  handleSchedulesChange: (day: string) => (updatedSchedules: SchedulePriceType[]) => void,
}

const ManageScheduleContext = createContext<ManageScheduleContextType | undefined>(undefined);

export const ManageScheduleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [schedules, setSchedules] = useState<ManageSchedulesType>({
    [DAY.SUNDAY]: [],
    [DAY.MONDAY]: [],
    [DAY.TUESDAY]: [],
    [DAY.THURSDAY]: [],
    [DAY.FRIDAY]: [],
    [DAY.SATURDAY]: [],
  });

  const handleSchedulesChange = (day: string) => (updatedSchedules: SchedulePriceType[]) => {
    setSchedules(prevSchedule => ({
      ...prevSchedule,
      [day]: updatedSchedules,
    }))
  };

  return (
    <ManageScheduleContext.Provider value={{
      schedules,
      setSchedules,
      handleSchedulesChange,
    }}>
      {children}
    </ManageScheduleContext.Provider>
  );
};

export const useManageSchedule = () => {
  const context = useContext(ManageScheduleContext);
  if (context === undefined) {
    throw new Error('useManageSchedule must be used within a ManageScheduleProvider');
  }
  return context;
};
