import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './ImageCarousel.css'; // Import the local CSS file

interface ImageCarouselProps {
  images: string[];
  aspectRatio?: string;
  resizeMode?: string;
  customStyles?: React.CSSProperties;
  bulletColor?: string;
  activeBulletColor?: string;
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({
  images,
  aspectRatio = '16/9',
  resizeMode = 'cover',
  customStyles = {},
  bulletColor = '#000', // Default bullet color
  activeBulletColor = '#fff', // Default active bullet color
}) => {
  const [widthRatio, heightRatio] = aspectRatio.split('/').map(Number);
  const aspectRatioPercentage = (heightRatio / widthRatio) * 100;

  const containerStyle: React.CSSProperties = {
    position: 'relative',
    width: '100%',
    paddingBottom: `${aspectRatioPercentage}%`,
    overflow: 'hidden',
    ...customStyles,
  };

  const imageStyle: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: resizeMode as any,
  };

  return (
    <Swiper
      pagination={{
        clickable: true,
        renderBullet: (index: number, className: string) => (
          `<span class="${className}" style="background-color: ${bulletColor}; height:8px; width:20px; border-radius: 8px;"></span>`
        ),
      }}
      modules={[Pagination]}
      className="mySwiper"
      style={{ '--active-bullet-color': activeBulletColor } as React.CSSProperties}
    >
      {images.map((src, index) => (
        <SwiperSlide key={index}>
          <div style={containerStyle}>
            <img src={src} alt={`Slide ${index + 1}`} style={imageStyle} />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ImageCarousel;
