import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { RootReducerState } from '../../redux/reducers';
import { closeDialog } from '../../redux/reducers/confirmationDialog';
import CustomButton from '../CustomButton/CustomButton';

const ConfirmationDialog: React.FC = () => {
  const dispatch = useDispatch();
  const { isOpen, message, title, primaryBtn, secondaryBtn } = useSelector((state: RootReducerState) => state.confirmationDialog);
  
  const handleClose = () => {
    dispatch(closeDialog());
    primaryBtn?.onClick?.();
  };
  
  const handleConfirm = () => {
    dispatch(closeDialog());
    secondaryBtn?.onClick?.();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton variant='text' onClick={handleClose} color="primary">
          {primaryBtn?.text}
        </CustomButton>
        <CustomButton onClick={handleConfirm} color="primary" autoFocus>
          {secondaryBtn?.text}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
