import { useState, useEffect, RefObject } from 'react';

/**
 * Custom hook to get the height of a referenced element.
 * @param ref - The ref of the element to measure.
 * @returns The height of the element.
 */
export function useElementHeight(ref: RefObject<HTMLElement>): number {
    const [height, setHeight] = useState<number>(0);

    useEffect(() => {
        const handleResize = () => {
            if (ref.current) {
                setHeight(ref.current.clientHeight);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [ref]);

    return height;
}
