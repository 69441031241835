import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type BtnType = {
  text?: string;
  onClick?: () => void;
}
export interface ConfirmationDialogState {
  isOpen: boolean;
  message?: string;
  title?: string;
  primaryBtn?: BtnType;
  secondaryBtn?: BtnType;
}

const initialState: ConfirmationDialogState = {
  isOpen: false,
  title: '',
  message: '',
  primaryBtn: {
    text: 'Cancel',
    onClick: undefined,
  },
  secondaryBtn: {
    text: 'Ok',
    onClick: undefined,
  },
};

export type OpenDialogPayloadType = Partial<Omit<ConfirmationDialogState, 'isOpen'>>;

const confirmationDialogSlice = createSlice({
  name: 'confirmationDialog',
  initialState,
  reducers: {
    openDialog: (state, action: PayloadAction<OpenDialogPayloadType>) => {
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        primaryBtn: {
          ...state.primaryBtn,
          ...action.payload?.primaryBtn,
        },
        secondaryBtn: {
          ...state.secondaryBtn,
          ...action.payload?.secondaryBtn,
        }
      };
    },
    closeDialog: (state) => {
      return { ...state, isOpen: false };
    },
  },
});

export const { openDialog, closeDialog } = confirmationDialogSlice.actions;
export default confirmationDialogSlice.reducer;
