import React from 'react';
import { IconSVGProps } from '../../types/globalTypes';

const StoreIcon: React.FC<IconSVGProps> = ({
  size = 18,
  color = "#3B82F6",
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.02598 0.666504H12.9739C14.4883 0.666504 15.8502 1.7038 16.4127 3.28571L17.0443 5.06223C17.2355 5.6 17.3677 6.17545 17.2282 6.7289C16.898 8.03963 15.8264 8.99984 14.5555 8.99984C13.0214 8.99984 11.7777 7.60073 11.7777 5.87484C11.7777 7.60073 10.5341 8.99984 8.99993 8.99984C7.4658 8.99984 6.22215 7.60073 6.22215 5.87484C6.22215 7.60073 4.9785 8.99984 3.44437 8.99984C2.17346 8.99984 1.10189 8.03963 0.771607 6.7289C0.632146 6.17545 0.764315 5.6 0.955521 5.06223L1.58717 3.28571C2.14963 1.7038 3.51151 0.666504 5.02598 0.666504ZM2.33329 10.0836V13.9998C2.33329 15.8407 3.82568 17.3331 5.66663 17.3331H12.3333C14.1742 17.3331 15.6666 15.8407 15.6666 13.9998V10.0836C15.3176 10.1913 14.9456 10.2498 14.5556 10.2498C13.4413 10.2498 12.481 9.78089 11.7778 9.04643C11.0746 9.78089 10.1143 10.2498 9.00002 10.2498C7.88575 10.2498 6.92542 9.78089 6.22224 9.04643C5.51906 9.78089 4.55873 10.2498 3.44446 10.2498C3.05436 10.2498 2.68235 10.1913 2.33329 10.0836ZM12.0443 12.8594C12.2138 13.1601 12.1075 13.5413 11.8068 13.7108C10.7942 14.2817 9.92196 14.6229 9.00223 14.6247C8.08138 14.6264 7.20745 14.2879 6.19082 13.7096C5.8908 13.5389 5.78594 13.1573 5.95662 12.8573C6.12729 12.5573 6.50888 12.4524 6.80891 12.6231C7.75574 13.1617 8.40163 13.3758 8.99982 13.3747C9.59914 13.3735 10.2452 13.1562 11.1929 12.6219C11.4936 12.4524 11.8748 12.5587 12.0443 12.8594Z"
        fill={color}
      />
    </svg>
  );
};

export default StoreIcon;
