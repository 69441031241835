import { forwardRef, useCallback, useState } from 'react';
import { BottomSheet, BottomSheetProps, BottomSheetRef } from 'react-spring-bottom-sheet';
import CustomButton from '../CustomButton/CustomButton';
import { Box, Checkbox, Typography } from '@mui/material';
import TextInput from '../Forms/TextInput/TextInput';
import OpacityButton from '../OpacityButton/OpacityButton';
import { COLOR } from '../../utils/color';
import MapIframe from '../MapIframe/MapIframe';
import { getEmbedMapSrc } from '../../utils/maps';
import { LocationData } from '../../pages/Business/LocationBusiness/LocationBusiness';
import { formatCoordinate, isValidNumber } from '../../utils/helper';
import SearchIcon from '../../assets/svg/SearchIcon';
import TargetIcon from '../../assets/svg/TargetIcon';
import { GEOLOCATION_PERMISSION } from '../../types/permission';
import { requestGeolocationPermission } from '../../utils/permission';
import { errorLogger } from '../../utils/logger';
import { ElipsisText } from '../../styles/global.styles';
import LocationIcon from '../../assets/svg/LocationIcon';
import { BpCheckedIcon, BpIcon } from '../CheckboxIcon/CheckboxIcon';
import { APICompanyLocation } from '../../api/request.types';
import isEmpty from 'lodash.isempty';

interface LocationBottomSheetProps {
  bottomSheetProps?: Omit<BottomSheetProps, 'children' | 'open' | 'ref'>;
  open: boolean;
  onClickSave?: (locationData: LocationData) => void;
  isLoading?: boolean;
  businessLocation: APICompanyLocation;
};

export const DEFAULT_LOCATION_DATA: LocationData = {
  lat: null,
  long: null,
  search: "",
  lastChanged: "none",
  isCustomLocation: false,
  error: "",
};

const LocationBottomSheet = forwardRef<BottomSheetRef, LocationBottomSheetProps>(
  ({ bottomSheetProps, isLoading = false, businessLocation, ...props }, ref) => {
    const [locationData, setLocationData] = useState<LocationData>({
      ...DEFAULT_LOCATION_DATA,
      search: businessLocation.mapLocation || DEFAULT_LOCATION_DATA.search,
    })
    const [requestLoading, setRequestLoading] = useState<boolean>(false);
    const [geolocationPermission, setGeolocationPermission] =
      useState<GEOLOCATION_PERMISSION>(GEOLOCATION_PERMISSION.INITIAL);
    const [userLocation, setUserLocation] = useState<{ lat: number | null, long: number | null }>({
      lat: null,
      long: null
    });

    const handleChangeLocationData = useCallback(
      (
        data: Partial<Omit<LocationData, "lastChanged">>,
        lastChanged?: LocationData["lastChanged"]
      ) => {
        setLocationData((prev) => ({
          ...prev,
          ...data,
          ...(lastChanged ? { lastChanged } : {}),
        }));
      },
      [setLocationData]
    );
    const _textByLocationPermission = (permission: GEOLOCATION_PERMISSION) => {
      if (requestLoading) return 'Sedang mencari...';
      switch (permission) {
        case GEOLOCATION_PERMISSION.GRANTED:
          return [userLocation.lat, userLocation.long].join(", ");
        case GEOLOCATION_PERMISSION.INITIAL:
          return "Tekan untuk mendapatkan lokasi saat ini";
        case GEOLOCATION_PERMISSION.DENIED:
        default:
          return "Mohon berikan akses ke lokasi";
      }
    };

    const getGeoLocation = async () => {
      if (userLocation.lat && userLocation.long) {
        handleChangeLocationData(
          {
            lat: userLocation.lat.toString(),
            long: userLocation.long.toString(),
            isCustomLocation: true,
          },
          "latlong"
        );
        return;
      }
      try {
        setRequestLoading(true);
        const { status, position } = await requestGeolocationPermission();
        setGeolocationPermission(status);
        const lat = position?.latitude ?? 0;
        const long = position?.longitude ?? 0;
        if (
          status === GEOLOCATION_PERMISSION.GRANTED
        ) {
          setUserLocation({ lat, long });
          handleChangeLocationData(
            {
              lat: lat.toString(),
              long: long.toString(),
              isCustomLocation: true,
            },
            "latlong"
          );
        }
      } catch (error) {
        errorLogger(error);
      } finally {
        setRequestLoading(false);
      }
    };
    return (
      <BottomSheet
        ref={ref}
        open={props.open}
        {...bottomSheetProps}
        style={{
          height: '100vh',
          maxHeight: '100vh',
          top: 0,
          bottom: 0,
          ...bottomSheetProps?.style,
        }}
      >
        <Box display="flex" flexDirection="column" width="100%" height="100%">
          <MapIframe
            src={getEmbedMapSrc(
              locationData.lastChanged === "latlong"
                ? [locationData.lat, locationData.long].join(",")
                : locationData.search
            )}
            style={{ width: "100%", height: "70%", pointerEvents: "none" }}
          />
          <Box px={2} pb={5}>

            <Box sx={{ mt: 1, position: "relative" }}>
              <TextInput
                startEndorment={<SearchIcon />}
                textInputProps={{
                  placeholder: "Cari lokasi mu",
                  value: locationData.search,
                  onChange: (e) =>
                    handleChangeLocationData(
                      {
                        search: e.target.value,
                        isCustomLocation: false,
                      },
                      "search"
                    ),
                }}
                disabled={isLoading || requestLoading}
              />
              <CustomButton
                fullWidth
                startEndorment={<TargetIcon />}
                disabled={isLoading || requestLoading}
                sx={{ mt: 2, px: 3 }}
                onClick={() => {
                  getGeoLocation();
                }}
              >
                <Box sx={{ width: "100%", textAlign: "left" }}>
                  <Typography fontWeight={600}>
                    Gunakan Lokasi anda saat ini
                  </Typography>
                  <Typography
                    fontWeight={400}
                    variant="caption"
                    style={ElipsisText(1)}
                  >
                    {_textByLocationPermission(geolocationPermission)}
                  </Typography>
                </Box>
              </CustomButton>

              <CustomButton
                fullWidth
                startEndorment={<LocationIcon />}
                disabled={isLoading || requestLoading}
                sx={{
                  mt: 2,
                  px: 3,
                  borderColor: COLOR.neutral300,
                  backgroundColor: COLOR.neutral50,
                  color: "black",
                  "&:hover": {
                    filter: "brightness(98%)",
                  },
                  "&:active": {
                    filter: "brightness(95%)",
                  },
                }}
                onClick={() => {
                  handleChangeLocationData(
                    {
                      lat: businessLocation.latitude.toString(),
                      long: businessLocation.longitude.toString(),
                      isCustomLocation: true,
                    },
                    "latlong"
                  );
                }}
              >
                <Box sx={{ width: "100%", textAlign: "left" }}>
                  <Typography fontWeight={600}>Gunakan Lokasi Usaha</Typography>
                  <Typography color={COLOR.neutral500} variant="caption">
                    {Object.values([businessLocation.latitude, businessLocation.longitude]).join(", ")}
                  </Typography>
                </Box>
              </CustomButton>
              <OpacityButton
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  py: 2,
                }}
                disabled={isLoading || requestLoading}
                disableOpacity={true}
                onClick={() => {
                  const isCustom = !locationData.isCustomLocation;
                  if (isCustom) {
                    handleChangeLocationData({
                      isCustomLocation: isCustom,
                      lat: null,
                      long: null,
                      search: '',
                    }, 'none');
                  } else {
                    handleChangeLocationData({
                      isCustomLocation: isCustom,
                      search: isEmpty(locationData.search) ? businessLocation.mapLocation : locationData.search,
                    }, 'search');
                  }
                }}
              >
                <Checkbox
                  inputProps={{
                    "aria-label": "Custom Location",
                  }}
                  checked={locationData.isCustomLocation}
                  disabled={isLoading || requestLoading}
                  disableRipple
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                  sx={{
                    p: 0,
                    mr: 1,
                    pointerEvents: "none",
                  }}
                />
                <Typography>Atur lokasi secara manual</Typography>
              </OpacityButton>
              {locationData.isCustomLocation && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                    pb: 4,
                  }}
                >
                  <TextInput
                    title="Lat"
                    required
                    disabled={isLoading || requestLoading}
                    sx={{ flex: 1 }}
                    textInputProps={{
                      inputMode: 'numeric',
                      value: formatCoordinate(locationData.lat ?? ""),
                      onChange: (e) =>
                        handleChangeLocationData({
                          lat: e.target.value,
                        }, 'latlong'),
                    }}
                    error={!isValidNumber(locationData.lat ?? "")}
                  />
                  <TextInput
                    title="Long"
                    required
                    sx={{ flex: 1 }}
                    textInputProps={{
                      inputMode: 'numeric',
                      value: formatCoordinate(locationData.long ?? ""),
                      onChange: (e) =>
                        handleChangeLocationData({
                          long: e.target.value,
                        }, 'latlong'),
                    }}
                    error={!isValidNumber(locationData.long ?? "")}
                  />
                </Box>
              )}
            </Box>

            <CustomButton
              fullWidth
              disabled={
                locationData.lastChanged === "none" ||
                !isValidNumber(locationData.lat ?? "") ||
                !isValidNumber(locationData.long ?? "") ||
                isLoading || requestLoading
              }
              onClick={() => {
                props.onClickSave?.(locationData)
              }}
            >
              <Typography variant="body1" fontWeight={500}>
                simpan
              </Typography>
            </CustomButton>
          </Box>
        </Box>
      </BottomSheet>
    );
  }
);

export default LocationBottomSheet;
