import React, { useMemo } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import MobileContainer from '../../components/Layout/MobileContainer/MobileContainer';
import { COLOR } from '../../utils/color';
import { FaExchangeAlt, FaWallet } from 'react-icons/fa';
import { formatCurrency } from '../../utils/helper';
import { BarChart } from '@mui/x-charts/BarChart';
import { DAY } from '../../utils/date';
import { PieChart } from '@mui/x-charts';
import { useGetProductCompactList } from '../../query/queries';
import { APIGetProductCompact } from '../../api/request.types';
import { RootReducerState } from '../../redux/reducers';
import { useSelector } from 'react-redux';

const Home: React.FC = () => {
  const userReducer = useSelector((state: RootReducerState) => state.userReducer);
  const productCompactListQuery = useGetProductCompactList(userReducer.data.companyId || "", {
    refetchOnMount: true,
  });
  const productList: APIGetProductCompact[] = useMemo(() => productCompactListQuery.data?.data?.data || [], [productCompactListQuery.data]);

  const dataset = Object.values(DAY).map((dayStr, index) => ({
    day: dayStr.substring(0, 3),
    transactionCount: 1 + index,
  }));

  const datasetPie = [
    { label: "Berhasil (5)", value: 5, color: COLOR.success500 },
    { label: "Gagal (10)", value: 10, color: COLOR.danger500 },
  ];

  const _renderLoading = () => (
    Array.from({ length: 10 }).map((_, idx) => (
      <Skeleton key={idx} height="68px" sx={{ transform: 'none' }} />
    ))
  );

  const _renderProductList = () => (
    productList.map(product => (
      <Box
        key={product.productID}
        display="flex"
        flexDirection="row"
        border={`1px solid ${COLOR.neutral300}`}
        justifyContent="space-between"
        alignItems="center"
        borderRadius={2}
        p={1}
      >
        <Box display="flex" flexDirection="column">
          <Typography fontWeight={500} color={COLOR.neutral500} variant="caption">
            ID: {product.productID}
          </Typography>
          <Typography fontWeight={600}>{product.productName}</Typography>
        </Box>
      </Box>
    ))
  );

  return (
    <MobileContainer>
      <Box display="flex" flexDirection="column" height="100%" px={2} py={5}>
        <Box display="flex" flexDirection="row" gap={1}>
          <Box p={1} border={`1px solid ${COLOR.neutral200}`} borderRadius={2} flex={1}>
            <Typography
              fontWeight={500}
              fontSize={12}
              color={COLOR.neutral500}
              display="flex"
              flexDirection="row"
              justifyContent="start"
              alignItems="center"
              gap={2}
            >
              Tingkat Konversi <FaExchangeAlt />
            </Typography>
            <Typography fontWeight={700} fontSize={16}>0%</Typography>
          </Box>
          <Box p={1} border={`1px solid ${COLOR.neutral200}`} borderRadius={2} flex={1}>
            <Typography
              fontWeight={500}
              fontSize={12}
              color={COLOR.neutral500}
              display="flex"
              flexDirection="row"
              justifyContent="start"
              alignItems="center"
              gap={2}
            >
              Total Penghasilan <FaWallet />
            </Typography>
            <Typography fontWeight={700} fontSize={16}>{formatCurrency(10000, 'IDR')}</Typography>
          </Box>
        </Box>

        <Box
          mt={3}
          py={1}
          width="100%"
          border={`1px solid ${COLOR.neutral200}`}
          borderRadius={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography my={2} fontWeight={600} textAlign="center">Jumlah Transaksi PerMinggu</Typography>
          <BarChart
            sx={{ width: '100%' , '.MuiBarLabel-root': {
              fill:'white'
            }}}
            height={300}
            dataset={dataset}
            xAxis={[{ scaleType: 'band', dataKey: 'day' }]}
            borderRadius={10}
            series={[
              {
                dataKey: 'transactionCount',
                color: COLOR.primary500,
              },
            ]}
            barLabel="value"
          />
        </Box>

        <Box
          width="100%"
          mt={3}
          py={1}
          border={`1px solid ${COLOR.neutral200}`}
          borderRadius={2}
          display="flex"
          flexDirection='column'
          justifyContent="center"
          px={2}
        >
          <Typography my={2} fontWeight={600} textAlign="center">Jumlah Transaksi PerMinggu</Typography>
          <PieChart
            series={[
              {
                data: datasetPie,
                highlightScope: { fade: 'global', highlight: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray', },
                innerRadius: 45
              },
            ]}
            slotProps={{
              legend: {
                itemMarkHeight: 10,
                itemMarkWidth: 10,
                labelStyle: {
                  fontSize: 14,
                },

              },
            }}
            height={200}
          />
        </Box>

        <Typography fontWeight={700} mt={3}>Produk Terlaris</Typography>
        <Box display="flex" flexDirection="column" gap={1} mt={1}>
          {productCompactListQuery.isLoading ? _renderLoading() : _renderProductList()}
        </Box>
      </Box>
    </MobileContainer>
  );
};

export default Home;
