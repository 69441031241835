import React, { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { LuPencil } from "react-icons/lu";
import { COLOR } from "../../../utils/color";
import SubMenuHeader from "../../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft } from "react-icons/fa";
import OpacityButton from "../../../components/OpacityButton/OpacityButton";
import { PiLinkSimple } from "react-icons/pi";
import {
  BusinessImageContainerStyle,
  BusinessImageEditStyle,
  ContainerCss,
  ContainerStyle,
  ImageButtonStyle,
} from "./CreateBusiness.styles";
import {
  FieldContainer,
  FormContainerStyle,
  TextButtonSx,
} from "../../../styles/global.styles";
import BoardIcon from "../../../assets/svg/BoardIcon";
import TextInput from "../../../components/Forms/TextInput/TextInput";
import TextArea from "../../../components/Forms/TextArea/TextArea";
import AppContainer from "../../../components/Layout/AppContainer/AppContainer";
import CustomButton from "../../../components/CustomButton/CustomButton";
import UserIcon from "../../../assets/svg/UserIcon";
import {
  NEEMA_SUFFIX_URL,
  ROUTE_NAME,
  RoutePath,
  STORAGE_KEY,
  TIMEZONE_LABEL,
  TIMEZONES,
} from "../../../utils/constant";
import { useNavigate, useParams } from "react-router-dom";
import LocationIcon from "../../../assets/svg/LocationIcon";
import useLocalStorage from "../../../hooks/useLocalStorage";
import HiddenInput from "../../../components/HiddenInput/HiddenInput";
import { FooterContainer } from "../AddProduct/AddProduct.styles";
import InputTitle from "../../../components/Forms/InputTitle/InputTitle";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "../../../redux/reducers/confirmationDialog";
import { useLocationContext } from "../../../context/LocationPageContext";
import { fileToBase64, selectedLocationStr } from "../../../utils/helper";
import SingleSelect from "../../../components/Forms/SingleSelect/SingleSelect";
import { businessDataSchema } from "../../../joiSchema/business";
import Joi, { ValidationErrorItem } from "joi";
import { RootReducerState } from "../../../redux/reducers";
import {
  BusinessDataType,
  resetBusinessData,
  setBusinessData,
} from "../../../redux/reducers/business";
import ImageRenderer from "../../../components/ImageRenderer/ImageRenderer";
import { useSnackbar } from "notistack";

const DEFAULT_ERROR_MESSAGE = {
  url: undefined,
  name: undefined,
  description: undefined,
  location: undefined,
  locationDetail: undefined,
  timezone: undefined,
  image: undefined,
};

const CreateBusiness: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: businessData } = useSelector(
    (state: RootReducerState) => state.businessReducer
  );
  const [errors, setErrors] = useLocalStorage<
    Record<
      keyof Omit<BusinessDataType, "templateId" | "color">,
      undefined | string
    >
  >(STORAGE_KEY.BUSINESS_DATA_ERROR, DEFAULT_ERROR_MESSAGE);
  const { savedLocation } = useLocationContext();
  const { enqueueSnackbar } = useSnackbar();

  const fileRef = useRef<HTMLInputElement | null>(null);
  const [imagePreview, setImagePreview] = useLocalStorage<string | undefined>(
    STORAGE_KEY.CREATE_BUSINESS_IMAGE_PREVIEW,
    ""
  );

  const clearAllState = () => {
    dispatch(resetBusinessData());
    setErrors(DEFAULT_ERROR_MESSAGE);
    setImagePreview("");
  };
  const handleOnChange =
    (key: keyof BusinessDataType) =>
    async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (
        key === "image" &&
        e.target instanceof HTMLInputElement &&
        e.target.files
      ) {
        const file = e.target.files[0];
        if (file) {
          try {
            const base64Image = await fileToBase64(file);
            dispatch(
              setBusinessData({
                [key]: base64Image,
              })
            );
          } catch (error) {
            enqueueSnackbar("Error Preview Image", {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              autoHideDuration: 5000,
            });
          }
        }
      } else {
        dispatch(
          setBusinessData({
            [key]: e.target.value,
          })
        );
      }
    };

  useEffect(() => {
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  const previousLocationDataRef = useRef(businessData.location);
  useEffect(() => {
    const dataToBeSaved =
      savedLocation.lastChanged === "latlong"
        ? {
            lat: Number(savedLocation.lat),
            long: Number(savedLocation.long),
            search: "",
            useManualLocation: savedLocation.isCustomLocation,
          }
        : {
            search: savedLocation.search,
            lat: 0,
            long: 0,
            useManualLocation: savedLocation.isCustomLocation,
          };
    const isChanged =
      JSON.stringify(dataToBeSaved) !==
      JSON.stringify(previousLocationDataRef.current);
    if (isChanged) {
      dispatch(
        setBusinessData({
          location: {
            ...dataToBeSaved,
          },
        })
      );
      previousLocationDataRef.current = { ...dataToBeSaved };
    }
  }, [dispatch, savedLocation]);

  const validateError = () => {
    const { error } = businessDataSchema.validate(businessData, {
      abortEarly: false,
    });
    console.log(error, "CREATE");
    console.log(businessData);
    if (error) {
      const errors = error.details.reduce(
        (acc: any, err: ValidationErrorItem) => {
          return { ...acc, [err.path[0]]: err.message };
        },
        {}
      );
      setErrors((prevErrors) => ({ ...prevErrors, ...errors }));
      return true;
    }
    return false;
  };
  const handleBlur = (field: keyof BusinessDataType) => {
    const { error } = Joi.object({
      [field]: businessDataSchema.extract(field),
    }).validate({ [field]: businessData[field] });
    if (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: error.message,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };

  const handleOnPressContinue = () => {
    const hasError = validateError();
    if (!hasError) {
      navigate(RoutePath[ROUTE_NAME.PAGE_TEMPLATE]);
    }
  };

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement> | React.FormEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    handleOnPressContinue();
  };

  const selectedLocation = selectedLocationStr({
    search: businessData.location.search,
    lat: businessData.location.lat,
    long: businessData.location.long,
  });
  const timezoneOptions = TIMEZONES.map((tz) => ({
    label: TIMEZONE_LABEL[tz] || "unknown",
    value: tz,
  }));
  return (
    <AppContainer
      bottomChildren={
        <Box sx={FooterContainer}>
          <CustomButton fullWidth onClick={handleOnPressContinue}>
            <Typography variant="body1" fontWeight={500}>
              Lanjutkan
            </Typography>
          </CustomButton>
        </Box>
      }
    >
      <Box sx={ContainerStyle}>
        <SubMenuHeader
          leftNav={{
            icon: <FaArrowLeft />,
            onClick: () => {
              dispatch(
                openDialog({
                  title: "Konfirmasi",
                  message:
                    "Anda yakin ingin kembali? anda harus mengisi ulang data anda",
                  secondaryBtn: {
                    onClick: () => {
                      clearAllState();
                      navigate(-1);
                    },
                  },
                })
              );
            },
          }}
          text={"Lengkapi Data Usaha"}
        />
        <OpacityButton
          sx={ImageButtonStyle}
          onClick={() => {
            if (fileRef.current) {
              fileRef.current.click();
            }
          }}
        >
          <HiddenInput
            ref={fileRef}
            onChange={handleOnChange("image")}
            hideMethod="none"
          />
          <Box sx={BusinessImageContainerStyle}>
            {businessData.image ? (
              <ImageRenderer
                className="img-default"
                style={{ objectFit: "cover" }}
                alt="business-logo"
                src={businessData.image}
              />
            ) : (
              <UserIcon />
            )}
          </Box>
          <Box sx={BusinessImageEditStyle}>
            <LuPencil size={16} color="white" />
          </Box>
        </OpacityButton>
        <form style={ContainerCss} onSubmit={handleSubmit}>
          <Box sx={FormContainerStyle}>
            <Box sx={FieldContainer}>
              <TextInput
                title="URL Usaha"
                required={true}
                textInputProps={{
                  placeholder: "bisnisku",
                  value: businessData.url,
                  style: { textTransform: "lowercase" },
                  onChange: handleOnChange("url"),
                  onBlur: () => handleBlur("url"),
                }}
                startEndorment={
                  <PiLinkSimple size={20} color={COLOR.neutral500} />
                }
                endEndorment={
                  <Typography color={COLOR.neutral500} mx={2}>
                    {NEEMA_SUFFIX_URL}
                  </Typography>
                }
                error={errors.url !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.url,
                }}
              />
            </Box>

            <Box sx={FieldContainer}>
              <TextInput
                title="Nama Usaha"
                required
                startEndorment={
                  <BoardIcon size={20} color={COLOR.neutral500} />
                }
                textInputProps={{
                  placeholder: "Bisnisku",
                  value: businessData.name,
                  onChange: handleOnChange("name"),
                  onBlur: () => handleBlur("name"),
                }}
                error={errors.name !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.name,
                }}
              />
            </Box>

            <Box sx={FieldContainer}>
              <TextArea
                title="Deskripsi Singkat"
                required
                textAreaProps={{
                  value: businessData.description,
                  onChange: handleOnChange("description"),
                  placeholder:
                    "Deskripsikan bisnis anda yang anda ingin perlihatkan di website anda.",
                  onBlur: () => handleBlur("description"),
                }}
                error={errors.description !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.description,
                }}
              />
            </Box>
            <Box sx={FieldContainer}>
              <SingleSelect
                title="Zona Waktu"
                required
                error={errors.timezone !== undefined}
                helper={{
                  text: errors.timezone,
                }}
                disabled
                optionProps={{
                  value: businessData.timezone,
                  onChange: (selectedOption?: string) => {
                    dispatch(
                      setBusinessData({
                        timezone: selectedOption || "",
                      })
                    );
                  },
                  options: timezoneOptions,
                  defaultValue: timezoneOptions[0].value,
                  placeholder: "Pilih Zona Waktu",
                }}
              />
            </Box>
            <Box sx={FieldContainer}>
              <InputTitle title="Pilih Lokasi" />
              <CustomButton
                variant="outlined"
                sx={TextButtonSx}
                endormentSx={{
                  start: { mr: 1 },
                }}
                startEndorment={<LocationIcon />}
                onClick={() => {
                  navigate(RoutePath[ROUTE_NAME.LOCATION_BUSINESS]);
                }}
              >
                <Typography
                  component="span"
                  fontWeight="inherit"
                  fontSize="inherit"
                  color={selectedLocation ? "black" : COLOR.neutral500}
                >
                  {selectedLocation || "Belum Pilih Lokasi"}
                </Typography>
              </CustomButton>
            </Box>
            <Box sx={FieldContainer}>
              <TextArea
                title="Alamat Lengkap"
                required={false}
                textAreaProps={{
                  value: businessData.locationDetail,
                  onChange: handleOnChange("locationDetail"),
                  placeholder: "Tulis alamat lengkap disini",
                }}
                error={errors.locationDetail !== undefined}
                helper={{
                  text: errors.locationDetail,
                }}
              />
            </Box>
          </Box>
          <HiddenInput
            type="submit"
            accept={"*"}
            hideMethod="none"
            onSubmit={handleSubmit}
          />
        </form>
      </Box>
    </AppContainer>
  );
};

export default CreateBusiness;
