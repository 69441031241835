export enum CAMERA_PERMISSION {
  GRANTED = 'granted',
  DENIED = 'denied',
  DISMISSED = 'dismissed',
  INITIAL = 'initial',
  UNKNOWN = 'unknown',
};

export enum GEOLOCATION_PERMISSION {
  DENIED = 'denied',
  POSITION_UNAVAILABLE = 'position_unavailable',
  TIMEOUT = 'timeout',
  GRANTED = 'granted',
  INITIAL = 'initial',
}

export type GeolocationPermissionResponse = {
  status: GEOLOCATION_PERMISSION;
  position?: Pick<GeolocationCoordinates, "longitude" | "latitude">;
}

export type CoordinateType = string | number;