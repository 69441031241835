import { Theme } from '@emotion/react';
import { Box, SxProps } from '@mui/material';
import React from 'react';
import { hexToRgba } from '../../utils/helper';
import { COLOR } from '../../utils/color';

interface AvatarImgPropsI {
  imgProps: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
  sx?: SxProps<Theme>;
  borderHexColor?: string;
  borderAlphaColor?: number;
  size?: number;
}
const AvatarImg = (props: AvatarImgPropsI) => {
  const { size = 80 } = props;
  const { style, ...otherImgProps } = props.imgProps;
  const getBorderSize = (size: number) => {
    if (size > 80) return 6;
    if (size <= 80 || size >= 40) return 5;
    return 2;
  }
  return (
    <Box sx={{
      backgroundColor: 'white',
      width: `${size}px`,
      height: `${size}px`,
      overflow: 'hidden',
      borderRadius: '50%',
      border: `${getBorderSize(size)}px solid ${hexToRgba(props.borderHexColor || COLOR.neutral500, props.borderAlphaColor || 0.2)}`,
      ...(props.sx || {})
    }}>
      <img
        alt='avatar-img'
        style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          ...style,
        }}
        {...otherImgProps}
      />
    </Box>
  )
};
export default AvatarImg;