import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useEffect } from 'react';
import AvatarImg from '../AvatarImg/AvatarImg';


export type DialogItemList = {
  value: string | number;
  primaryText: string;
  src?: string;
};

export interface SimpleDialogProps {
  open: boolean;
  title?: string;
  selectedValue?: DialogItemList['value'] | null;
  list: Array<DialogItemList>;
  onClose: (value: SimpleDialogProps['selectedValue']) => void;
  onOpen?: () => void;
}

const BusinessDialog = (props: SimpleDialogProps) => {
  const { onClose, selectedValue, open, title, list, onOpen } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: DialogItemList['value']) => {
    onClose(value);
  };

  useEffect(() => {
    if (open && onOpen) {
      onOpen();
    }
  }, [onOpen, open])
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <List sx={{ pt: 0 }}>
        {list.map(({ primaryText, value, src }) => (
          <ListItem disableGutters key={value}>
            <ListItemButton onClick={() => handleListItemClick(value)} sx={{ wordBreak: 'break-all' }}>
              <AvatarImg imgProps={{ src, alt: primaryText }} size={80} sx={{ mr: 2 }} />
              <ListItemText primary={primaryText} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};
export default BusinessDialog;