import { Box, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import TimeRangePicker from '../Forms/TimeRangePicker/TimeRangePicker';
import CustomButton from '../CustomButton/CustomButton';
import { GoPlusCircle } from 'react-icons/go';
import { COLOR } from '../../utils/color';
import NumberInput from '../Forms/NumberInput/NumberInput';
import { formatNumber, generateDisabledTimeIntervals } from '../../utils/helper';
import { TextButtonSx } from '../../styles/global.styles';
import TrashIcon from '../../assets/svg/TrashIcon';
import { LuPencil } from "react-icons/lu";
import OpacityButton from '../OpacityButton/OpacityButton';
import { SchedulePriceType } from '../../types/globalTypes';

interface SchedulePricePropsI {
  title: string;
  schedules: Array<SchedulePriceType>;
  onSchedulesChange: (schedules: SchedulePriceType[]) => void;
  minuteInterval?: number;
  priceProps?: {
    isCustomPrice?: boolean;
    price?: number;
  }
  deleteBtnDisabled?: boolean;
}

const SchedulePrice: React.FC<SchedulePricePropsI> = ({
  title,
  schedules,
  onSchedulesChange,
  minuteInterval = 30,
  deleteBtnDisabled = false,
  priceProps,
}) => {
  const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);

  const sortSchedules = (schedules: SchedulePriceType[]) => {
    return schedules.sort((a, b) => dayjs(a.time.startTime).isBefore(dayjs(b.time.startTime)) ? -1 : 1);
  };

  const handleOpenChange = (open: boolean) => {
    setIsPickerOpen(open);
  };

  const handlePickerClose = (startTime: Dayjs, endTime: Dayjs) => {
    const newSchedule = {
      time: {
        startTime: startTime.toDate(),
        endTime: endTime.toDate(),
        disabled: false,
      },
      price: priceProps?.isCustomPrice ? (priceProps?.price || 0) : 0,
    };
    const overlapping = schedules.some((schedule, index) =>
      dayjs(schedule.time.startTime).isBefore(endTime) && dayjs(schedule.time.endTime).isAfter(startTime) && index !== editIndex
    );
    if (!overlapping) {
      if (editIndex !== null) {
        const updatedSchedules = [...schedules];
        updatedSchedules[editIndex] = newSchedule;
        onSchedulesChange(sortSchedules(updatedSchedules));
        setEditIndex(null);
      } else {
        onSchedulesChange(sortSchedules([...schedules, newSchedule]));
      }
    } else {
      alert('The new schedule overlaps with an existing one.');
    }
    setIsPickerOpen(false);
  };

  const handleAddScheduleClick = () => {
    setIsPickerOpen(true);
    setEditIndex(null); // Setting to null means adding a new schedule
  };

  const handleEditScheduleClick = (index: number) => {
    setIsPickerOpen(true);
    setEditIndex(index);
  };

  const deleteSchedule = (index: number) => {
    onSchedulesChange(sortSchedules(schedules.filter((_, i) => i !== index)));
  };

  const handlePriceChange = (index: number, value: string) => {
    const updatedSchedules = schedules.map((schedule, i) => {
      const updatedSchedule = i === index ? {
        ...schedule,
        price: parseInt(value, 10) || 0,
      } : schedule;
      return updatedSchedule;
    });
    onSchedulesChange(sortSchedules(updatedSchedules));
  };

  const initialStartTime = editIndex !== null && schedules[editIndex]
    ? dayjs(schedules[editIndex].time.startTime).format('HH.mm')
    : schedules.length > 0
      ? dayjs(schedules[schedules.length - 1].time.endTime).add(0, 'hour').format('HH.mm')
      : '08:00';

  const initialEndTime = editIndex !== null && schedules[editIndex]
    ? dayjs(schedules[editIndex].time.endTime).format('HH.mm')
    : schedules.length > 0
      ? dayjs(schedules[schedules.length - 1].time.endTime).add(1, 'hour').format('HH.mm')
      : '18:00';

  const disabledIntervals = generateDisabledTimeIntervals(schedules, minuteInterval, editIndex !== null ? schedules?.[editIndex] : undefined);

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="body1" fontWeight={500}>{title}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 1, mt: 1 }}>
        {schedules.map((schedule, index) => (
          <Box key={index} sx={{ gap: 1, display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
            <CustomButton
              variant="outlined"
              sx={{ ...TextButtonSx, justifyContent: 'space-between', flex: 1, color: 'black' }}
              endormentSx={{
                end: { marginLeft: 0 }
              }}
              endEndorment={<LuPencil />}
              onClick={() => handleEditScheduleClick(index)}
              disabled={schedule.time.disabled || false}
            >
              {`${dayjs(schedule.time.startTime).format('HH.mm')} - ${dayjs(schedule.time.endTime).format('HH.mm')}`}
            </CustomButton>
            <NumberInput
              required
              sx={{ flex: 1 }}
              startEndorment={"Rp"}
              formatFunction={(value) => formatNumber(Number(value))}
              numberInputProps={{
                placeholder: "100.000",
                value: String(schedule.price),
                onChange: (e) => handlePriceChange(index, e.target.value),
              }}
              disabled={priceProps?.isCustomPrice || false}
            />
            <OpacityButton sx={{
              display: 'flex', justifyContent: 'center', alignItems: 'center',
              width: '40px', height: '40px', borderRadius: '50%'
            }}
              disabled={deleteBtnDisabled}
              onClick={() => deleteSchedule(index)}>
              <TrashIcon color={COLOR.danger500} />
            </OpacityButton>
          </Box>
        ))}
      </Box>
      <CustomButton
        variant="dashed"
        startEndorment={<GoPlusCircle size={18} />}
        sx={{ mb: 2 }}
        onClick={handleAddScheduleClick}
      >
        <Typography
          component="span"
          fontWeight="inherit"
          fontSize="inherit"
        >
          Tambah Jam Buka
        </Typography>
      </CustomButton>

      <TimeRangePicker
        open={isPickerOpen}
        onOpenChange={handleOpenChange}
        onClose={handlePickerClose}
        initialStartTime={initialStartTime}
        initialEndTime={initialEndTime}
        intervalDuration={minuteInterval}
        disabledIntervals={disabledIntervals}
      />
    </Box>
  );
};

export default SchedulePrice;
