import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { COLOR } from "../../../utils/color";
import { CSSProperties } from "react";

export const ContainerStyle: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  width: '100%',
  height: '100%',
};

export const ContainerCss: CSSProperties = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  width: '100%',
  height: '100%',
};

export const ImageButtonStyle: SxProps<Theme> = {
  mt: 5,
  position: "relative",
};

export const BusinessImageContainerStyle: SxProps<Theme> = {
  width: 100,
  height: 100,
  borderRadius: "50%",
  overflow: "hidden",
  display: 'flex',
  backgroundColor: COLOR.neutral200,
  justifyContent: 'center',
  alignItems: 'center',
};

export const BusinessImageEditStyle: SxProps<Theme> = {
  position: "absolute",
  width: 36,
  height: 36,
  borderRadius: "50%",
  backgroundColor: COLOR.primary500,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  bottom: 0,
  right: 0,
};
