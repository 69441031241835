import { SxProps } from "@mui/material";
import BottomNav from "../../BottomNav";
import AppContainer from "../AppContainer/AppContainer";
import { Theme } from "@emotion/react";

interface MobileContainerPropsI {
  children: React.ReactNode;
  additionalBottomChildren?: React.ReactNode;
  sx?: SxProps<Theme>;
}
const MobileContainer: React.FC<MobileContainerPropsI> = (props) => {
  const { children, additionalBottomChildren, ...otherProps } = props;
  return (
    <AppContainer
      {...otherProps}
      bottomChildren={
        <>
          {additionalBottomChildren}
          <BottomNav />
        </>
      }
    >
      {children}
    </AppContainer>
  );
};

export default MobileContainer;