import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { getSubdomain } from '../utils/route';
import { useGetDataByDomainName } from '../query/queries';
import { PublicCompanyWebsiteResponse } from '../api/request.types';
import { useNavigate } from 'react-router-dom';
import { ROUTE_NAME, RoutePath } from '../utils/constant';

interface DynamicPageContextType {
  data: PublicCompanyWebsiteResponse;
  subdomain: string;
  loading: boolean;
  error: Error | null;
}

interface DataProviderProps {
  children: ReactNode;
}

const DynamicPageContext = createContext<DynamicPageContextType | undefined>(undefined);

const DEFAULT_DATA: PublicCompanyWebsiteResponse = {
  companyWebsiteID: '',
  companyID: '',
  companyDomainName: '',
  logoPath: '',
  template: 'Type1',
  color: '',
  name: '',
  description: '',
  products: [],
  logoSignedURL: '',
  openingHours: ''
};

export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
  const subdomain = getSubdomain();
  // const isIgnoredPath = window.location.href.includes(RoutePath[ROUTE_NAME.DYNAMIC_NOT_FOUND]);
  const getDataByDomainNameQuery = useGetDataByDomainName(subdomain, { retry: false });
  const navigate = useNavigate();
  const [data, setData] = useState<PublicCompanyWebsiteResponse>(DEFAULT_DATA);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    // if (isIgnoredPath) return; // do not fetch
    if (getDataByDomainNameQuery.isLoading) {
      setLoading(true);
    } else if (getDataByDomainNameQuery.isError) {
      setError(getDataByDomainNameQuery.error as Error);
      navigate(RoutePath[ROUTE_NAME.DYNAMIC_NOT_FOUND], { replace: true });
      setLoading(false);
    } else if (getDataByDomainNameQuery.isSuccess) {
      const responseData = getDataByDomainNameQuery.data?.data?.data;
      if (responseData) {
        setData(responseData);
      } else {
        navigate(RoutePath[ROUTE_NAME.DYNAMIC_NOT_FOUND], { replace: true });
      }
      setLoading(false);
    }
  }, [
    getDataByDomainNameQuery.isLoading,
    getDataByDomainNameQuery.isError,
    getDataByDomainNameQuery.isSuccess,
    getDataByDomainNameQuery.error,
    getDataByDomainNameQuery.data?.data,
    navigate
  ]);

  return (
    <DynamicPageContext.Provider value={{
      data,
      subdomain,
      loading,
      error
    }}>
      {children}
    </DynamicPageContext.Provider>
  );
};

export const useDynamicPage = (): DynamicPageContextType => {
  const context = useContext(DynamicPageContext);
  if (context === undefined) {
    throw new Error('useDynamicPage must be used within a DataProvider');
  }
  return context;
};
