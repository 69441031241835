import { Theme } from "@emotion/react";
import { Box, BoxProps, SxProps, TextField } from "@mui/material";
import InputTitle from "../InputTitle/InputTitle";
import HelperText from "../HelperText/HelperText";
import { FormBaseProps } from "../../../types/globalTypes";
import {
    ContainerStyle,
    DisabledContainerStyle,
    ErrorContainerStyle,
    InputStyle,
} from "../form.styles";
import { stripFormatting } from "../../../utils/helper";

interface NumberInputPropsI extends BoxProps, FormBaseProps {
    startEndorment?: React.ReactNode;
    endEndorment?: React.ReactNode;
    disabled?: boolean;
    error?: boolean;
    formatFunction?: (e: string) => string;
    numberInputProps?: {
        value?: string;
        onChange?: React.ChangeEventHandler<HTMLInputElement>;
        placeholder?: string;
        style?: React.CSSProperties;
        onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    };
}

const NumberInput: React.FC<NumberInputPropsI> = (props) => {
    const {
        sx,
        startEndorment,
        endEndorment,
        disabled,
        error,
        formatFunction,
        numberInputProps,
    } = props;

    const formattedValue = formatFunction
        ? formatFunction(numberInputProps?.value || "")
        : numberInputProps?.value;

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if (numberInputProps?.onChange) {
            const rawValue = stripFormatting(e.target.value);
            numberInputProps.onChange({
                ...e,
                target: { ...e.target, value: rawValue },
            });
        }
    };

    return (
        <Box
            sx={{
                ...sx,
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
            }}
        >
            <InputTitle
                title={props.title}
                required={props.required}
                textProps={{
                    sx: {
                        mb: 1,
                    },
                }}
            />
            <Box
                sx={
                    {
                        ...ContainerStyle,
                        ...(disabled ? DisabledContainerStyle : {}),
                        ...(error ? ErrorContainerStyle : {}),
                    } as SxProps<Theme>
                }
            >
                {startEndorment && (
                    <Box component="span" sx={{ mr: 1 }}>
                        {startEndorment}
                    </Box>
                )}
                <TextField
                    {...numberInputProps}
                    type="text"
                    variant="standard"

                    sx={{
                        ...InputStyle,
                        ...numberInputProps?.style,
                    }}
                    InputProps={{
                        disableUnderline: true,
                    }}
                    value={formattedValue}
                    onChange={handleChange}
                    inputProps={{
                        inputMode: 'decimal',
                        pattern: '[0-9]*',
                    }}
                />
                {endEndorment && (
                    <Box component="span" sx={{ ml: 1 }}>
                        {endEndorment}
                    </Box>
                )}
            </Box>
            <HelperText {...props.helper} />
        </Box>
    );
};

export default NumberInput;
