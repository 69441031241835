import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditProductData, ProductData } from '../../types/globalTypes';
import { DAY } from '../../utils/date';
import { DURATION_PERIOD } from '../../utils/constant';


export type ProductReducerState = {
  data: ProductData;
  edit: EditProductData;
}

export const initialState: ProductReducerState = {
  data: {
    images: new Array(5).fill({
      file: null,
      base64: '',
    }),
    name: '',
    price: 0,
    duration: 0,
    durationUnit: DURATION_PERIOD.MINUTES,
    quota: 0,
    isCustomPrice: true,
    schedules: {
      [DAY.SUNDAY]: [],
      [DAY.MONDAY]: [
        { startTime: '08:00', endTime: '18:00', price: 0 },
      ],
      [DAY.TUESDAY]: [
        { startTime: '08:00', endTime: '18:00', price: 0 },
      ],
      [DAY.WEDNESDAY]: [
        { startTime: '08:00', endTime: '18:00', price: 0 },
      ],
      [DAY.THURSDAY]: [
        { startTime: '08:00', endTime: '18:00', price: 0 },
      ],
      [DAY.FRIDAY]: [
        { startTime: '08:00', endTime: '18:00', price: 0 },
      ],
      [DAY.SATURDAY]: [],
    },
    description: '',
    location: {
      lat: 0,
      long: 0,
      search: '',
    },
    locationDetail: '',
    allowReschedule: false,
  },
  edit: {
    images: new Array(5).fill({
      file: null,
      base64: '',
      url: null,
    }),
    name: '',
    price: 0,
    duration: 0,
    durationUnit: DURATION_PERIOD.MINUTES,
    quota: 0,
    isCustomPrice: true,
    schedules: {
      [DAY.SUNDAY]: [],
      [DAY.MONDAY]: [
        { startTime: '08:00', endTime: '18:00', price: 0 },
      ],
      [DAY.TUESDAY]: [
        { startTime: '08:00', endTime: '18:00', price: 0 },
      ],
      [DAY.WEDNESDAY]: [
        { startTime: '08:00', endTime: '18:00', price: 0 },
      ],
      [DAY.THURSDAY]: [
        { startTime: '08:00', endTime: '18:00', price: 0 },
      ],
      [DAY.FRIDAY]: [
        { startTime: '08:00', endTime: '18:00', price: 0 },
      ],
      [DAY.SATURDAY]: [],
    },
    description: '',
    location: {
      lat: 0,
      long: 0,
      search: '',
      isCustomLocation: false,
    },
    locationDetail: '',
    allowReschedule: false,
  }
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setScheduleData: (state, action: PayloadAction<ProductData['schedules']>) => {
      return {
        ...state,
        data: {
          ...state.data,
          schedules: {
            [DAY.SUNDAY]: [
              ...(action.payload?.[DAY.SUNDAY] || [])
            ],
            [DAY.MONDAY]: [
              ...(action.payload?.[DAY.MONDAY] || [])
            ],
            [DAY.TUESDAY]: [
              ...(action.payload?.[DAY.TUESDAY] || [])
            ],
            [DAY.WEDNESDAY]: [
              ...(action.payload?.[DAY.WEDNESDAY] || [])
            ],
            [DAY.THURSDAY]: [
              ...(action.payload?.[DAY.THURSDAY] || [])
            ],
            [DAY.FRIDAY]: [
              ...(action.payload?.[DAY.FRIDAY] || [])
            ],
            [DAY.SATURDAY]: [
              ...(action.payload?.[DAY.SATURDAY] || [])
            ],
          },
        }
      }
    },
    setProductData: (state, action: PayloadAction<Partial<ProductData>>) => {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          schedules: {
            ...state.data.schedules,
            ...action.payload.schedules,
          },
          location: {
            ...state.data.location,
            ...action.payload.location,
          },
        }
      }
    },
    resetProductData: (state) => {
      return {
        ...state,
        data: {
          ...initialState.data,
        }
      };
    },
    setScheduleEditData: (state, action: PayloadAction<EditProductData['schedules']>) => {
      return {
        ...state,
        edit: {
          ...state.edit,
          schedules: {
            [DAY.SUNDAY]: [
              ...(action.payload?.[DAY.SUNDAY] || [])
            ],
            [DAY.MONDAY]: [
              ...(action.payload?.[DAY.MONDAY] || [])
            ],
            [DAY.TUESDAY]: [
              ...(action.payload?.[DAY.TUESDAY] || [])
            ],
            [DAY.WEDNESDAY]: [
              ...(action.payload?.[DAY.WEDNESDAY] || [])
            ],
            [DAY.THURSDAY]: [
              ...(action.payload?.[DAY.THURSDAY] || [])
            ],
            [DAY.FRIDAY]: [
              ...(action.payload?.[DAY.FRIDAY] || [])
            ],
            [DAY.SATURDAY]: [
              ...(action.payload?.[DAY.SATURDAY] || [])
            ],
          },
        }
      }
    },
    setProductEditData: (state, action: PayloadAction<Partial<EditProductData>>) => {
      return {
        ...state,
        edit: {
          ...state.edit,
          ...action.payload,
          location: {
            ...state.edit.location,
            ...action.payload.location,
          },
        }
      }
    },
    resetProductEditData: (state) => {
      return {
        ...state,
        edit: {
          ...initialState.edit,
        }
      };
    },
  },
});

export const {
  setProductData, resetProductData, setScheduleData,
  setScheduleEditData, setProductEditData, resetProductEditData,
} = productSlice.actions;
export default productSlice.reducer;
