import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import DHome from "./Template1/DHome";
import DProduct from "./Template1/DProduct";
import { DataProvider } from "../../context/DynamicPageContext";
import { ROUTE_NAME, RoutePath } from "../../utils/constant";
import DSchedule from "./Template1/DSchedule";
import { useGetDomainAvailability } from "../../query/queries";
import { getSubdomain } from "../../utils/route";
import { useEffect } from "react";
import DInvoice from "./Template1/DInvoice";
import DPaymentDetail from "./Template1/DPaymentDetail";
import StatusInfo from "../StatusInfo/StatusInfo";

interface DynamicPagePropsI { }

const DynamicPage: React.FC<DynamicPagePropsI> = (props) => {
  // const [html, setHtml] = useState("");
  // const [css, setCss] = useState("");
  // const webname = window.location.hostname.split(".")[0];
  const subdomain = getSubdomain();
  const navigate = useNavigate();
  // const isDomainName = subdomain.includes(DOMAIN_NAME);
  const getDomainAvailability = useGetDomainAvailability(subdomain, { retry: false, cacheTime: 2 });
  useEffect(() => {
    const domainIsUsed = getDomainAvailability.data?.data?.data;
    if (domainIsUsed) {
      navigate(RoutePath[ROUTE_NAME.DYNAMIC_NOT_FOUND])
    }
  }, [
    getDomainAvailability.data?.data?.data,
    getDomainAvailability.isLoading,
    navigate
  ]);
  // useEffect(() => {
  //     const webname = window.location.hostname.split(".")[0];
  //     const fetchPageData = async () => {
  //         try {
  //             const response = await axios.get(`/api/pages/${webname}`);
  //             setHtml(response.data.html);
  //             setCss(response.data.css);
  //         } catch (error) {
  //             console.error("Error fetching page data:", error);
  //         }
  //     };
  //     fetchPageData();
  // }, []);
  return (
    // <div>
    //     <style>{css}</style>
    //     <div dangerouslySetInnerHTML={{ __html: html }} />
    // </div>
    <DataProvider>
      <Routes>
        <Route path={RoutePath[ROUTE_NAME.DYNAMIC_HOME]} element={<DHome />} />
        {/* Default component for subdomain */}
        <Route
          path={RoutePath[ROUTE_NAME.DYNAMIC_PRODUCT_DETAIL] + '/:productId'}
          element={<DProduct />}
        />
        <Route
          path={RoutePath[ROUTE_NAME.DYNAMIC_INVOICE] + '/:invoiceId'}
          element={<DInvoice />}
        />
        <Route
          path={`${RoutePath[ROUTE_NAME.DYNAMIC_SCHEDULE]}/:productId`}
          element={<DSchedule />}
        />
        <Route
          path={RoutePath[ROUTE_NAME.DYNAMIC_PAYMENT_DETAIL]}
          element={<DPaymentDetail />}
        />
        <Route path={RoutePath[ROUTE_NAME.DYNAMIC_STATUS_INFO]} element={<StatusInfo />} />
        <Route path={RoutePath[ROUTE_NAME.DYNAMIC_NOT_FOUND]} element={"Not Found"} />
        <Route path="*" element={<Navigate to={RoutePath[ROUTE_NAME.DYNAMIC_HOME]} />} />
      </Routes>
    </DataProvider>
  );
};

export default DynamicPage;
