import React, { useState, ChangeEvent, FocusEvent, useEffect } from 'react';
import { Box, Button, SxProps, TextField } from '@mui/material';
import { COLOR } from '../../utils/color';
import { Theme } from '@emotion/react';

type QuantitySelectorProps = {
  value?: number;
  max?: number;
  min?: number;
  disableIncrement?: boolean;
  disableDecrement?: boolean;
  disableInput?: boolean;
  disabled?: boolean;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  initialValue?: number;
  onChange?: (value: number) => void;
  maxDigits?: number;
  primaryColor?:string;
};

const QuantitySelector: React.FC<QuantitySelectorProps> = ({
  value,
  max = Infinity,
  min = 0,
  disableIncrement = false,
  disableDecrement = false,
  disableInput = false,
  disabled = false,
  onFocus,
  onBlur,
  initialValue = 0,
  onChange,
  maxDigits = 2,
  primaryColor = COLOR.primary500,
}) => {
  const [internalValue, setInternalValue] = useState<number | string>(value ?? initialValue);

  const inputWidth = `${maxDigits + 1}ch`;

  useEffect(() => {
    if (value !== undefined) {
      setInternalValue(value);
    }
  }, [value]);

  const handleIncrement = () => {
    if (!disabled && !disableIncrement && typeof internalValue === 'number' && internalValue < max) {
      const newValue = internalValue + 1;
      setInternalValue(newValue);
      onChange && onChange(newValue);
    }
  };

  const handleDecrement = () => {
    if (!disabled && !disableDecrement && typeof internalValue === 'number' && internalValue > min) {
      const newValue = internalValue - 1;
      setInternalValue(newValue);
      onChange && onChange(newValue);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (!disabled && !disableInput) {
      if (inputValue === '') {
        setInternalValue('');
        onChange && onChange(0);
      } else if (/^\d*$/.test(inputValue)) {
        let parsedValue = parseInt(inputValue, 10);

        if (parsedValue > max) {
          parsedValue = max;
        } else if (parsedValue < min) {
          parsedValue = min;
        }

        setInternalValue(parsedValue);
        onChange && onChange(parsedValue);
      }
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (internalValue === '') {
      setInternalValue(0);
    }
    onBlur && onBlur(event);
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    onFocus && onFocus(event);
  };

  const buttonSx: SxProps<Theme> = {
    width: 24,
    height: 24,
    borderRadius: 1,
    backgroundColor: primaryColor,
    color: 'white',
    minWidth: 0,
    padding: 0,
    '&:hover': {
      backgroundColor: primaryColor,
    },
    '&:disabled': {
      color: 'white',
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <Button
        onClick={handleDecrement}
        sx={{
          ...buttonSx,
          opacity: disabled || disableDecrement || Number(internalValue) <= min ? 0.5 : 1,
        }}
        disabled={disabled || disableDecrement || Number(internalValue) <= min}
      >
        -
      </Button>
      <TextField
        type="number"
        value={Number(internalValue).toString()}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled || disableInput}
        inputProps={{
          min,
          max,
          style: {
            textAlign: 'center',
            width: inputWidth,
          },
        }}
        sx={{
          marginX: 1,
          opacity: disabled ? 0.5 : 1,
        }}
        variant="outlined"
        size="small"
      />
      <Button
        onClick={handleIncrement}
        sx={{
          ...buttonSx,
          opacity: disabled || disableIncrement || Number(internalValue) >= max ? 0.5 : 1,
        }}
        disabled={disabled || disableIncrement || Number(internalValue) >= max}
      >
        +
      </Button>
    </Box>
  );
};

export default QuantitySelector;
