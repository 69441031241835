import { useEffect, useState } from "react";
import { STORAGE_KEY } from "../utils/constant";

type SetValue<T> = T | ((val: T) => T);

function useLocalStorage<T>(
  key: STORAGE_KEY,
  initialValue: T,
): [T, (value: SetValue<T>, callback?: () => void) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      if (typeof window !== "undefined") {
        const item = window.localStorage.getItem(key);
        if (item) {
          const parsedItem = JSON.parse(item) as unknown;
          if (isObject(parsedItem) && isObject(initialValue)) {
            return mergeAndFilter(parsedItem as Partial<T & Record<string, unknown>>, initialValue);
          } else {
            return parsedItem as T;
          }
        }
      }
      return initialValue;
    } catch (error) {
      console.error('Error reading localStorage key “' + key + '”: ', error);
      return initialValue;
    }
  });

  const setValue: (value: SetValue<T>, callback?: () => void) => void = (value, callback) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
      if (callback) callback();
    } catch (error) {
      console.error('Error setting localStorage key “' + key + '”: ', error);
    }
  };

  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(storedValue));
    } catch (error) {
      console.error('Error writing localStorage key “' + key + '”: ', error);
    }
  }, [key, storedValue]);

  return [storedValue, setValue];
}

function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === "object" && value !== null && !Array.isArray(value);
}

function mergeAndFilter<T extends object>(item: Partial<T>, initialValue: T): T {
  return Object.keys(initialValue).reduce((acc, key) => {
    const itemValue = item[key as keyof T];
    const initialValueProp = initialValue[key as keyof T];

    if (isObject(itemValue) && isObject(initialValueProp)) {
      acc[key as keyof T] = mergeAndFilter(itemValue as Partial<typeof initialValueProp>, initialValueProp) as T[keyof T];
    } else {
      acc[key as keyof T] = itemValue !== undefined ? itemValue as T[keyof T] : initialValueProp;
    }

    return acc;
  }, {} as T);
}


export default useLocalStorage;
