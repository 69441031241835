import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  SxProps,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { formatNumber } from "../../utils/helper"; // Ensure this helper function is defined
import { COLOR } from "../../utils/color";
import { Theme } from "@emotion/react";

export interface ScheduleItem {
  day: string;
  startTime: Date;
  endTime: Date;
  price: number;
}

interface ScheduleTableProps {
  schedule: ScheduleItem[];
  sx?: SxProps<Theme>;
}

const tableContainerStyles = {
  borderRadius: "8px",
  border: `1px solid ${COLOR.neutral300}`,
  boxShadow: "none",
  padding: 0,
};

const tableStyles = {
  borderCollapse: "collapse",
};

const headerRowStyles = {
  backgroundColor: COLOR.neutral100,
};

const headerCellStyles = {
  fontWeight: "bold",
  textAlign: "left",
  color: COLOR.neutral500,
  padding: "8px 12px",
};

const contentCellStyles = {
  textAlign: "left",
  padding: "8px 12px",
  border: 'none',
  color: COLOR.neutral500,
};

const ScheduleTable: React.FC<ScheduleTableProps> = (props) => {
  const { schedule } = props;
  const timeFormat = "HH.mm";
  return (
    <TableContainer component={Paper} sx={{ ...tableContainerStyles, ...(props.sx || {}) }}>
      <Table sx={tableStyles}>
        <TableHead>
          <TableRow sx={headerRowStyles}>
            <TableCell sx={headerCellStyles}>Hari</TableCell>
            <TableCell sx={headerCellStyles}>Waktu</TableCell>
            <TableCell sx={headerCellStyles}>Harga</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schedule.length === 0 ? (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography color={COLOR.neutral500} py={2} textAlign="center" variant="body1">
                  Jadwal belum diatur
                </Typography>
              </TableCell>
            </TableRow>
          )
            : (
              schedule.map((row, index) => (
                <TableRow key={index} sx={{ borderBottom: "none" }}>
                  <TableCell sx={contentCellStyles}>{row.day}</TableCell>
                  <TableCell sx={contentCellStyles}>
                    {`${dayjs(row.startTime).format(timeFormat)} - ${dayjs(row.endTime).format(timeFormat)}`}
                  </TableCell>
                  <TableCell sx={contentCellStyles}>
                    {`Rp ${formatNumber(row.price)}`}
                  </TableCell>
                </TableRow>
              ))
            )}

        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScheduleTable;
