import { Box, SxProps, Typography } from "@mui/material";
import MenuButton from "../../../../components/MenuButton/MenuButton";
import { COLOR } from "../../../../utils/color";
import TransactionIcon from "../../../../assets/svg/TransactionIcon";
import WalletIcon from "../../../../assets/svg/WalletIcon";
import ProductIcon from "../../../../assets/svg/ProductIcon";
import StoreIcon from "../../../../assets/svg/StoreIcon";
import { formatNumber } from "../../../../utils/helper";
import OpacityButton from "../../../../components/OpacityButton/OpacityButton";
import EyeIcon from "../../../../assets/svg/EyeOpenIcon";
import EyeClosedIcon from "../../../../assets/svg/EyeClosedIcon";
import { useState } from "react";
import { Theme } from "@emotion/react";

interface WalletPropsI {
  amount: number;
  onClickTransaction: React.MouseEventHandler<HTMLDivElement>;
  onClickWithdraw: React.MouseEventHandler<HTMLDivElement>;
  onClickProduct: React.MouseEventHandler<HTMLDivElement>;
  onClickBusinessProfile: React.MouseEventHandler<HTMLDivElement>;
  sx?: SxProps<Theme>;
}

const Wallet: React.FC<WalletPropsI> = (props) => {
  const [hideAmount, setHideAmount] = useState<boolean>(false);

  const BUTTON_LIST = [
    {
      IconElement: TransactionIcon,
      primaryColor: COLOR.danger500,
      secondaryColor: COLOR.danger50,
      id: "transaction",
      onClick: props.onClickTransaction,
    },
    // {
    //   IconElement: WalletIcon,
    //   primaryColor: COLOR.warning500,
    //   secondaryColor: COLOR.warning50,
    //   id: "withdraw",
    //   onClick: props.onClickWithdraw,
    // },
    {
      IconElement: ProductIcon,
      primaryColor: COLOR.success500,
      secondaryColor: COLOR.success50,
      id: "product",
      onClick: props.onClickProduct,
    },
    {
      IconElement: StoreIcon,
      primaryColor: COLOR.primary500,
      secondaryColor: COLOR.primary50,
      id: "profile",
      onClick: props.onClickBusinessProfile,
    },
  ];

  const textById: Record<string, string> = {
    transaction: "Transaksi",
    withdraw: "Pencairan",
    product: "Produk",
    profile: "Profil Usaha",
  };

  return (
    <Box sx={{ backgroundColor: "white", p: 3, borderRadius: "20px", wordBreak: "break-all", ...props.sx }} boxShadow={2}>
      {/* <Typography color={COLOR.neutral500} fontWeight={600} fontSize={14}>
        Saldo Saat Ini
      </Typography>
      <Box display="flex" flexDirection="row" justifyContent="space-between" pb={2}>
        <Typography fontWeight={800} fontSize={25}>
          {hideAmount ? "* * * * *" : `Rp ${formatNumber(props.amount)}`}
        </Typography>
        <OpacityButton disableOpacity onClick={() => setHideAmount((prev) => !prev)} sx={{ display: "flex", justifyContent: "center", alignItems: "center", pl: 1 }}>
          {!hideAmount ? <EyeIcon color={COLOR.neutral400} /> : <EyeClosedIcon color={COLOR.neutral400} />}
        </OpacityButton>
      </Box> */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {BUTTON_LIST.map((button) => (
          <MenuButton
            key={button.id}
            icon={<button.IconElement color={button.primaryColor} />}
            color={button.secondaryColor}
            text={textById[button.id]}
            onClick={button.onClick}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Wallet;
