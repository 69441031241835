import AppContainer from "../../../components/Layout/AppContainer/AppContainer";
import { Box, Typography } from "@mui/material";
import SubMenuHeader from "../../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft } from "react-icons/fa";
import RowKeyValue from "../../../components/RowKeyValue/RowKeyValue";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  DEFAULT_SNACKBAR_PROPS,
  ROUTE_NAME,
  RoutePath,
  TIMEZONE_SHORTLABEL,
} from "../../../utils/constant";
import { useState } from "react";
import TicketSeparator from "../../../components/TicketSeparator/TicketSeparator";
import {
  formatCurrency,
  formatPhoneNumber,
  stripFormatting,
} from "../../../utils/helper";
import { COLOR } from "../../../utils/color";
import TextInput from "../../../components/Forms/TextInput/TextInput";
import TextArea from "../../../components/Forms/TextArea/TextArea";
import UserIcon from "../../../assets/svg/UserIcon";
import MailIcon from "../../../assets/svg/MailIcon";
import PhoneIcon from "../../../assets/svg/PhoneIcon";
import Joi, { ValidationErrorItem } from "joi";
import { receipentSchema } from "../../../joiSchema/payment";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { CalculateTransactionResponse } from "../../../api/request.types";
import dayjs from "dayjs";
import { PublicScheduleDetailWithQty } from "../../../types/globalTypes";
import useSnap from "../../../hooks/useSnap";
import { useCreateNewTransaction } from "../../../query/mutations";
import { isAxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { errorLogger } from "../../../utils/logger";
import isEmpty from "lodash.isempty";

type PaymentReceipentType = {
  name: string;
  email: string;
  phone: string;
  notes: string;
};
const DEFAULT_PAYMENT_RECEIPENT_DATA = {
  name: "",
  email: "",
  phone: "",
  notes: "",
};
export type PaymentDataState = {
  data: {
    productId: string;
    date: string;
    scheduleDetails: PublicScheduleDetailWithQty[];
  };
};
const DPaymentDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location?.state;

  const orderedData = locationState?.data as CalculateTransactionResponse;
  const { snapEmbed } = useSnap();
  const createNewTransactionMutation = useCreateNewTransaction();

  const [paymentReceipent, setPaymentReceipent] =
    useState<PaymentReceipentType>(DEFAULT_PAYMENT_RECEIPENT_DATA);
  const [snapShow, setSnapShow] = useState<boolean>(false);
  const [errors, setErrors] = useState<
    Record<keyof PaymentReceipentType, string>
  >({
    name: "",
    email: "",
    notes: "",
    phone: "",
  });

  const validateError = () => {
    const { error } = receipentSchema.validate(paymentReceipent, {
      abortEarly: false,
    });
    if (error) {
      const errors = error.details.reduce(
        (acc: any, err: ValidationErrorItem) => {
          return { ...acc, [err.path[0]]: err.message };
        },
        {}
      );
      setErrors((prevErrors) => ({ ...prevErrors, ...errors }));
      return true;
    }
    return false;
  };

  const handleBlur = (field: keyof PaymentReceipentType) => () => {
    const { error } = Joi.object({
      [field]: receipentSchema.extract(field),
    }).validate({ [field]: paymentReceipent[field] });
    if (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: error.message,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };
  const handleSubmit = async () => {
    try {
      const hasError = validateError();
      if (!hasError) {
        const data = {
          ...orderedData,
          fullName: paymentReceipent.name,
          email: paymentReceipent.email,
          phoneNumber: paymentReceipent.phone,
          notes: paymentReceipent.notes,
        };
        const apiResponse = await createNewTransactionMutation.mutateAsync(
          data
        );
        const responseData = apiResponse.data.data;
        setSnapShow(true);
        snapEmbed(responseData.snapToken, "snap-container", {
          onSuccess: function (result: any) {
            const { transactionID } = responseData;
            navigate(
              `${RoutePath[ROUTE_NAME.DYNAMIC_INVOICE]}/${transactionID}`
            );
            setSnapShow(false);
          },
          onPending: function (result: any) {
            const { transactionID } = responseData;
            navigate(
              `${RoutePath[ROUTE_NAME.DYNAMIC_INVOICE]}/${transactionID}`
            );
            setSnapShow(false);
          },
          onClose: function (result: any) {
            const { transactionID } = responseData;
            navigate(
              `${RoutePath[ROUTE_NAME.DYNAMIC_INVOICE]}/${transactionID}`
            );
            setSnapShow(false);
          },
        });
      }
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 409) {
        const errorData = error.response.data?.data;
        if (Array.isArray(errorData)) {
          errorData.forEach((product) => {
            const { startTime, endTime, date } = product.scheduleDetails;
            const msg = `Kuota pada tanggal ${date} (${startTime}-${endTime}) tidak mencukupi`;

            enqueueSnackbar({
              ...DEFAULT_SNACKBAR_PROPS,
              variant: "error",
              message: msg,
            });
          });
        } else {
          const msg = `Telah terjadi pembaharuan terhadap harga dan jadwal yang telah kamu pilih`;
          enqueueSnackbar({
            ...DEFAULT_SNACKBAR_PROPS,
            variant: "error",
            message: msg,
          });
        }
      } else {
        errorLogger(error);
        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
          message: "Terjadi kesalahan, mohon coba dalam beberapa saat lagi",
        });
      }
    }
  };

  const handleOnChange = (key: keyof PaymentReceipentType) => (e: any) => {
    switch (key) {
      case "phone":
        let phoneNumber = e?.target?.value || "";
        phoneNumber = stripFormatting(phoneNumber);
        setPaymentReceipent((prevData) => ({
          ...prevData,
          [key]: phoneNumber,
        }));
        return;
      case "email":
      case "name":
      case "notes":
        setPaymentReceipent((prevData) => ({
          ...prevData,
          [key]: e.target.value,
        }));
        return;
    }
  };

  if (isEmpty(locationState)) {
    return <Navigate to={RoutePath[ROUTE_NAME.DYNAMIC_HOME]} replace />;
  }

  return (
    <AppContainer>
      <SubMenuHeader
        leftNav={{
          icon: <FaArrowLeft />,
          disabled: snapShow,
        }}
        text={"Pembayaran"}
      />
      {!snapShow && (
        <Box px={2} py={2}>
          <Box
            py={2}
            sx={{
              backgroundColor: "white",
              border: `1px solid ${COLOR.neutral200}`,
              borderRadius: 4,
            }}
          >
            <RowKeyValue
              px={2}
              title="Produk"
              value={orderedData.productDetail?.[0]?.productName}
            />
            <RowKeyValue px={2} title="Alamat" value={orderedData.location} />
            <TicketSeparator />
            <RowKeyValue px={2} title="Jadwal" value="" />
            {orderedData.productDetail.map((product) => {
              return (
                <Box key={product.productID}>
                  {product.scheduleDetails.map((schedule, key) => {
                    const start = dayjs(schedule.startTime, "HH.mm");
                    const end = dayjs(schedule.endTime, "HH.mm");
                    const date = dayjs(schedule.date, "YYYY-MM-DD");
                    return (
                      <RowKeyValue
                        key={`${schedule.startTime}_${schedule.endTime}_${schedule.date}_${key}`}
                        px={2}
                        title=""
                        value={`${date.format("DD MMM YYYY")} / ${start.format(
                          "HH.mm"
                        )} - ${end.format("HH.mm")} ${
                          TIMEZONE_SHORTLABEL?.[orderedData.timezone] || ""
                        } (${end.diff(start, "minute")} menit)`}
                      />
                    );
                  })}
                </Box>
              );
            })}
            <TicketSeparator
              hideCircle={false}
              circleBorderWidth={1}
              circleBorderColor={COLOR.neutral200}
              outerBorderWidth={1}
              outerBorderColor="white"
            />
            <RowKeyValue
              px={2}
              titleProps={{ sx: { color: COLOR.neutral500, fontWeight: 600 } }}
              title="Total:"
              value={formatCurrency(orderedData.total, "IDR")}
            />
          </Box>
          <Box
            component="form"
            onSubmit={() => handleSubmit()}
            display="flex"
            flexDirection="column"
            py={2}
            gap={2}
          >
            <TextInput
              title="Nama Lengkap"
              required
              startEndorment={<UserIcon color={COLOR.neutral400} />}
              textInputProps={{
                placeholder: "cth: John Doe",
                value: paymentReceipent.name,
                onChange: handleOnChange("name"),
                onBlur: handleBlur("name"),
              }}
              helper={{
                color: COLOR.danger500,
                text: errors["name"],
              }}
            />
            <TextInput
              title="Alamat Email"
              required
              startEndorment={<MailIcon color={COLOR.neutral400} />}
              textInputProps={{
                placeholder: "cth: johndoe@email.com",
                value: paymentReceipent.email,
                onChange: handleOnChange("email"),
                onBlur: handleBlur("email"),
              }}
              helper={{
                color: COLOR.danger500,
                text: errors["email"],
              }}
            />
            <TextInput
              title="No Whatsapp"
              required={false}
              startEndorment={
                <>
                  <PhoneIcon color={COLOR.neutral400} />
                  <Typography color={COLOR.neutral400} ml={"4px"}>
                    +62
                  </Typography>
                </>
              }
              additionalPrefix={
                <Typography
                  variant="caption"
                  fontWeight={400}
                  color={COLOR.neutral400}
                >
                  (Optional)
                </Typography>
              }
              textInputProps={{
                placeholder: "cth: 818239183912",
                value: formatPhoneNumber(paymentReceipent.phone),
                onChange: handleOnChange("phone"),
                onBlur: handleBlur("phone"),
                pattern: "[0-9]*",
              }}
              helper={{
                color: COLOR.danger500,
                text: errors["phone"],
              }}
            />
            <TextArea
              title="Catatan Pembelian"
              required={false}
              textAreaProps={{
                value: paymentReceipent.notes,
                onChange: handleOnChange("notes"),
                onBlur: handleBlur("notes"),
              }}
              helper={{
                color: COLOR.danger500,
                text: errors["notes"],
              }}
            />
          </Box>

          <CustomButton
            type="submit"
            fullWidth
            onClick={() => {
              handleSubmit();
            }}
          >
            Beli Sekarang
          </CustomButton>
        </Box>
      )}
      <div id="snap-container" style={{ width: "100%" }}></div>
    </AppContainer>
  );
};

export default DPaymentDetail;
