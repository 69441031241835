import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useTokenCheck from '../hooks/useTokenCheck';
import { RoutePath, USER_ROLE } from '../utils/constant';

interface RequireAuthRouteProps {
  allowedRoles: Array<string>;
}

const RequireAuthRoute = ({ allowedRoles }: RequireAuthRouteProps) => {
  const [isLoggedIn, userRole] = useTokenCheck();
  const location = useLocation();

  const isAuthorized = allowedRoles.includes(userRole as string);
  if (isLoggedIn) {
    if (isAuthorized) {
      if (userRole === USER_ROLE.ADMIN) {
        return (
          <Outlet />
        )
      } else {
        return (
          <Outlet />
        );
      }

    } else {
      return (
        <Navigate
          to={RoutePath.UNAUTHORIZED}
          state={{ from: location }}
          replace
        />
      );
    }
  } else {
    return (
      <Navigate to={RoutePath.LOGIN} state={{ from: location }} replace />
    );
  }
};
export default RequireAuthRoute;