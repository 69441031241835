import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { DEFAULT_BOTTOM_NAVIGATION, ROUTE_NAME, RoutePath, RoutePathType } from '../utils/constant';
import { COLOR } from '../utils/color';
import { ValueOf } from '../types/globalTypes';
import { useBottomNav } from '../context/BottomNavContext';

export type BottomNavItem = {
  routeName: ROUTE_NAME;
  routePath: ValueOf<RoutePathType>;
  label: string;
  icon: React.ReactNode;
};

interface BottomNavPropsI {
  navigationList?: Array<BottomNavItem>;
}

const BottomNav: React.FC<BottomNavPropsI> = (props) => {
  const { navigationList = DEFAULT_BOTTOM_NAVIGATION } = props;

  const navigate = useNavigate();
  const { selectedRouteName, setRouteName } = useBottomNav();

  const handleNavigationChange = (_: React.SyntheticEvent, selectedRouteName: ROUTE_NAME) => {
    setRouteName(selectedRouteName);
    navigate(RoutePath[selectedRouteName], { replace: true });
  };


  return (
    <BottomNavigation
      showLabels
      sx={{
        borderTop: `1px solid ${COLOR.neutral200}`,
        minHeight: 80,
        backgroundColor: 'white'
      }}
      value={selectedRouteName}
      onChange={handleNavigationChange}
    >
      {navigationList.map((item) => {
        const { icon, label, routeName, routePath } = item;
        const isActive = selectedRouteName === routeName

        return (
          <BottomNavigationAction
            key={routeName}
            label={label}
            value={routeName}
            icon={icon}
            component={Link}
            to={routePath}
            sx={{
              color: isActive ? 'primary.main' : 'inherit',
            }}
          />
        );
      })}
    </BottomNavigation>
  );
};

export default BottomNav;
