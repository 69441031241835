import React, { CSSProperties, useEffect, useState } from "react";
import { COLOR } from "../../utils/color";

type ShowTextType = {
    text?: string;
    style?: CSSProperties;
};

interface ShowMoreByWordsProps {
    text: string;
    breakpoints?: {
        xs?: number; // Extra small screens
        sm?: number; // Small screens
        md?: number; // Medium screens
        lg?: number; // Large screens
        xl?: number; // Extra large screens
    };
    showMore?: ShowTextType;
    showLess?: ShowTextType;
}

const ShowMoreByWords: React.FC<ShowMoreByWordsProps> = ({
    text,
    breakpoints = { xs: 30, sm: 30, md: 80, lg: 80, xl: 120 },
    showMore = { text: "Lihat Semua", style: {} },
    showLess = { text: "Lihat Sebagian", style: {} },
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [maxWords, setMaxWords] = useState<number>(breakpoints.xs ?? 5); // Ensure default value

    useEffect(() => {
        const updateMaxWords = () => {
            if (window.matchMedia("(min-width: 1200px)").matches) {
                setMaxWords(breakpoints.xl ?? 25);
            } else if (window.matchMedia("(min-width: 992px)").matches) {
                setMaxWords(breakpoints.lg ?? 20);
            } else if (window.matchMedia("(min-width: 768px)").matches) {
                setMaxWords(breakpoints.md ?? 15);
            } else if (window.matchMedia("(min-width: 576px)").matches) {
                setMaxWords(breakpoints.sm ?? 10);
            } else {
                setMaxWords(breakpoints.xs ?? 5);
            }
        };

        updateMaxWords(); // Initial check
        window.addEventListener("resize", updateMaxWords); // Listen for changes

        return () => {
            window.removeEventListener("resize", updateMaxWords); // Clean up listener
        };
    }, [breakpoints]);

    const words = text.split(" ");
    const isTruncated = words.length > maxWords;
    const displayText = isExpanded ? text : words.slice(0, maxWords).join(" ");

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <span>
            <span style={{ color: COLOR.neutral500 }}>
                {displayText}
                {isTruncated && !isExpanded && "..."}
            </span>
            {isTruncated && (
                <span
                    style={{
                        cursor: "pointer",
                        color: COLOR.primary500,
                        marginLeft: "4px",
                        fontWeight: 600,
                        ...(isExpanded ? showLess.style : showMore.style),
                    }}
                    onClick={toggleExpand}
                >
                    {isExpanded ? showLess.text : showMore.text}
                </span>
            )}
        </span>
    );
};

export default ShowMoreByWords;
