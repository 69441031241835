import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { dashedBorderBgImage } from "../../../utils/helper";
import { COLOR } from "../../../utils/color";

export const ContainerStyle: SxProps<Theme> = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
};

export const ImageGridContainer: SxProps<Theme> = {
    p: 2,
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: 2,
    width: "100%",
};

export const ImageItemContainer: SxProps<Theme> = {
    width: "100%",
    height: "100%",
    minWidth: "72px",
    minHeight: "72px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
    flexDirection: "column",
    borderRadius: 2,
    backgroundImage: dashedBorderBgImage(
        COLOR.neutral50,
        COLOR.neutral300,
        "8px",
        "8px"
    ),
    cursor: "pointer",
    "&:hover": {
        filter: "brightness(98%)",
    },
    "&:active": {
        filter: "brightness(95%)",
    },
};

export const FirstImageRelativeContainer: SxProps<Theme> = {
    width: "100%",
    height: "160px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
    flexDirection: "column",
    borderRadius: 2,
    position: "relative",
    gridColumn: "span 4",
    "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "inherit",
    },
};
export const FirstImageContainer: SxProps<Theme> = {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
    flexDirection: "column",
    borderRadius: 2,
    backgroundImage: dashedBorderBgImage(
        COLOR.neutral50,
        COLOR.neutral300,
        "8px",
        "8px"
    ),
    cursor: "pointer",
    position: "relative",
    "&:hover": {
        filter: "brightness(98%)",
    },
    "&:active": {
        filter: "brightness(95%)",
    },
};

export const SmallImageRelativeContainer: SxProps<Theme> = {
    width: "100%",
    height: "100%",
    minWidth: "72px",
    minHeight: "72px",
    maxHeight: "120px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
    flexDirection: "column",
    borderRadius: 2,
    position: "relative",
};
export const SmallImageContainer: SxProps<Theme> = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
    flexDirection: "column",
    borderRadius: 2,
    backgroundImage: dashedBorderBgImage(
        COLOR.neutral50,
        COLOR.neutral300,
        "8px",
        "8px"
    ),
    cursor: "pointer",
    "&:hover": {
        filter: "brightness(98%)",
    },
    "&:active": {
        filter: "brightness(95%)",
    },
};

export const ImageStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "inherit",
};
export const IconContainerStyle: SxProps<Theme> = {
    position: "absolute",
    top: "-8px",
    right: "-8px",
    backgroundColor: "white",
    borderRadius: "50%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
};

export const FooterContainer: SxProps<Theme> = {
    borderTop: `1px solid ${COLOR.neutral200}`,
    minHeight: 80,
    width: "100%",
    px: 2,
    py: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent:'center',
    alignItems:'center',
    gap: 2,
};
