import React from 'react';
import { IconSVGProps } from '../../types/globalTypes';

const TargetIcon: React.FC<IconSVGProps> = ({
  size = 24,
  color = "white",
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="12"
        cy="12.5"
        r="8"
        stroke={color}
        strokeWidth="1.5"
      />
      <circle
        cx="12"
        cy="12.5"
        r="3"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M12 4.5V2.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12 22.5V20.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20 12.5L22 12.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M2 12.5L4 12.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default TargetIcon;
