import ENV from "../utils/env";

export const BASE_URL = ENV.BASE_URL;

export const endpoints = {
  BASE_URL,
  REGISTER: `${BASE_URL}/register`,
  LOGIN: `${BASE_URL}/login`,
  LOGIN_GOOGLE: `${BASE_URL}/google`,
  REFRESH_TOKEN: `${BASE_URL}/refresh-token`,
  LOGOUT: `${BASE_URL}/logout`,
  COMPANY: `${BASE_URL}/company`,
  PRODUCT: `${BASE_URL}/product`,
  TRANSACTION: `${BASE_URL}/transaction`,
  PRODUCT_STORAGE_UPLOAD: `${BASE_URL}/storage/upload/product`,
  STORAGE_DOWNLOAD: `${BASE_URL}/storage/download`,
  COMPANY_LOGO: `${BASE_URL}/storage/upload/companyLogo`,
  COMPANY_BY_DOMAIN: `${BASE_URL}/company/public`,
  CHECK_DOMAIN_AVAILABILITY: `${BASE_URL}/company/check`,
  GET_PUBLIC_PRODUCT_BY_ID: `${BASE_URL}/product/public`,
  GET_PUBLIC_PRODUCT_DETAIL: `${BASE_URL}/product/public/`,
  PUBLIC_CALCULATE_TRANSACTION: `${BASE_URL}/transaction/calculate`,
  GET_SCHEDULE_DETAILS_BY_PRODUCT_ID: `${BASE_URL}/product/`,
  GET_PRODUCT_COMPACT_LIST: `${BASE_URL}/product/compact`,
  PUBLIC_CREATE_NEW_TRANSACTION: `${BASE_URL}/transaction/`,
  PUBLIC_REFUND_TRANSACTION: `${BASE_URL}/transaction/refund`,
  PUBLIC_CANCEL_TRANSACTION: `${BASE_URL}/transaction/cancel`,
  PUBLIC_GET_TRANSACTION_BY_ID: `${BASE_URL}/transaction/public`,
};

export default endpoints;
