import { useEffect, useMemo, useRef, useState } from "react";
import AppContainer from "../../../components/Layout/AppContainer/AppContainer";
import { FooterContainer } from "./Template1.styles";
import { Box, Skeleton, Typography } from "@mui/material";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { formatCurrency } from "../../../utils/helper";
import { useGetPublicProductScheduleById } from "../../../query/queries";
import { useNavigate, useParams } from "react-router-dom";
import QuantitySelector from "../../../components/QuantityInput/QuantityInput";
import { useDynamicPage } from "../../../context/DynamicPageContext";
import HelperText from "../../../components/Forms/HelperText/HelperText";
import {
  useCalculateTransaction,
} from "../../../query/mutations";
import { enqueueSnackbar } from "notistack";
import { errorLogger } from "../../../utils/logger";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePIcker";
import dayjs, { Dayjs } from "dayjs";
import { COLOR } from "../../../utils/color";
import {
  DEFAULT_SNACKBAR_PROPS,
  ROUTE_NAME,
  RoutePath,
} from "../../../utils/constant";
import SubMenuHeader from "../../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft } from "react-icons/fa";
import { PublicScheduleDetailWithQty } from "../../../types/globalTypes";
import { APICalculateTransactionPayload } from "../../../api/request.types";
import { isAxiosError } from "axios";

const DSchedule = () => {
  const { data } = useDynamicPage();
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [errors, setErrors] = useState<Record<"scheduleDetails", string>>({
    scheduleDetails: "",
  });
  const [scheduleDetailsWithQuantities, setScheduleDetailsWithQuantities] =
    useState<{ [date: string]: Array<PublicScheduleDetailWithQty> }>({});

  const calculateTransactionMutation = useCalculateTransaction();
  const { productId } = useParams<{ productId: string }>();
  const navigate = useNavigate();
  const productScheduleByIdQuery = useGetPublicProductScheduleById(
    productId || "",
    selectedDate.format("YYYY-MM-DD")
  );

  const productScheduleData = useMemo(() => {
    return productScheduleByIdQuery.data?.data.data || { scheduleDetails: [] };
  }, [productScheduleByIdQuery.data]);

  useEffect(() => {
    if (
      productScheduleByIdQuery.isFetched &&
      !productScheduleByIdQuery.isLoading
    ) {
      const selectedDateKey = selectedDate.format("DD-MM-YYYY");
      const fetchedScheduleDetails = productScheduleData.scheduleDetails;

      if (!scheduleDetailsWithQuantities[selectedDateKey]) {
        const initializedScheduleDetails = fetchedScheduleDetails.map(
          (detail) => ({
            ...detail,
            qty: 0,
          })
        );

        setScheduleDetailsWithQuantities((prevState) => ({
          ...prevState,
          [selectedDateKey]: initializedScheduleDetails,
        }));
      }
    }
  }, [
    productScheduleByIdQuery.isFetched,
    productScheduleByIdQuery.isLoading,
    productScheduleData.scheduleDetails,
    scheduleDetailsWithQuantities,
    selectedDate,
  ]);

  useEffect(() => {
    if (!productId) {
      navigate(RoutePath[ROUTE_NAME.DYNAMIC_HOME], { replace: true });
    }
  }, [navigate, productId]);

  const handleQuantityChange = (index: number, qty: number) => {
    const formattedDate = selectedDate.format("DD-MM-YYYY");
    setScheduleDetailsWithQuantities((prevState) => {
      const updatedDetails = prevState[formattedDate].map((detail, i) =>
        i === index ? { ...detail, qty } : detail
      );
      return {
        ...prevState,
        [formattedDate]: updatedDetails,
      };
    });
  };

  const totalItems = Object.values(scheduleDetailsWithQuantities).reduce(
    (sum, details) =>
      sum + details.reduce((detailSum, detail) => detailSum + detail.qty, 0),
    0
  );

  const totalPrice = Object.values(scheduleDetailsWithQuantities).reduce(
    (sum, details) =>
      sum +
      details.reduce(
        (detailSum, detail) => detailSum + detail.price * detail.qty,
        0
      ),
    0
  );

  const daysContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (daysContainerRef.current) {
      const selectedDayElement = daysContainerRef.current.querySelector(
        `[data-day="${selectedDate.format("YYYY-MM-DD")}"]`
      );
      if (selectedDayElement) {
        selectedDayElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }
  }, [selectedDate]);

  if (!productId) {
    return null;
  }

  const _renderLoadingSchedules = () => {
    return new Array(5).fill("").map((_, idx) => {
      return <Skeleton width="100%" height="60px" key={idx} sx={{
        ':-webkit-transform': 'none',
        transform: 'none',
        mt: 1,
      }} />
    })
  }
  const _renderSchedules = () => {
    if (productScheduleData.scheduleDetails.length === 0) {
      return <Typography>Tidak Ada jadwal hari ini</Typography>
    }
    return productScheduleData.scheduleDetails.map((detail, index) => {
      const formattedDate = selectedDate.format("DD-MM-YYYY");
      const scheduleDetail: PublicScheduleDetailWithQty =
        scheduleDetailsWithQuantities[formattedDate]?.find(
          (d) => d.startTime === detail.startTime
        ) || { ...detail, qty: 0 };

        const startTime = dayjs(detail.startTime, "HH.mm").set('date', selectedDate.date());
        const endTime = dayjs(detail.endTime, "HH.mm").set('date', selectedDate.date());
        
        const currentTime = dayjs();
        const timeDifference = endTime.diff(currentTime, 'minute');
        
        const timePassed = timeDifference < 0; 
        const disabled =
          scheduleDetail.remainingQuota === 0 ||
          calculateTransactionMutation.isLoading || 
          timePassed;
      return (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            my: 2,
            pointerEvents: disabled ? "none" : "auto",
            opacity: disabled ? 0.5 : 1,
          }}
        >
          <Box>
            <Typography fontSize={16} fontWeight={600}>
              {`${startTime.format("HH.mm")} - ${endTime.format("HH.mm")}`}
              <Typography
                fontSize={12}
                fontWeight={400}
                color={COLOR.danger400}
                variant="caption"
                ml="4px"
              >
                (Sisa {detail.remainingQuota})
              </Typography>
            </Typography>
            <Typography
              mt="8px"
              fontSize={16}
              fontWeight={600}
              color={COLOR.neutral500}
            >
              {formatCurrency(detail.price, "IDR")}
            </Typography>
          </Box>
          <QuantitySelector
            value={scheduleDetail.qty}
            onChange={(qty) => handleQuantityChange(index, qty)}
            min={0}
            max={detail.remainingQuota}
            primaryColor={data.color}
            disabled={disabled}
          />
        </Box>
      );
    })
  }
  return (
    <AppContainer
      bottomChildren={
        <Box sx={{ ...FooterContainer, flexDirection: "column", gap: 1 }}>
          <Box
            display="flex"
            flexDirection="row"
            flexGrow={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize={14} fontWeight={600}>
              Total{" "}
              <Typography
                variant="caption"
                fontSize={12}
                fontWeight={400}
                color={COLOR.danger400}
              >
                ({totalItems} item{totalItems !== 1 ? "s" : ""})
              </Typography>
            </Typography>
            <Typography fontSize={20} fontWeight={700}>
              {formatCurrency(totalPrice, "IDR")}
            </Typography>
          </Box>
          <HelperText color={COLOR.danger500} text={errors.scheduleDetails} />
          <CustomButton
            sx={{ height: "fit-content", backgroundColor: data.color }}
            disabled={calculateTransactionMutation.isLoading}
            onClick={async () => {
              try {
                const productDetail: APICalculateTransactionPayload["productDetail"] =
                  [
                    {
                      productID: productId,
                      scheduleDetails: Object.entries(
                        scheduleDetailsWithQuantities
                      )
                        .map(([date, details]) =>
                          details
                            .filter((detail) => detail.qty > 0)
                            .map((detail) => ({
                              startTime: dayjs(
                                detail.startTime,
                                "HH.mm"
                              ).format("HH.mm"),
                              endTime: dayjs(detail.endTime, "HH.mm").format(
                                "HH.mm"
                              ),
                              date: dayjs(date, "DD-MM-YYYY").format(
                                "YYYY-MM-DD"
                              ), // Convert to "YYYY-MM-DD"
                              qty: detail.qty,
                              price: detail.price,
                              remainingQuota: detail.remainingQuota,
                            }))
                        )
                        .flat(),
                    },
                  ];
                if (totalItems === 0) {
                  setErrors({ scheduleDetails: "Kamu belum pilih jadwal!" });
                } else {
                  setErrors({ scheduleDetails: "" });
                  const response =
                    await calculateTransactionMutation.mutateAsync({
                      companyID: data.companyID,
                      productDetail: productDetail,
                    });
                  navigate(RoutePath[ROUTE_NAME.DYNAMIC_PAYMENT_DETAIL], {
                    state: {
                      data: response.data.data,
                    },
                  });
                }
              } catch (error) {
                if (isAxiosError(error) && error.response?.status === 409) {
                  const errorData = error.response.data?.data;
                  if (Array.isArray(errorData)) {
                    errorData.forEach((product) => {
                      const { startTime, endTime, date } =
                        product.scheduleDetails;
                      const msg = `Kuota pada tanggal ${date} (${startTime}-${endTime}) tidak mencukupi`;

                      enqueueSnackbar({
                        ...DEFAULT_SNACKBAR_PROPS,
                        variant: "error",
                        message: msg,
                      });
                    });
                  }
                } else {
                  errorLogger(error);
                  enqueueSnackbar({
                    ...DEFAULT_SNACKBAR_PROPS,
                    variant: "error",
                    message:
                      "Terjadi kesalahan, mohon coba dalam beberapa saat lagi",
                  });
                }
              }
            }}
          >
            <Typography fontWeight={600} variant="body1">
              Reservasi
            </Typography>
          </CustomButton>
        </Box>
      }
    >
      <SubMenuHeader
        leftNav={{
          icon: <FaArrowLeft />,
        }}
        text={"Pilih Jadwal"}
      />
      <CustomDatePicker
        value={selectedDate}
        onChange={(date: Dayjs) => setSelectedDate(date)}
        primaryColor={data.color}
      />
      <Box sx={{ px: 2, pb: 4, pt: 1 }}>
        {productScheduleByIdQuery.isLoading ? _renderLoadingSchedules() : _renderSchedules()}
      </Box>
    </AppContainer>
  );
};

export default DSchedule;
