import { FiHome } from "react-icons/fi";
import { IoStorefrontOutline } from "react-icons/io5";
import { MdOutlineAccountCircle } from "react-icons/md";
import { FiCalendar } from "react-icons/fi";
import { BottomNavItem } from "../components/BottomNav";
import { OptionsObject } from "notistack";
import { GetProductByIdResponse, PageTemplateType } from "../api/request.types";

export enum ROUTE_NAME {
  BASE = "BASE",
  HOME = "HOME",
  SCHEDULE = "SCHEDULE",
  BUSINESS = "BUSINESS",
  CREATE_BUSINESS = "CREATE_BUSINESS",
  PROFILE = "PROFILE",
  UNAUTHORIZED = "UNAUTHORIZED",
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  LOCATION_BUSINESS = "LOCATION_BUSINESS",
  PAGE_EDITOR = "PAGE_EDITOR",
  PAGE_PREVIEW = "PAGE_PREVIEW",
  PAGE_TEMPLATE = "PAGE_TEMPLATE",
  ADD_PRODUCT = "ADD_PRODUCT",
  STATUS_INFO = "STATUS_INFO",
  MANAGE_SCHEDULE = "MANAGE_SCHEDULE",
  DYNAMIC_PRODUCT_DETAIL = "DYNAMIC_PRODUCT_DETAIL",
  DYNAMIC_SCHEDULE = "DYNAMIC_SCHEDULE",
  DYNAMIC_HOME = "DYNAMIC_HOME",
  DYNAMIC_NOT_FOUND = "DYNAMIC_NOT_FOUND",
  DYNAMIC_INVOICE = "DYNAMIC_INVOICE",
  DYNAMIC_PAYMENT_DETAIL = "DYNAMIC_PAYMENT_DETAIL",
  DYNAMIC_STATUS_INFO = "DYNAMIC_STATUS_INFO",
  TRANSACTION = "TRANSACTION",
  PRODUCT = "PRODUCT",
  EDIT_BUSINESS = "EDIT_BUSINESS",
}

export type RoutePathType = { [key in ROUTE_NAME]: string };

export const RoutePath: RoutePathType = {
  [ROUTE_NAME.BASE]: "/",
  [ROUTE_NAME.HOME]: "/home",
  [ROUTE_NAME.SCHEDULE]: "/schedule",
  [ROUTE_NAME.BUSINESS]: "/business",
  [ROUTE_NAME.CREATE_BUSINESS]: "/business/create",
  [ROUTE_NAME.LOCATION_BUSINESS]: "/business/location",
  [ROUTE_NAME.PAGE_PREVIEW]: "/business/preview",
  [ROUTE_NAME.PAGE_TEMPLATE]: "/business/template",
  [ROUTE_NAME.ADD_PRODUCT]: "/business/add-product",
  [ROUTE_NAME.PROFILE]: "/profile",
  [ROUTE_NAME.UNAUTHORIZED]: "/unauthorized",
  [ROUTE_NAME.LOGIN]: "/login",
  [ROUTE_NAME.REGISTER]: "/register",
  [ROUTE_NAME.PAGE_EDITOR]: "/page-editor",
  [ROUTE_NAME.STATUS_INFO]: "/status-info",
  [ROUTE_NAME.DYNAMIC_HOME]: "/",
  [ROUTE_NAME.DYNAMIC_PRODUCT_DETAIL]: "/detail",
  [ROUTE_NAME.DYNAMIC_SCHEDULE]: "/schedule",
  [ROUTE_NAME.MANAGE_SCHEDULE]: "/manage-schedule",
  [ROUTE_NAME.DYNAMIC_INVOICE]: "/inv",
  [ROUTE_NAME.DYNAMIC_NOT_FOUND]: "/not-found",
  [ROUTE_NAME.DYNAMIC_PAYMENT_DETAIL]: "/payment",
  [ROUTE_NAME.DYNAMIC_STATUS_INFO]: "/info",
  [ROUTE_NAME.TRANSACTION]: "/transaction",
  [ROUTE_NAME.PRODUCT]: "/product",
  [ROUTE_NAME.EDIT_BUSINESS]: "/business",
};

export const COOKIE = {
  TOKEN: "TOKEN",
};

export const USER_ROLE = {
  GUEST: "guest",
  ADMIN: "admin",
  STAFF: "staff",
  USER: "user",
};

export const DEFAULT_BOTTOM_NAVIGATION: Array<BottomNavItem> = [
  {
    routeName: ROUTE_NAME.HOME,
    routePath: RoutePath[ROUTE_NAME.HOME],
    label: "Beranda",
    icon: <FiHome />,
  },
  {
    routeName: ROUTE_NAME.SCHEDULE,
    routePath: RoutePath[ROUTE_NAME.SCHEDULE],
    label: "Jadwal",
    icon: <FiCalendar />,
  },
  {
    routeName: ROUTE_NAME.BUSINESS,
    routePath: RoutePath[ROUTE_NAME.BUSINESS],
    label: "Bisnis",
    icon: <IoStorefrontOutline />,
  },
  {
    routeName: ROUTE_NAME.PROFILE,
    routePath: RoutePath[ROUTE_NAME.PROFILE],
    label: "Profil",
    icon: <MdOutlineAccountCircle />,
  },
];

// Remember change the value on index.css too (since it impact bottomsheet)
export const MAX_PAGE_WIDTH = 800;
export const MIN_FONT_SIZE_FORM = 16;
export const MIN_FONT_SIZE_BUTTON_ICON = 24;

export const SX_P_M_SIZE = 3;

export enum STORAGE_KEY {
  COLOR_THEME = "color-theme",
  CREATE_BUSINESS_IMAGE_PREVIEW = "create-business-image-preview",
  BUSINESS_DATA_ERROR = "business-data-error",
  TEMPLATE_DATA = "template-data",
  LOCATION_DATA = "location-data",
  PRODUCT_IMAGES = "product-images",
}

export const DEFAULT_GEOLOCATION = {
  lat: 0,
  long: 0,
};

export const TIMEZONES = ["Asia/Jakarta", "Asia/Makassar"];

export const TIMEZONE_SHORTLABEL = {
  [TIMEZONES[0]]: "WIB",
  [TIMEZONES[1]]: "WITA",
};

export const TIMEZONE_LABEL = {
  [TIMEZONES[0]]: "Waktu Indonesia Barat (WIB), UTC+07:00",
  [TIMEZONES[1]]: "Waktu Indonesia Tengah (WITA), UTC+08:00",
};

// Secondary Color based on
// https://www.canva.com/colors/color-wheel/
export const TEMPLATE_HEX_COLOR = [
  {
    primary: "#3B82F6",
    secondary: "#93C5FD",
  },
  {
    primary: "#EF4444",
    secondary: "#EB1515",
  },
  {
    primary: "#F59E0B",
    secondary: "#C57F08",
  },
  {
    primary: "#22C55E",
    secondary: "#3DDD78",
  },
  {
    primary: "#8B5CF6",
    secondary: "#692CF3",
  },
];

export const TEMPLATE_PAGE: Array<{ id: PageTemplateType; content: string }> = [
  {
    id: "Type1",
    content: "template1",
  },
];

export enum DURATION_PERIOD {
  MINUTES = "minutes",
  HOURS = "hours",
  DAYS = "days",
  WEEKS = "weeks",
}

export const DEFAULT_SNACKBAR_PROPS: OptionsObject<"default"> = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  autoHideDuration: 3000,
};

export const UNIT_TEXT = {
  [DURATION_PERIOD.DAYS]: "Hari",
  [DURATION_PERIOD.HOURS]: "Jam",
  [DURATION_PERIOD.MINUTES]: "Menit",
  [DURATION_PERIOD.WEEKS]: "Minggu",
};

export const NEEMA_SUFFIX_URL = ".neema.asia";
export const getBusinessPreviewUrl = (companyUrl: string): string => {
  return `https://${companyUrl}${NEEMA_SUFFIX_URL}`;
};

export const DEFAULT_PRODUCT_DATA: GetProductByIdResponse = {
  productID: "",
  companyID: "",
  name: "",
  description: "",
  useSinglePrice: false,
  allowRefund: false,
  disabled: false,
  duration: 0,
  durationPeriod: DURATION_PERIOD.MINUTES,
  quota: 0,
  photos: [],
  photosSignedURL: [],
  location: {
    mapLocation: "",
    completeAddress: "",
    useManualLocation: false,
    longitude: 0,
    latitude: 0,
    timezone: ""
  },
  scheduleDetails: {},
  addOn: [],
  createdBy: "",
  updatedBy: "",
  createdAt: "",
  updatedAt: ""
}