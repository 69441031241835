import { useEffect, useRef, useState } from "react";
import {
  InnerContainerStyle,
  NavContainerStyle,
  OuterContainerStyle,
} from "./AppContainer.styles";
import { Box, SxProps } from "@mui/material";
import { Theme } from "@emotion/react";

interface AppContainerPropsI {
  children: React.ReactNode;
  bottomChildren?: React.ReactNode;
  sx?: SxProps<Theme>;
}

const AppContainer: React.FC<AppContainerPropsI> = ({
  children,
  bottomChildren,
  sx,
}) => {
  const bottomNavRef = useRef<HTMLDivElement>(null);
  const [bottomNavHeight, setBottomNavHeight] = useState(0);
  const [innerHeight, setInnerHeight] = useState("100%");

  useEffect(() => {
    if (bottomNavRef?.current?.clientHeight) {
      const height = bottomNavRef.current.clientHeight;
      if (height !== bottomNavHeight) {
        setBottomNavHeight((h) => {
          return height;
        });
        setInnerHeight(`calc(100vh - ${height}px)`);
      }
    }
  }, [bottomNavHeight]);

  return (
    <Box
      sx={{ ...OuterContainerStyle, paddingBottom: `${bottomNavHeight}px` }}
    >
      <Box
        sx={{
          ...InnerContainerStyle,
          minHeight: bottomChildren ? innerHeight : "100vh",
        }}
      >
        <Box sx={{ width: "100%", height: "100%", ...sx }}>{children}</Box>
      </Box>
      {bottomChildren && (
        <Box sx={{ ...NavContainerStyle, width: "100%" }} ref={bottomNavRef}>
          {bottomChildren}
        </Box>
      )}
    </Box>
  );
};

export default AppContainer;
