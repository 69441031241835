import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { DEFAULT_LOCATION_DATA, LocationData } from '../pages/Business/LocationBusiness/LocationBusiness';

interface LocationContextType {
  locationData: LocationData;
  savedLocation: LocationData;
  setLocationData: React.Dispatch<React.SetStateAction<LocationData>>;
  saveLocation: () => void;
}

const LocationContext = createContext<LocationContextType | undefined>(undefined);

export const LocationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [locationData, setLocationDataState] = useState<LocationData>(DEFAULT_LOCATION_DATA);
  const [savedLocation, setSavedLocation] = useState<LocationData>(DEFAULT_LOCATION_DATA);

  const saveLocation = () => {
    console.log('SAVE LOCATION')
    setSavedLocation(locationData);
  };
  useEffect(() => {
    console.log("SAVED LOCATION", savedLocation)
  }, [savedLocation])

  return (
    <LocationContext.Provider value={{
      locationData,
      setLocationData: setLocationDataState,
      savedLocation,
      saveLocation,
    }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationContext = () => {
  const context = useContext(LocationContext);
  if (context === undefined) {
    throw new Error('useLocationContext must be used within a LocationProvider');
  }
  return context;
};
