import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { RoutePath, USER_ROLE } from "../utils/constant";

import Home from "../pages/Home/Home";
import Profile from "../pages/Profile/Profile";
import Schedule from "../pages/Schedule/Schedule";
import Business from "../pages/Business/Business";
import NotFound from "../pages/NotFound";
import UnAuthorizedPage from "./UnAuthorizedPage";
import RequireAuthRoute from "./RequireAuth";
import Login from "./UnAuthorizedPage/LoginPage/Login";
// import Register from "./UnAuthorizedPage/RegisterPage/Register";
import NavigateOnAuth from "./NavigateOnAuth";
import CreateBusiness from "./Business/CreateBusiness/CreateBusiness";
import { BottomNavProvider } from "../context/BottomNavContext";
import LocationBusiness from "./Business/LocationBusiness/LocationBusiness";
// import PageEditor from "../components/PageEditor/PageEditor";
import PageTemplate from "./Business/PageTemplate/PageTemplate";
import AddProduct from "./Business/AddProduct/AddProduct";
import DynamicPage from "./DynamicPage/DynamicPage";
import { DOMAIN_NAME, getSubdomain } from "../utils/route";
import StatusInfo from "./StatusInfo/StatusInfo";
import ManageSchedule from "./Business/ManageSchedule/ManageSchedule";
import { LocationProvider } from "../context/LocationPageContext";
import { StatusInfoProvider } from "../context/StatusInfoContext";
import { ManageScheduleProvider } from "../context/ScheduleContext";
import Transaction from "./Transaction/Transaction";
import ProductList from "./ProductList/ProductList";
import DetailProduct from "./ProductDetail/ProductDetail";
import TransactionDetail from "./TransactionDetail/TransactionDetail";
import EditBusiness from "./Business/EditBusiness/EditBusiness";

const RouteList = () => {
  const subdomain = getSubdomain();
  const isDomainName = subdomain.includes(DOMAIN_NAME);
  return (
    <BrowserRouter>
      <BottomNavProvider>
        <StatusInfoProvider>
          <ManageScheduleProvider>
            <LocationProvider>
              <Routes>
                {!isDomainName && <Route path="*" element={<DynamicPage />} />}
                {isDomainName && (
                  <>
                    <Route
                      path={RoutePath.UNAUTHORIZED}
                      element={<UnAuthorizedPage />}
                    />
                    <Route element={<NavigateOnAuth to={RoutePath.HOME} />}>
                      <Route path={RoutePath.LOGIN} element={<Login />} />
                      {/* <Route path={RoutePath.REGISTER} element={<Register />} /> */}
                    </Route>
                    <Route
                      element={
                        <RequireAuthRoute
                          allowedRoles={[
                            USER_ROLE.ADMIN,
                            USER_ROLE.STAFF,
                            USER_ROLE.GUEST,
                          ]}
                        />
                      }
                    >
                      <Route path={RoutePath.HOME} element={<Home />} />
                      <Route path={RoutePath.SCHEDULE} element={<Schedule />} />
                      <Route path={RoutePath.BUSINESS} element={<Business />} />
                      <Route
                        path={RoutePath.CREATE_BUSINESS}
                        element={<CreateBusiness />}
                      />
                      <Route
                        path={RoutePath.EDIT_BUSINESS + "/:businessId/edit"}
                        element={<EditBusiness />}
                      />
                      {/* <Route path={RoutePath.PAGE_EDITOR} element={<PageEditor />} /> */}
                      <Route
                        path={RoutePath.PAGE_TEMPLATE}
                        element={<PageTemplate />}
                      />
                      <Route
                        path={RoutePath.ADD_PRODUCT}
                        element={<AddProduct />}
                      />
                      <Route path={RoutePath.PROFILE} element={<Profile />} />
                      <Route
                        path={RoutePath.LOCATION_BUSINESS}
                        element={<LocationBusiness />}
                      />
                      <Route
                        path={RoutePath.STATUS_INFO}
                        element={<StatusInfo />}
                      />
                      <Route
                        path={RoutePath.MANAGE_SCHEDULE}
                        element={<ManageSchedule />}
                      />
                      <Route
                        path={RoutePath.TRANSACTION}
                        element={<Transaction />}
                      />
                      <Route
                        path={RoutePath.TRANSACTION + "/:transactionId"}
                        element={<TransactionDetail />}
                      />
                      <Route
                        path={RoutePath.PRODUCT}
                        element={<ProductList />}
                      />
                      <Route
                        path={RoutePath.PRODUCT + "/:productId/:mode"}
                        element={<DetailProduct />}
                      />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                    <Route
                      path="/"
                      element={<Navigate to={RoutePath.LOGIN} />}
                    />
                  </>
                )}
              </Routes>
            </LocationProvider>
          </ManageScheduleProvider>
        </StatusInfoProvider>
      </BottomNavProvider>
    </BrowserRouter>
  );
};

export default RouteList;
