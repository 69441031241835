import React from 'react';
import { IconSVGProps } from '../../types/globalTypes';

const LocationIcon: React.FC<IconSVGProps> = ({
  size = 24,
  color = "#64748B",
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 11.5C15 13.1569 13.6569 14.5 12 14.5C10.3431 14.5 9 13.1569 9 11.5C9 9.84315 10.3431 8.5 12 8.5C13.6569 8.5 15 9.84315 15 11.5Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M21 11.3889C21 16.2981 15.375 22.5 12 22.5C8.625 22.5 3 16.2981 3 11.3889C3 6.47969 7.02944 2.5 12 2.5C16.9706 2.5 21 6.47969 21 11.3889Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default LocationIcon;
